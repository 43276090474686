import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Collapse as MuiCollapse,
  ListItem as MuiListItem,
  List as MuiList,
  styled
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Title from './Title';

const List = styled(MuiList)(() => ({ width: '100%' }));
const Collapse = styled(MuiCollapse)(() => ({ width: '100%' }));

const ListItem = styled(MuiListItem)(() => ({
  display: 'flex',
  flexFlow: 'column',
  paddingTop: 0,
  paddingBottom: 0,
  alignItems: 'flex-start',
  position: 'relative'
}));

const DropdownButton = styled(Button)`
  width: 100%;
  padding: 0;
`;

const DropdownIcon = styled(({ open, ...props }) => open
  ? <ExpandLessIcon {...props} />
  : <ExpandMoreIcon {...props} />
)(({ theme }) => ({
  margin: theme.spacing(0, 2, 0, 0),
  color: theme.palette.action.active
}));

const TreeItemBox = styled(Box)(getItemBoxStyle);

function Section({
  children,
  open,
  title,
  dropdown,
  disabled,
  titleClassName,
  ...props
}) {
  return (
    <ListItem disableGutters {...props}>
      {dropdown ? (
        <DropdownSection
          title={title}
          open={open}
          disabled={disabled}
          titleClassName={titleClassName}>
          {children}
        </DropdownSection>
      ) : (
        <OpenSection title={title} titleClassName={titleClassName}>
          {children}
        </OpenSection>
      )}
    </ListItem>
  );
}

export default Section;

function DropdownSection({
  title,
  open: initial,
  disabled,
  children,
  titleClassName
}) {
  const [open, setOpen] = useState(initial);
  const toggleDropdown = () => {
    setOpen(prevOpen => !prevOpen);
  };
  useEffect(() => {
    setOpen(initial);
  }, [initial]);

  return (
    <TreeItemBox disabled={disabled}>
      <DropdownButton onClick={toggleDropdown} disabled={disabled}>
        <Title className={titleClassName}>{title}</Title>
        {!disabled && <DropdownIcon open={open} />}
      </DropdownButton>
      <Collapse in={open} timeout={0}>
        <List disablePadding>{open ? children : null}</List>
      </Collapse>
    </TreeItemBox>
  );
}

function OpenSection({ title, children, titleClassName }) {
  return (
    <TreeItemBox disabled={!children}>
      <Title className={titleClassName}>{title}</Title>
      <List disablePadding>
        {children}
      </List>
    </TreeItemBox>
  );
}

function getItemBoxStyle({ theme, disabled }) {
  return {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
    padding: 0,
    justifyContent: 'start',
    alignItems: 'center',
    color: disabled
      ? theme.palette.text.disabled
      : theme.palette.text.primary,
    opacity: disabled && 0.5,
    textTransform: 'none',
    letterSpacing: 0,
    '&:hover, &.active': {
      borderRadius: 0
    }
  };
}
