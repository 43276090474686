import React from 'react';
import styled from '@emotion/styled';
import { Button, Box, Card, CardContent, CardHeader, Grid, Link, Typography } from '@mui/material';
import { Copy as CopyIcon, User as UserIcon } from 'react-feather';
import { ASSIGNMENT_TYPE_LABELS } from 'src/constants';

const DEFAULT_ASSIGNMENT_TYPE_LABEL = 'Partner Success Member';

const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const ProfileContainer = styled.div`
  flex-shrink: 0;
  width: 64px;
  height: 64px;
  border-radius: 50%;
`;

const ProfileImage = styled(ProfileContainer)`
  object-fit: cover;
`;

const StyledUserIcon = styled(ProfileContainer)`
  border: 1px solid ${({ theme }) => theme.palette.tertiary.lighter};
  fill: currentColor;
  stroke-linecap: butt;
`;

const UserName = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.subtitle2.fontSize};
  font-weight: ${({ theme }) => theme.typography.subtitle2.fontWeight};
  line-height: 1.15rem;
`;

const UserAssignmentType = styled(Typography)`
  font-weight: 500;
`;

const UserEmail = styled(Typography)`
  font-weight: 500;
`;

const ContactIcon = styled.svg`
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  margin-right: ${props => props.theme.spacing(1)};
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
`;

const copyToClipboard = userEmail => navigator.clipboard.writeText(userEmail);

export default function ContactPsTeamMembersWidget({ psTeamMembers }) {
  const emails = psTeamMembers.map(user => user.email);
  const getAssignmentTypeLabel = (certificationAssignments = []) => {
    if (!certificationAssignments.length) return DEFAULT_ASSIGNMENT_TYPE_LABEL;
    const assignmentType = certificationAssignments[0].type;
    return ASSIGNMENT_TYPE_LABELS[assignmentType] || DEFAULT_ASSIGNMENT_TYPE_LABEL;
  };

  return (
    <Card>
      <CardHeader
        title='Contact your Partner Success Team'
        titleTypographyProps={{ variant: 'subtitle2' }} />
      {psTeamMembers.map(user => (
        <div key={user.id}>
          <StyledCardContent sx={{ p: 2, pt: 0 }}>
            {
              user.photoUrl
                ? <ProfileImage as="img" src={user.photoUrl} draggable={false} />
                : <StyledUserIcon as={UserIcon} />
            }
            <Grid container direction="column" rowSpacing={0.5} sx={{ ml: 2, mt: 0 }}>
              <UserName>
                {
                  user.first && user.last
                    ? `${user.first} ${user.last}`
                    : 'N/A'
                }
              </UserName>
              <UserAssignmentType variant="body4" sx={{ mt: 1 }}>
                {
                  getAssignmentTypeLabel(user.certificationAssignments)
                }
              </UserAssignmentType>
              <Grid item container direction="row" alignItems="center" sx={{ mt: '2px' }}>
                <UserEmail variant="body4" sx={{ mr: '4px' }}>
                  {
                    user.email
                      ? <Link href={`mailto:${user.email}`} variant="body4">{user.email}</Link>
                      : 'N/A'
                  }
                </UserEmail>
                <ContactIcon as={CopyIcon} onClick={() => copyToClipboard(user.email)} />
              </Grid>
            </Grid>
          </StyledCardContent>
        </div>
      ))}
      <Box mx={2} mb={2}>
        <Link href={`mailto:${emails.join(',')}`}>
          <Button fullWidth color="primary">
            Contact Team
          </Button>
        </Link>
      </Box>
    </Card>
  );
}
