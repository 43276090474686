import { selectActions } from 'src/store/selectors';
import {
  getCertifications,
  getCertification,
  submitCertification,
  reviewCertification
} from 'src/api/certifications';
import logger from 'src/utils/logger';
import { setLatestCertificationBaseline } from './baseline';

const selectBaselineActions = selectActions('baseline');
const log = logger.child({ store: 'certification' });

const init = () => ({
  current: null,
  isLoading: false,
  isSaving: false
});

function hydrateCertificationSteps(state, certification) {
  setLatestCertificationBaseline(state, certification);
}

const createActions = (set, get) => ({
  fetch: partnerId => {
    if (get().certification.isLoading) return;
    set(state => { state.certification.isLoading = true; }, 'certification/fetchBegin');
    return getCertifications({ partnerId })
      .then(([certification]) => {
        set(state => { state.certification.current = certification; }, 'certification/fetchSuccess');
        hydrateCertificationSteps(get(), certification);
        return certification;
      })
      .catch(err => log.error('fetchCertifications', err))
      .finally(() => {
        set(state => { state.certification.isLoading = false; }, 'certification/fetchFinally');
      });
  },

  fetchById: id => {
    set(state => { state.certification.isLoading = true; }, 'certification/fetchByIdBegin');
    return getCertification(id)
      .then(certification => {
        set(state => { state.certification.current = certification; }, 'certification/fetchByIdSuccess');
        hydrateCertificationSteps(get(), certification);
        return certification;
      })
      .finally(() => {
        set(state => { state.certification.isLoading = false; }, 'certification/fetchByIdFinally');
      });
  },

  submit: () => {
    const { current } = get().certification;
    set(state => { state.certification.isSaving = true; }, 'certification/submitBegin');
    return submitCertification(current.id)
      .then(certification => {
        set(state => { state.certification.current.status = certification.status; }, 'certification/submitSuccess');
        hydrateCertificationSteps(get(), certification);
      })
      .finally(() => {
        set(state => { state.certification.isSaving = false; }, 'certification/submitFinally');
      });
  },

  review: isApproved => {
    const { current } = get().certification;
    set(state => { state.certification.isSaving = true; }, 'certification/reviewBegin');
    return reviewCertification(current.id, isApproved)
      .then(certification => {
        set(state => { state.certification.current.status = certification.status; }, 'certification/reviewSuccess');
        hydrateCertificationSteps(get(), certification);
      })
      .finally(() => {
        set(state => { state.certification.isSaving = false; }, 'certification/reviewFinally');
      });
  },

  dispose: () => {
    const { dispose: disposeBaseline } = selectBaselineActions(get());
    set(state => { state.certification.current = null; }, 'certification/dispose');
    disposeBaseline();
  }
});

export default { init, createActions };
