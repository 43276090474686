import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Container } from '@mui/material';
import { useSetPartnerOnCertificationChange } from '../storeNavigation';
import { ROUTES } from '../routes';

const Overview = React.lazy(() => import('./Overview'));
const Form = React.lazy(() => import('./Form'));

function Baseline() {
  useSetPartnerOnCertificationChange();

  return (
    <Container maxWidth="xl">
      <Switch>
        <Route path={ROUTES.CERTIFICATION_BASELINE_PILLAR} component={Form} />
        <Route component={Overview} />
      </Switch>
    </Container>
  );
}

export default Baseline;
