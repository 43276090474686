import React, { useEffect } from 'react';
import { ErrorBoundary, useErrorHandler } from 'react-error-boundary';
import useStore from 'src/next/store';
import GenericErrorPage from './GenericErrorPage';

function GlobalErrorHandler({ children }) {
  const onErrorHandle = useErrorHandler();
  const handleError = event => {
    onErrorHandle(event.error ?? event.reason);
  };

  useEffect(() => {
    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', handleError);

    return () => {
      window.removeEventListener('error', handleError);
      window.removeEventListener('unhandledrejection', handleError);
    };
  }, []);

  return (
    <>
      {children}
    </>
  );
}

function GlobalErrorBoundary({ children }) {
  const resetStore = useStore(state => state.reset);

  return (
    <ErrorBoundary
      FallbackComponent={GenericErrorPage}
      onReset={resetStore}
    >
      <GlobalErrorHandler>
        {children}
      </GlobalErrorHandler>
    </ErrorBoundary>
  );
}

export default GlobalErrorBoundary;
