import React from 'react';
import { Box, Link, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ExtendedCertificationStatus } from 'src/models/certification';
import { CERTIFIED_SCORE } from 'src/constants';
import ReportContextMenu from './ReportContextMenu';

const {
  BASELINE_DRAFT,
  EVALUATION_DRAFT,
  EVALUATION_NOT_STARTED_OPEN,
  EVALUATION_IN_REVIEW,
  EVALUATION_APPROVED,
  EVALUATION_APPROVED_NO_BADGE
} = ExtendedCertificationStatus;

const statusLabelMap = {
  [EVALUATION_DRAFT]: 'In Progress',
  [EVALUATION_NOT_STARTED_OPEN]: 'In Progress',
  [EVALUATION_IN_REVIEW]: 'Early Score',
  [EVALUATION_APPROVED]: 'Final Score',
  [EVALUATION_APPROVED_NO_BADGE]: 'Final Score'
};

const useStyles = makeStyles(theme => ({
  monthContainer: ({ isHighlighted, highlightColor }) => ({
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
    fontWeight: '700',
    width: '100px',
    height: '75px',
    boxShadow: '1px 1px 8px rgba(0, 0, 0, 0.2)',
    border: isHighlighted ? `1px solid ${highlightColor}` : '',
    background: isHighlighted ? '#FFFFFF' : '#F1F1F1'
  }),
  monthTitle: ({ isHighlighted, highlightColor }) => ({
    display: 'flex',
    justifyContent: 'center',
    background: isHighlighted ? highlightColor : '#D6D6D6',
    color: isHighlighted ? '#FFFFFF' : '#000000'
  }),
  monthContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
  },
  notAvailable: () => ({
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.tertiary.main
  }),
  score: ({ hasEarnedBadge, score }) => ({
    fontSize: 20,
    fontWeight: 600,
    color: hasEarnedBadge ? theme.palette.secondary.main
      : score >= 0 ? theme.palette.tertiary.dark : theme.palette.tertiary.main
  }),
  statusLabel: ({ inProgress, isHighlighted }) => ({
    fontSize: 11,
    lineHeight: '11px',
    color: isHighlighted && inProgress ? theme.palette.tertiary.main : '#000000'
  }),
  footerLinks: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 11,
    textAlign: 'center'
  },
  link: ({ highlightColor }) => ({
    color: highlightColor,
    textDecoration: 'underline',
    cursor: 'pointer'
  })
}));

export default function CertificationMonth({
  addLeftMargin,
  addTopMargin,
  title,
  isHighlighted,
  status,
  score,
  reviewLink,
  isDataUnavailable,
  isScoreVisible,
  highlightColor,
  certificationId,
  certificationStepId,
  asset,
  canManageReports
}) {
  const inProgress = [BASELINE_DRAFT, EVALUATION_DRAFT, EVALUATION_NOT_STARTED_OPEN].includes(status);
  const hasEarnedBadge = status === EVALUATION_APPROVED && score >= CERTIFIED_SCORE;
  const styleParams = { addLeftMargin, isHighlighted, hasEarnedBadge, score, inProgress, highlightColor };
  const styleClasses = useStyles(styleParams);
  const statusLabel = isDataUnavailable ? null : statusLabelMap[status];
  const showReportMenu = canManageReports || !!asset;

  return (
    <Box mt={addTopMargin ? '10px' : 1} ml={addLeftMargin ? '10px' : 0}>
      <Paper square className={styleClasses.monthContainer}>
        <Box className={styleClasses.monthTitle}>
          {title}
        </Box>
        <Box className={styleClasses.monthContent}>
          {isDataUnavailable ? (
            <Typography className={styleClasses.notAvailable}>N/A</Typography>
          ) : (
            <>
              {statusLabel && <Typography className={styleClasses.statusLabel}>{statusLabel}</Typography>}
              {isScoreVisible && <Typography className={styleClasses.score}>{score}</Typography>}
            </>
          )}
        </Box>
      </Paper>
      {
        (showReportMenu || reviewLink) &&
        <Box className={styleClasses.footerLinks}>
          {reviewLink && <Link className={styleClasses.link} href={reviewLink}>Review</Link>}
          {reviewLink && showReportMenu && ' | '}
          {showReportMenu && (
            <ReportContextMenu
              certificationId={certificationId}
              certificationStepId={certificationStepId}
              asset={asset}
              canManageReports={canManageReports}
              themeColor={highlightColor}
              trigger={
                <Link className={styleClasses.link}>Report</Link>
              }
            />
          )}
        </Box>
      }
    </Box>
  );
}
