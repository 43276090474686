import partners from './partners';

const init = (set, get) => ({
  partners: partners.init(set, get)
});

const createActions = (set, get) => ({
  partners: partners.createActions(set, get)
});

export default { init, createActions };
