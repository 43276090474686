export function getAssessmentWindowMeta(hasReceivedABadgeInCurrentYear, periods, currentDate) {
  const currentOrNextPeriod = getAssessmentWindowPeriod(hasReceivedABadgeInCurrentYear, periods);
  const differenceInMs = currentOrNextPeriod.isCurrent
    ? currentOrNextPeriod.endDate - currentDate
    : currentOrNextPeriod.startDate - currentDate;

  const millisecondsInOneMinute = 1000 * 60;
  const millisecondsInOneHour = millisecondsInOneMinute * 60;
  const millisecondsInOneDay = millisecondsInOneHour * 24;

  const days = Math.floor(differenceInMs / millisecondsInOneDay);
  const hours = Math.floor((differenceInMs % millisecondsInOneDay) / millisecondsInOneHour);
  const minutes = Math.floor((differenceInMs % millisecondsInOneHour) / millisecondsInOneMinute);

  return {
    period: currentOrNextPeriod,
    isCurrent: currentOrNextPeriod.isCurrent,
    isNext: currentOrNextPeriod.isNext,
    remainingTime: { days, hours, minutes }
  };
}

function getAssessmentWindowPeriod(hasReceivedABadgeInCurrentYear, periods) {
  if (hasReceivedABadgeInCurrentYear) return getNextYearPeriod(periods[0]);

  const period = periods.find(period => period.isCurrent || period.isNext);

  return period || getNextYearPeriod(periods[0]);
}

function getNextYearPeriod(period) {
  const nextYearPeriodStartDate = new Date(period.startDate);
  const nextYearPeriodEndDate = new Date(period.endDate);
  nextYearPeriodStartDate.setFullYear(period.startDate.getFullYear() + 1);
  nextYearPeriodEndDate.setFullYear(period.endDate.getFullYear() + 1);

  return {
    startDate: nextYearPeriodStartDate,
    endDate: nextYearPeriodEndDate,
    isNext: true,
    title: period.title
  };
}
