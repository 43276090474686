import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import routes from 'src/next/navigation/routes';
import rewTheme from 'src/next/mui/rewTheme';
import useAssessmentTheme from 'src/next/assessment/useAssessmentTheme';
import { Container, ScopedCssBaseline, ThemeProvider } from '@mui/material';
import styled from '@emotion/styled';
import { ROLES } from 'src/constants';
import CertificationMain from './certification/CertificationMain';
import MyProgramsMain from './partner/MyProgramsMain';
import AppBar from './header/AppBar';
import AppFooter from './footer/AppFooter';
import Breadcrumbs from './header/Breadcrumbs';
import GlobalErrorBoundary from './error/GlobalErrorBoundary';
import useStore from './store';
import CertificationUserMain from './user/CertificationUserMain';
import UserMain from './user/UserMain';
import PartnerMain from './partner/PartnerMain';
import FeatureToggleProvider from './feature-toggles/FeatureToggleProvider';

const StyledContainer = styled(Container)`
  &.MuiContainer-maxWidthLg {
    max-width: 1366px;
    flex: auto;
  }
`;

export default function App() {
  const { initialize: initializeAuth, user } = useStore(state => state.auth);
  const { getSettings, assessmentRanges, currentDate } = useStore(state => state.settings);
  const assessmentTheme = useAssessmentTheme();
  const isEmployee = user?.role === ROLES.EMPLOYEE;

  if (user) {
    TagManager.dataLayer({
      dataLayer: { userRole: user.role },
      dataLayerName: 'UserDataLayer'
    });
  }

  useEffect(() => {
    initializeAuth();
    getSettings();
  }, [initializeAuth, getSettings]);

  return (
    <ThemeProvider theme={rewTheme}>
      <ScopedCssBaseline>
        <GlobalErrorBoundary>
          <FeatureToggleProvider>
            <AppBar />
            {
              user && assessmentRanges &&
              <ThemeProvider theme={assessmentTheme}>
                <Breadcrumbs />
                <StyledContainer sx={{ py: 2 }} >
                  <Switch>
                    <Route path={routes.ADMINISTRATION_USERS} component={isEmployee ? () => <Redirect to={routes.HOME} /> : UserMain} exact />
                    <Route path={routes.CERTIFICATION_USERS} component={CertificationUserMain} exact />
                    <Route path={routes.CERTIFICATION} component={CertificationMain} />
                    <Route path={routes.HOME}>
                      {
                        isEmployee
                          ? <MyProgramsMain />
                          : <PartnerMain user={user}/>
                      }
                    </Route>
                  </Switch>
                </StyledContainer>
              </ThemeProvider>
            }
            <AppFooter currentDate={currentDate} />
          </FeatureToggleProvider>
        </GlobalErrorBoundary>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
}
