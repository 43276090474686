import React from 'react';
import { Pagination } from '@mui/material';
import { Box } from '@mui/system';
import { getPartnerCertificationUpdatedAtBySortOrder } from './PartnerList';
import CertificationStatusReviewElement from '../certification/CertificationStatusReviewElement';
import usePagination from '../util/usePagination';

const ROWS_PER_PAGE = 8;

export default function MyReviewsMain({ partners, sortOrder, reviews, filters }) {
  const sortedPartners = [...partners].sort((a, b) => {
    if (sortOrder.sortBy === 'updatedAt') {
      return (sortOrder.order === 'asc' ? 1 : -1) * (
        getPartnerCertificationUpdatedAtBySortOrder(a, sortOrder.order, filters.assessmentTypes) -
      getPartnerCertificationUpdatedAtBySortOrder(b, sortOrder.order, filters.assessmentTypes));
    } else {
      return (sortOrder.order === 'asc' ? 1 : -1) * a.name.localeCompare(b.name);
    }
  });
  const { pageRows: pagePartners, page, setPage, pageCount } = usePagination(sortedPartners, ROWS_PER_PAGE);

  return (
    <>
      {
        pagePartners.map(partner => {
          const { certifications = [] } = partner;

          return (
            certifications.map((certification, index) => (
              <Box mb={2} key={index}>
                <CertificationStatusReviewElement
                  certification={certification}
                  partner={partner}
                  reviews={reviews}
                />
              </Box>
            ))
          );
        })
      }
      <Pagination
        shape="rounded"
        count={pageCount}
        page={page}
        onChange={(event, newPage) => setPage(newPage)}
        sx={{ my: 2, display: 'flex', justifyContent: 'center' }}
      />
    </>
  );
}
