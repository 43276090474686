import React, { useState } from 'react';
import { ButtonBase, Collapse, styled } from '@mui/material';
import Typography from 'src/components/common/Typography';
import ChevronRight from 'src/components/common/icons/ChevronRight';
import EmbeddedContent from '../../EmbeddedContent';

const ExpandButton = styled(ButtonBase)({
  justifyContent: 'flex-start',
  fontSize: '0.875rem'
});

function CollapsedDefinitions({ label, content }) {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <ExpandButton onClick={() => setExpanded(!expanded)}>
        <ChevronRight expanded={expanded} />
        <Typography variant="subtitle2" bold>{label}</Typography>
      </ExpandButton>
      <Collapse in={expanded}>
        <br />
        <EmbeddedContent variant="subtitle2" dangerouslySetInnerHTML={{ __html: content }} />
      </Collapse>
    </>
  );
}

export default CollapsedDefinitions;
