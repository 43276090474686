import React, { useEffect, useState, useRef } from 'react';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  colors,
  Card,
  Typography
} from '@mui/material';
import { ChevronDown as ChevronDownIcon } from 'react-feather';
import styled from '@emotion/styled';
import _, { curry, kebabCase, noop } from 'lodash';
import Snackbar from 'src/next/mui/Snackbar';
import useCertificationId from 'src/next/certification/useCertificationId';
import usePreviousValue from 'src/next/util/usePreviousValue';
import useStore from 'src/next/store';
import useSnackbar from 'src/next/util/useSnackbar';
import wrapPromise from 'src/next/util/wrapPromise';
import useLoading from 'src/next/util/useLoading';
import CommentEdit from './CommentEdit';
import CommentList from './CommentList';

const StyledAccordionSummary = styled(AccordionSummary)`
  &.MuiAccordionSummary-root {
    padding: 0 ${({ theme }) => theme.spacing(4)};
  }

  .MuiAccordionSummary-content {
    align-items: center;
    margin: ${({ theme }) => theme.spacing(3)} 0;

    &.Mui-expanded {
      margin: ${({ theme }) => theme.spacing(3)} 0;
    }
  }

  &.Mui-expanded {
    min-height: 0;
  }

  .MuiAccordionSummary-expandIconWrapper {
    color: ${({ theme }) => theme.palette.primary.main}
  }
`;

const CommentNumber = styled.div`
  width: 24px;
  height: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: ${colors.common.white};
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

function CommentMain({ questionGroup, certificationStep }) {
  const certificationId = useCertificationId();
  const {
    groupComments,
    getGroupComments,
    creatGroupComment
  } = useStore(state => state.comment);
  const { getNotifications, markCommentNotificationsSeen } = useStore(state => state.notification);
  const [listVisible, setListVisible] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [snackbarData, openSnackbar] = useSnackbar();
  const toggleListVisible = () => setListVisible(!listVisible);
  const selector = kebabCase(`${questionGroup.name} comments`);
  const previousCommentsRef = useRef(groupComments);

  const openSuccessSnackbar = () => openSnackbar('Comment saved', 'success');
  const openErrorSnackbar = () => openSnackbar('An error occurred', 'error');
  const onError = () => {
    setIsFailed(true);
    openErrorSnackbar();
  };
  const withSnackbar = curry(wrapPromise)(_, openSuccessSnackbar, onError);

  const [create, isCreateLoading] = useLoading(withSnackbar(async data => {
    setIsFailed(false);
    await creatGroupComment({
      ...data,
      certificationStepId: certificationStep.id,
      questionGroupId: questionGroup.id
    });
  }, noop));

  useEffect(() => {
    getGroupComments({
      certificationStepId: certificationStep.id,
      questionGroupId: questionGroup.id
    });
  }, [certificationStep.id, questionGroup.id, getGroupComments]);

  const prevListVisible = usePreviousValue(listVisible);

  useEffect(() => {
    (async () => {
      if (!prevListVisible && listVisible) {
        await markCommentNotificationsSeen(groupComments.map(c => c.id));
        getNotifications(certificationId);
      }

      if (previousCommentsRef.current !== groupComments && !isCreateLoading) {
        setListVisible(false);
      }

      previousCommentsRef.current = groupComments;
    })();
  }, [listVisible, groupComments, markCommentNotificationsSeen, getNotifications]);

  return (
    <>
      <Card>
        <Accordion expanded={listVisible} onChange={toggleListVisible} TransitionProps={{ timeout: 0 }}>
          <StyledAccordionSummary
            expandIcon={<ChevronDownIcon width={16} height={16} />}
            id={`${selector}-header`}
            aria-controls={`${selector}-content`}
          >
            <Typography variant="modalHeadline" component="span" mr={2}>
              Comments
            </Typography>
            <CommentNumber>
              <Typography variant="microtext1">
                { groupComments?.length ?? 0 }
              </Typography>
            </CommentNumber>
          </StyledAccordionSummary>
          <AccordionDetails sx={{ pt: 2, px: 4 }}>
            <CommentList comments={groupComments} />
          </AccordionDetails>
          <AccordionActions sx={{ px: 4, pb: 3 }}>
            <CommentEdit isLoading={isCreateLoading} isFailed={isFailed} onSave={create} listVisible={listVisible}/>
          </AccordionActions>
        </Accordion>
      </Card>

      <Snackbar
        {...snackbarData}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        style={{ top: 125 }}
      />
    </>
  );
}

export default CommentMain;
