import React from 'react';
import assessmentType from './assessmentType';

const logoByAssessmentType = {
  [assessmentType.BEW]: {
    primary: '/static/next/bew-primary-logo.svg',
    reverse: '/static/next/bew-reverse-logo.svg'
  },
  [assessmentType.HEW]: {
    primary: '/static/next/hew-primary-logo.svg',
    reverse: '/static/next/hew-reverse-logo.svg'
  }
};

export default function AssessmentLogo({ type, variant = 'reverse', ...props }) {
  const logoUrl = logoByAssessmentType[type][variant];

  return logoUrl
    ? <img alt="Logo" src={logoUrl} {...props} />
    : null;
}
