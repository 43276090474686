import * as yup from 'yup';
import PasswordValidator from 'password-validator';

const schema = new PasswordValidator();
const passwordSchema = schema
  .has().uppercase()
  .has().lowercase()
  .has().digits()
  .has().symbols()
  .has().not().spaces();

// TODO: Remove, a number should not be partially validated just because the UI prefixes it
const phoneRegExp = /^[0-9]{10}$/;
yup.addMethod(yup.string, 'phoneNumber', function (message) {
  return this.matches(phoneRegExp, message);
});

const fullPhoneRegExp = /^\+1[0-9]{10}$/;
yup.addMethod(yup.string, 'phoneNumberFull', function (message) {
  return this.matches(fullPhoneRegExp, message);
});

const fullPhoneInternationalRegExp = /^\+(?:[0-9]●?){6,14}[0-9]$/;
yup.addMethod(yup.string, 'phoneNumberInternational', function (message) {
  return this.matches(fullPhoneInternationalRegExp, message);
});

yup.addMethod(yup.string, 'password', function (message) {
  return this.test(function (value, { createError, path }) {
    if (!value) return true;
    const isValid = passwordSchema.validate(value);
    return isValid || createError({ message, path });
  });
});

export default yup;
