import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { ROUTES } from 'src/router';

const SuccessView = () => (
  <Box>
    <Box mt={2}>
      <Button
        component={Link}
        to={ROUTES.LOGIN}
        variant="contained"
        color="primary"
        size="large">
        Return to Login
      </Button>
    </Box>
  </Box>
);

export default SuccessView;
