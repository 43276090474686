import React, { useCallback, useEffect, useState } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import {
  ButtonBase,
  Card,
  colors,
  Grid,
  Icon,
  LinearProgress,
  MenuItem,
  Paper,
  Tabs,
  Tab,
  Typography
} from '@mui/material';
import {
  Circle as CircleIcon,
  CheckCircle as CheckCircleIcon,
  ChevronDown as ChevronDownIcon,
  Flag as FlagIcon
} from 'react-feather';
import { map } from 'lodash';
import styled from '@emotion/styled';
import Popper from 'src/next/mui/Popper';
import routes from 'src/next/navigation/routes';
import { isCompleted, getProgress } from 'src/next/util/questionGroup';
import useTabsScrollButtonDisplay from 'src/next/util/useTabsScrollButtonDisplay';

const PillarTabs = styled(Tabs)`
  &.MuiTabs-root {
    justify-content: center;
  }

  & .MuiTabs-scroller {
    text-align: center;
  }

  & .MuiTabs-flexContainer {
    display: inline-flex;
    align-items: center;
  }

  & .MuiTabs-scrollButtons {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  & .MuiTabs-indicator {
    display: none;
  }

  .MuiTab-root {
    width: 260px;
    height: 68px;
    border-radius: 4px;
    color: ${({ theme }) => theme.palette.primary.main};
    border: 3px solid transparent;


    &:hover {
      color: ${colors.common.white};
      background-color: ${({ theme }) => theme.palette.primary.light};

      & .Pillar-expandIconWrapper {
        color: ${colors.common.white};
      }
    }

    & .Pillar-expandIconWrapper {
      width: 16px;
      height: 16px;
      color: ${({ theme }) => theme.palette.primary.main};
      transform: rotate(0deg);
      transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      &.Pillar-expanded {
        transform: rotate(180deg);
      }
    }

    &.Mui-selected {
      color: ${colors.common.white};
      background-color: ${({ theme }) => theme.palette.primary.main};
      border: 3px solid ${({ theme }) => theme.palette.primary.light};

      &:hover {
        opacity: 1;
      }

      & .Pillar-expandIconWrapper {
        color: inherit;
      }
    }
  }
`;

const PillarContainer = styled(props => (
  <Paper elevation={3} {...props} />
))`
  margin: ${({ theme }) => theme.spacing(2, 1, 2, 0)};

  &:first-of-type {
    margin-left: ${({ theme }) => theme.spacing(2)};
  }

  &:last-child {
    margin-right: ${({ theme }) => theme.spacing(2)};
  }
`;

const PillarContent = styled(Grid)`
  gap: ${({ theme }) => theme.spacing(1)};
  text-align: left;
  text-transform: none;
`;

const PillarProgressCard = styled(Card)`
  display: flex;
  max-width: fit-content;
  border-radius: 8px;
`;

const PillarProgressBar = styled(
  LinearProgress,
  { shouldForwardProp: prop => prop !== 'isSelected' }
)`
  width: 72px;
  height: 16px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.palette.tertiary.lighter};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.tertiary.lighter};
  box-shadow: ${({ theme }) => theme.shadows[3]};


  & .MuiLinearProgress-bar {
    border-radius: 8px;
    box-shadow: ${({ theme }) => theme.shadows[3]};
    background-color: ${({ isSelected }) => (
    isSelected
      ? ({ theme }) => theme.palette.primary.light
      : ({ theme }) => theme.palette.primary.main
  )};
  }
`;

const IndicatorPopper = styled(Popper)`
  z-index: 1000;
  & .MuiPaper-root {
    margin-top: ${({ theme }) => theme.spacing(1)};
    width: 320px;
  }
`;

const IndicatorIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin: ${({ theme }) => theme.spacing(0.5, 1, 0, 0)};
`;

const IndicatorOrder = styled(props => (
  <Typography variant="body1" component='span' {...props} />
))`
  min-width: 16px;
  text-align: right;
  white-space: pre;

  &:after {
    content: ". ";
  }
`;

const IndicatorName = styled(props => (
  <Typography variant="body1" component='span' {...props} />
))`
  flex-grow: 1;
  text-decoration: underline;
  white-space: normal;
  overflow-wrap: break-word;
  width: 50px;
`;

const Overview = styled(Typography)`
  text-decoration: underline;
`;

const useMatched = matched => useCallback(
  target => target.id === matched?.id,
  [matched]
);

function IndicatorMenu({
  anchorEl,
  indicators,
  pillar,
  certificationStep,
  certification,
  onClose,
  indicatorIndexById
}) {
  const history = useHistory();
  const open = Boolean(anchorEl);
  const useHandleClick = indicatorId => () => {
    const path = generatePath(
      routes[`CERTIFICATION_${indicatorId ? 'INDICATOR' : 'PILLAR'}`],
      {
        certificationId: certification.id,
        certificationStepId: certificationStep.id,
        pillarId: pillar.id,
        indicatorId
      }
    );

    history.push(path);
    onClose();
  };

  return (
    <IndicatorPopper
      anchorEl={anchorEl}
      open={open}
      placement="bottom-start"
      onClose={onClose}
    >
      <MenuItem onClick={useHandleClick()}>
        <Grid container wrap="nowrap">
          <IndicatorIcon component={FlagIcon}/>
          <Overview variant="body1">Overview</Overview>
        </Grid>
      </MenuItem>
      {
        map(indicators, indicator => {
          const order = indicator.isOrdered
            ? indicatorIndexById.get(indicator.id)
            : null;

          return <MenuItem
            key={indicator.id}
            onClick={useHandleClick(indicator.id)}
          >
            <Grid container wrap="nowrap">
              <IndicatorIcon
                component={
                  isCompleted(certificationStep.completion, indicator)
                    ? CheckCircleIcon
                    : CircleIcon
                }
              />
              {
                indicator.isOrdered &&
                <IndicatorOrder>{order + 1}</IndicatorOrder>
              }
              <IndicatorName>{indicator.name}</IndicatorName>
            </Grid>
          </MenuItem>;
        })
      }
    </IndicatorPopper>
  );
}

const PillarTab = React.forwardRef(function PillarTab({
  children,
  progress,
  isSelected,
  isIndicatorMenuOpen,
  ...props
}, ref) {
  return (
    <PillarContainer>
      <ButtonBase {...props} ref={ref}>
        <Grid container justifyContent="space-between" wrap="nowrap">
          <PillarContent item xs="auto" flexShrink={1} container direction="column">
            <Grid item sx={{ whiteSpace: 'break-spaces' }}>
              <Typography variant="body4" sx={{ fontWeight: 600 }}>
                {children}
              </Typography>
            </Grid>
            <Grid item>
              <PillarProgressCard>
                <PillarProgressBar
                  variant="determinate"
                  value={progress}
                  isSelected={isSelected}
                />
              </PillarProgressCard>
            </Grid>
          </PillarContent>
          <Grid
            item
            xs={1}
            alignSelf="center"
            className={
              `Pillar-expandIconWrapper ${isIndicatorMenuOpen ? 'Pillar-expanded' : ''}`
            }
          >
            <ChevronDownIcon width="16" height="16" />
          </Grid>
        </Grid>
      </ButtonBase>
    </PillarContainer>
  );
});

function PillarSubheader({
  currentPillar,
  pillars,
  certificationStep,
  certification
}) {
  const location = useLocation();
  const selectedPillar = currentPillar ?? pillars?.[0];
  const [tabsRef, hideScrollButtons] = useTabsScrollButtonDisplay();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorPillar, setAnchorPillar] = useState(null);
  const [hasAnchorChanged, setAnchorChanged] = useState(false);
  const [isIndicatorMenuOpen, setIsIndicatorMenuOpen] = useState(false);
  const [indicators, setIndicators] = useState([]);
  const isSelected = useMatched(selectedPillar);
  const isAnchor = useMatched(anchorPillar);
  const useHandleTabClick = pillar => event => {
    event.preventDefault();
    setAnchorChanged(anchorPillar?.id !== pillar.id);
    setAnchorEl(event.currentTarget);
    setAnchorPillar(pillar);
    setIsIndicatorMenuOpen(true);
  };
  const handleIndicatorMenuClose = () => {
    setIsIndicatorMenuOpen(hasAnchorChanged || false);
    setAnchorChanged(false);
  };
  const allIndicators = pillars.flatMap(p => p.indicators);
  const indicatorIndexById = allIndicators.reduce((acc, i, index) => acc.set(i.id, index), new Map());

  useEffect(() => {
    setIsIndicatorMenuOpen(false);
  }, [location]);

  useEffect(() => {
    setIndicators(isIndicatorMenuOpen
      ? pillars.find(p => p.id === anchorPillar.id).indicators
      : []
    );
  }, [isIndicatorMenuOpen, anchorPillar?.id]);

  if (!selectedPillar) {
    // TODO: Detect is loading and return appropriate loader
    return null;
  }

  return (
    <Card elevation={3}>
      <PillarTabs
        ref={tabsRef}
        value={selectedPillar.id}
        variant="scrollable"
        scrollButtons={hideScrollButtons ? false : 'auto'}
        aria-label="pillar subheader tabs"
      >
        {
          map(pillars, pillar => (
            <Tab
              key={pillar.id}
              value={pillar.id}
              to={generatePath(routes.CERTIFICATION_PILLAR, {
                certificationId: certification.id,
                certificationStepId: certificationStep.id,
                pillarId: pillar.id
              })}
              label={pillar.name}
              progress={getProgress(certificationStep.completion, pillar)}
              isSelected={isSelected(pillar)}
              isIndicatorMenuOpen={isAnchor(pillar) && isIndicatorMenuOpen}
              component={PillarTab}
              wrapped
              onClick={useHandleTabClick(pillar)}
            />
          ))
        }
      </PillarTabs>
      <IndicatorMenu
        anchorEl={isIndicatorMenuOpen ? anchorEl : null}
        indicators={indicators}
        pillar={anchorPillar ?? selectedPillar}
        certificationStep={certificationStep}
        certification={certification}
        completion={certificationStep.completion}
        onClose={handleIndicatorMenuClose}
        indicatorIndexById={indicatorIndexById}
      />
    </Card>
  );
}

export default PillarSubheader;
