import * as React from 'react';
import {
  Box,
  Button,
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AlertCircle, AlertTriangle, CheckCircle, Info } from 'react-feather';

import { DIALOG_TYPES } from '../constants';

const useStyles = makeStyles(theme => ({
  typeIcon: {
    color: ({ type = DIALOG_TYPES.INFO }) => theme.palette[type].main,
    verticalAlign: 'middle',
    marginRight: theme.spacing(1)
  },
  content: {
    lineHeight: 1.3
  }
}));

// Reusable Material UI based Dialog
// https://material-ui.com/api/dialog/
// Recommended to use through `useDialog` hook
const Dialog = React.memo(function Modal(props) {
  const {
    type,
    open,
    title,
    content,
    content2,
    dialogProps,
    dialogTitle,
    dialogContent,
    dialogActions,
    okText,
    cancelText,
    onOk,
    onCancel
  } = props;
  const { typeIcon, content: contentStyle } = useStyles(props);

  const types = {
    [DIALOG_TYPES.SUCCESS]: { comp: CheckCircle, className: typeIcon },
    [DIALOG_TYPES.INFO]: { comp: Info, className: typeIcon },
    [DIALOG_TYPES.WARNING]: { comp: AlertTriangle, className: typeIcon },
    [DIALOG_TYPES.ERROR]: { comp: AlertCircle, className: typeIcon }
  };
  const { comp: IconComp, className: iconClassName } = types[type] || {};
  return (
    <MuiDialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...dialogProps}>
      {dialogTitle || (
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
      )}
      {dialogContent || (
        <DialogContent>
          {content && (
            <Box display="flex">
              {IconComp && <IconComp className={iconClassName} size={24} />}
              <div id="alert-dialog-description" className={contentStyle}>
                {content}
              </div>
            </Box>
          )}
          {content2 && (
            <Box display="flex" mt={2}>
              {IconComp && <IconComp className={iconClassName} size={24} />}
              <div id="alert-dialog-description" className={contentStyle}>
                {content2}
              </div>
            </Box>
          )}
        </DialogContent>
      )}
      {dialogActions || (
        <DialogActions>
          {onCancel && (
            <Button onClick={onCancel} color="secondary">
              {cancelText}
            </Button>
          )}
          <Button onClick={onOk} color="secondary">
            {okText}
          </Button>
        </DialogActions>
      )}
    </MuiDialog>
  );
});

Dialog.defaultProps = {
  okText: 'OK',
  cancelText: 'Cancel',
  dialogProps: { disableBackdropClick: true, disableEscapeKeyDown: true }
};

export default Dialog;
