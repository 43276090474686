import CheckList from 'src/assets/images/welcomeSlider/cuate.png';
import SecurityPana from 'src/assets/images/welcomeSlider/security-pana.png';
import Illustration from 'src/assets/images/welcomeSlider/illustration.png';

const welcomeInfo = [
  {
    label: 'About Racial Equity at Work',
    imgPath: Illustration,
    details:
      `The overarching purpose of Racial Equity at Work is to enable employers to remove discriminatory barriers and create a level playing field in all decisions, which yields more equitable, productive, and resilient workplaces for all employees, including Black and Hispanic/Latine employees, and more equitable, prosperous, and resilient society as a whole.
      <br /><br />
      The MLT Racial Equity at Work scoring framework is solely for the purpose of assessment. The scoring framework was explicitly designed to be flexible. There are no specific progress goals or prescribed actions required. You can choose where to start and identify your own aspirations with the REW framework as your guide.`
  },
  {
    label: 'Our Commitment to Data Security and Privacy',
    imgPath: SecurityPana,
    details:
      `MLT takes data security and privacy very seriously. Information you enter is safe and secure and will be kept strictly confidential. For more, please click the <a href="https://mlt.org/privacy-policy/" rel="noreferrer" target="_blank">Privacy Policy here</a>.
      <br /><br />
      To ensure you receive communications about the digital platform or to contact us for technical support, add the following email address to your contact list: <a href="mailto:support@mltracialequityatwork.org">support@mltracialequityatwork.org</a>.`
  },
  {
    label: 'Working with Your Partner Success Team',
    imgPath: CheckList,
    details:
      `The Racial Equity at Work Certification journey provides collaboration and support for sustainable progress. Throughout your commitment to racial equity, your Operating Team will have the full support of an experienced and dedicated Partner Success Team.
      <br /><br />
      The team includes your Senior Partner Lead (SPL) and Partner Success Associate (PSA), who will be your direct point of contact, and an Engagement Lead (EL). Your Partner Success Team will:
      <br /><br />
      <ul style="padding-left:16px">
        <li>Provide clear guidance and communication regarding your certification journey with regular meetings and check-ins</li>
        <li>Support your progression through each certification milestone<br />
        Provide recommendations, insights, and best practices to support your progress and success across the indicators within the program</li>
        <li>Serve as a conductor to connect you with subject matter experts and additional resources</li>
        <li>Drive analysis on your chosen Accelerator</li>
      </ul>`
  },
  {
    label: 'Building Your Racial Equity at Work Operating Team',
    details:
      `Building your operating team is a critical first step toward success. The operating team will likely comprise five critical roles each with a specific area of focus and responsibilities. Throughout the certification journey, the operating team will meet regularly internally and with your Partner Success Team.
      <br /><br />
      <em><strong>Program Lead:</strong>  The Program Lead coordinates the internal operational team, submits vital information, and manages overall progress and timeline. (Tip: Having a co-lead is an excellent way of ensuring that multiple team members have a deep knowledge of the program and can support your continued progress.)</em>
      <br /><br />
      <em><strong>Executive Sponsor(s):</strong> Senior leaders and executives work directly with the project lead(s) to serve as accountability partners for certification progress and success. (Tip: Chief Diversity Officers, Chief Human Resource Officers, Finance, Procurement, Benefits, Operations)</em>
      <br /><br />
      <em><strong>Communication Lead(s):</strong> Works closely with the project lead(s) to serve as the main point of contact to support internal and external communications related to Racial Equity at Work. (Tip: Comm Leads should attend key milestone meetings to help prepare for internal and external announcements and ongoing communication throughout the journey) </em>
      <br /><br />
      <em><strong>Data and Functional Lead(s):</strong> Work closely with the project lead to collect and provide information on the indicators across five pillars to complete the baseline and certification assessment, and collect and provide information for your chosen Accelerator </em>
      <br /><br />
      <strong>Questions? Contact your Partner Success Team.</strong>
      `
  },
  {
    label: 'Getting Started: Annual Impact Cycle',
    details:
      `The Annual Impact Cycle begins when a partner signs the REW service agreement for the first time, or signs the renewal agreement.
      <br /><br />
      New partners will complete an initial baseline assessment, and then are eligible to submit their certification assessment a minimum of three months later or during the next certification window. Certification Assessments can be submitted each year in February or August. A company that achieves certification in the February window of a given year cannot submit in the August window.
      <br /><br />
      Companies will receive a customized report with benchmarking data and leading practice recommendations following the assessment submission.
      <br /><br />
      Each year, companies will have access to additional resources including accelerator engagements, consultations with MLT and third party resources, convenings, and DEI focused content.
      <br /><br />
      <strong>Questions? Contact your Partner Success Team.</strong>
      `
  },
  {
    label: 'Using the MLT Racial Equity at Work Digital Platform',
    details:
      `The Racial Equity at work digital platform (<a href="http://mltracialequityatwork.org/">mltracialequityatwork.org</a>) provides your organization with one convenient location to access all of the Racial Equity at Work resources.
      <br /><br />
      The digital platform provides capabilities to:
      <ul style="padding-left:16px">
        <li>Submit assessment data</li>
        <li>Find DEI resources and insights</li>
        <li>Communicate and schedule meetings with your PST</li>
        <li>Manage user access and more</li>
      </ul>
      <br />
      <strong>Accessing the Digital Platform</strong>
      <br /><br />
      The Racial Equity at Work uses a two-factor authentication process for all users. To gain access to the digital platform, provide the company email address and mobile phone number for the Project Lead to your PST. 
      <br /><br />
      Additional users from the organization can be added directly in the platform by adding the company email address and mobile phone number.
      <br /><br />
      <strong><em>To ensure you receive communications about the digital platform or to contact us for technical support, add the following email address to your contact list:</em> <a href="mailto:support@mltracialequityatwork.org">support@mltracialequityatwork.org</a></strong>.
      `
  }
];

export default welcomeInfo;
