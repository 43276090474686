import React from 'react';
import { Box, FormHelperText } from '@mui/material';

const Error = ({ children, ...props }) => (
  <Box {...props}>
    <FormHelperText error>{children}</FormHelperText>
  </Box>
);

export default Error;
