import React from 'react';
import { Box, Link, Grid, Typography, useTheme } from '@mui/material';
import { generatePath, Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import styled from '@emotion/styled';
import routes from 'src/next/navigation/routes';
import { round } from 'lodash';
import PillarSubheader from './PillarSubheader';
import PillarView from './PillarView';
import IndicatorMain from './IndicatorMain';
import QuestionGroupMetadata from './QuestionGroupMetadata';
import { getCertificationStepLabel } from '../certification/certificationUtil';

const ScoreNumber = styled.div`
  width: 59px;
  height: 59px;
  border-radius: 50%;
  color: #FFF;
  background-color: ${({ theme }) => theme.palette.rew.main};
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${({ theme }) => theme.shadows[3]};
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

const SubheaderContainer = styled(Box)`
  width: calc(100% + ${({ theme }) => theme.spacing(4)});
  padding-top: ${({ theme }) => theme.spacing(2)};
  margin-left: ${({ theme }) => theme.spacing(-1)};
  flex-shrink: 0;
`;

function usePillarNavigation(certificationId, certificationStepId, pillars, pillarIndex) {
  const history = useHistory();
  const pillar = pillars[pillarIndex];
  const routerParams = { certificationId, certificationStepId };

  const openPillarSummary = () => history.push(generatePath(routes.CERTIFICATION_PILLAR, {
    ...routerParams,
    pillarId: pillar?.id
  }));
  const openNextPillar = () => {
    const newPillar = pillars[pillarIndex + 1];

    if (newPillar) {
      return history.push(generatePath(routes.CERTIFICATION_PILLAR, { ...routerParams, pillarId: newPillar.id }));
    }

    return history.push(generatePath(routes.CERTIFICATION_STEP, routerParams));
  };

  return { openPillarSummary, openNextPillar };
}

export default function PillarMain({ certification, certificationStep, pillars = [], latestAssessmentStep }) {
  const { pillarId } = useParams();
  const indicatorId = useRouteMatch(routes.CERTIFICATION_INDICATOR)?.params?.indicatorId;
  const theme = useTheme();
  const pillarIndex = pillars.findIndex(p => p.id === +pillarId);
  const pillar = pillars[pillarIndex];
  const totalScore = round(certificationStep.score?.total, 1);
  const allIndicators = pillars.flatMap(p => p.indicators);
  const indicator = pillar?.indicators.find(i => i.id === +indicatorId) ?? null;

  const {
    openNextPillar,
    openPillarSummary
  } = usePillarNavigation(certification.id, certificationStep.id, pillars, pillarIndex);

  return (
    <Grid container spacing={2}>
      <Grid item xs={true} mt={1}>
        <Typography variant="h1">
          {getCertificationStepLabel(certificationStep)}
        </Typography>
      </Grid>
      <Grid container direction="column" justifyContent="center" item xs="auto">
        <Grid item>
          <Typography component="span" variant="h5" color="rew.main" mr={1}>
            Total Score
          </Typography>
        </Grid>
        <Grid item>
          <StyledLink
            to={generatePath(routes.CERTIFICATION_STEP, {
              certificationId: certification.id,
              certificationStepId: certificationStep.id
            })}
            color={theme.palette.tertiary.dark}
          >
            View Summary
          </StyledLink>
        </Grid>
      </Grid>
      <Grid container alignItems="center" item xs="auto">
        <ScoreNumber>
          <Typography variant="h5">
            {totalScore}
          </Typography>
        </ScoreNumber>
      </Grid>
      {
        certification?.partner?.name &&
        <Grid container item mt={-2}>
          <Grid item xs={true}>
            <Typography mt={-1} variant="h3" color="tertiary.dark">
              {certification.partner.name}
            </Typography>
          </Grid>
        </Grid>
      }
      <SubheaderContainer mt={2}>
        <PillarSubheader
          currentPillar={pillar}
          pillars={pillars}
          certificationStep={certificationStep}
          certification={certification}
        />
      </SubheaderContainer>
      <Grid item xs={8}>
        {
          pillar &&
          <Switch>
            <Route path={routes.CERTIFICATION_PILLAR} exact>
              <PillarView
                pillar={pillar}
                certificationStep={certificationStep}
                certification={certification}
              />
            </Route>
            <Route path={routes.CERTIFICATION_INDICATOR} exact>
              <IndicatorMain
                indicators={pillar.indicators}
                allIndicators={allIndicators}
                certificationStep={certificationStep}
                certification={certification}
                scores={certificationStep.score}
                latestAssessmentStep={latestAssessmentStep}
                onAfterLast={openNextPillar}
                onBeforeFirst={openPillarSummary}
              />
            </Route>
          </Switch>
        }
      </Grid>
      <Grid item xs={4}>
        {
          pillar &&
          <QuestionGroupMetadata
            questionGroup={indicator ?? pillar}
            position={certificationStep.certificationCycle.position}
          />
        }
      </Grid>
    </Grid>
  );
}
