import axios from 'axios';
import { API_URL } from '../../constants';

const DEFAULT_PAGE_SIZE = 10; // Max number of appointments to be fetched per request

const api = {
  async getAppointments(certificationId) {
    const url = `${API_URL}/appointments`;

    return axios.get(url, {
      params: {
        certificationId,
        upcoming: true,
        pageSize: DEFAULT_PAGE_SIZE
      }
    });
  }
};

export default (set, get) => ({
  appointments: [],

  async getAppointments(certificationId) {
    const appointments = await api.getAppointments(certificationId);

    set(state => ({ appointments }), 'GET_APPOINTMENTS');
  },

  resetAppointments() {
    set(state => ({ appointments: [] }), 'RESET_APPOINTMENTS');
  }
});
