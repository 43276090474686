import React from 'react';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Typography from 'src/components/common/Typography';
import NavLink from 'src/components/Navigation/Link';

const useStyles = makeStyles(({ spacing, typography, palette }) => ({
  link: {
    borderRadius: 0
  },
  title: ({ active }) => ({
    fontWeight: typography.fontWeightBold,
    color: !active && palette.neutrals.grey10
  }),
  progressTextGrid: {
    display: 'flex',
    paddingTop: spacing(0.5),
    alignItems: 'flex-start',
    justifyContent: 'flex-end'
  },
  progressText: {
    color: palette.neutrals.grey10,
    whiteSpace: 'nowrap'
  },
  icon: ({ active }) => ({
    color: active
      ? palette.secondary.main
      : palette.neutrals.grey10
  })
}));

function SummaryNavItem({
  to,
  Icon,
  CompletedIcon,
  title,
  completed = 0,
  total = 0,
  active,
  showIcon,
  disabled
}) {
  const isCompleted = completed === total;
  const classes = useStyles({ active });
  const icon = isCompleted ? CompletedIcon : Icon;

  return (
    <NavLink
      to={to}
      disabled={disabled}
      Icon={showIcon && icon}
      iconClassName={classes.icon}
      className={classes.link}
    >
      <Grid container>
        <Grid item xs={9}>
          <Typography
            component="span"
            size={14}
            className={classes.title}
          >
            {title}
          </Typography>
        </Grid>
        {/* TODO figure out what isReview was and its implication on style */}
        <Grid item xs={3} className={classes.progressTextGrid}>
          <Typography
            component="span"
            size={12}
            className={classes.progressText}>
            ({completed} of {total})
          </Typography>
        </Grid>
      </Grid>
    </NavLink>
  );
}

export default SummaryNavItem;
