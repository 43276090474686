import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, colors, Grid, Typography, useTheme } from '@mui/material';
import styled from '@emotion/styled';
import routes from 'src/next/navigation/routes';
import { Layout as Header } from 'src/next/header/AppBar';

const StyledGridContainer = styled(props => (
  <Grid
    container
    direction="column"
    justifyContent="center"
    alignItems="center"
    {...props}
  />
))`
  max-width: 1299px;
  min-height: 369px;
  margin: ${({ theme }) => theme.spacing(9)} auto 0;
  background-color: ${colors.common.white};
`;

const StyledButton = styled(Button)`
  &.MuiButton-text {
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    text-decoration: underline;
  }
`;

function ErrorPageLayout({ heading, description, onReset }) {
  const location = useLocation();
  const firstRun = useRef(true);
  const theme = useTheme();

  useEffect(() => {
    /*
      As `useEffect` is always run on the first time, just showing the page implies error state
      will be reset. To avoid such a scenario, error state is not reset on the first run, but
      on a location change.
     */
    if (firstRun.current) {
      firstRun.current = false;

      return;
    }

    onReset();
  }, [location]);

  return (
    <>
      <Header />
      <StyledGridContainer>
        <Grid item xs={true}>
          <Typography variant="h1" color={theme.palette.primary.main} pt={6}>
            {heading}
          </Typography>
        </Grid>
        <Grid item xs={true}>
          <Typography variant="subtitle1" pt={2}>
            {description}
          </Typography>
        </Grid>
        <Grid item xs={true}>
          <StyledButton
            to={routes.HOME}
            variant="text"
          >
            Return to Dashboard
          </StyledButton>
        </Grid>
      </StyledGridContainer>
    </>
  );
}

export default ErrorPageLayout;
