import React from 'react';
import { Box, Button as MuiButton, MobileStepper, styled } from '@mui/material';

const Stepper = styled(MobileStepper)(({ theme }) => ({
  backgroundColor: 'inherit',
  '& .MuiMobileStepper-dot': {
    width: 13,
    height: 13,
    marginRight: theme.spacing(1),
    backgroundColor: '#ebebeb'
  },
  '& .MuiMobileStepper-dotActive': {
    backgroundColor: '#c4c4c4'
  }
}));

const Button = styled(MuiButton)(() => ({
  fontSize: 18,
  fontWeight: 600
}));

const BackButton = ({ onClick, disabled, ...props }) => (
  <Box display="flex" {...props}>
    <Button onClick={onClick} disabled={disabled}>Back</Button>
  </Box>
);

const NextButton = ({ onClick, children, ...props }) => (
  <Box {...props}>
    <Button onClick={onClick} color="primary" variant="contained">
      {children}
    </Button>
  </Box>
);

function Navigation({ onBack, onNext, onClose, activeStep, numberOfSteps }) {
  const isFirstStep = activeStep === 0;
  const isLastStep = activeStep + 1 === numberOfSteps;

  return (
    <>
      <Stepper activeStep={activeStep} steps={numberOfSteps} position="static" variant="dots" />
      <Box mt={3} display="flex" justifyContent="center">
        <BackButton
          onClick={onBack}
          justifyContent="flex-end"
          flex="1"
          mr={0.5}
          disabled={isFirstStep}
        />
        <NextButton onClick={isLastStep ? onClose : onNext} ml={0.5} flex="1">
          {isLastStep ? 'Get Started' : 'Next'}
        </NextButton>
      </Box>
    </>
  );
}

export default Navigation;
