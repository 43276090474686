import React, { useEffect } from 'react';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { create } from 'jss';
import { createTheme } from 'src/theme';
import GlobalStyles from 'src/components/GlobalStyles';
import { DialogProvider } from 'src/contexts/DialogContext';
import rtl from 'jss-rtl';
import useSettings from 'src/hooks/useSettings';
import { ThemeProvider, StyledEngineProvider, adaptV4Theme } from '@mui/material';
import jssPreset from '@mui/styles/jssPreset';
import StylesProvider from '@mui/styles/StylesProvider';
import EmployeeView from 'src/employee';
import PsmView from 'src/psm';
import AdminView from 'src/admin';
import Router from 'src/router';
import useStore, { useActions } from 'src/store';
import { useAccount } from 'src/store/auth/hooks';
import { selectIsSessionLoaded } from 'src/store/auth/selectors';
import globalStyles from './global.styles';
import 'src/utils/axios';
import { ROLES } from './constants';

const queryCache = new QueryCache({
  defaultConfig: {
    queries: { refetchOnWindowFocus: false }
  }
});

const Setup = () => {
  const { settings } = useSettings();
  const isSessionLoaded = useStore(selectIsSessionLoaded);
  const { loadSession } = useActions('auth');
  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
  jss.createStyleSheet(globalStyles).attach();
  const theme = createTheme(adaptV4Theme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  }));

  useEffect(() => {
    loadSession();
  }, [loadSession]);

  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <StylesProvider jss={jss}>
            <GlobalStyles />
            <Router>
              <DialogProvider>
                {isSessionLoaded && <App />}
              </DialogProvider>
            </Router>
          </StylesProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </ReactQueryCacheProvider>
  );
};

function App() {
  const account = useAccount();
  if (account.role === ROLES.EMPLOYEE) return <EmployeeView />;
  if (account.role === ROLES.STAFF) return <PsmView />;
  if (account.role === ROLES.ADMIN) return <AdminView />;
  return null;
}

export default Setup;
