import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import FileUploadModal from './FileUploadModal';
import useStore from '../certification/certificationStore';
import ActionsPopper from '../mui/ActionsPopper';
import usePopperState from '../util/usePopperState';

export default function ReportContextMenu({
  asset: initialAsset,
  certificationId,
  certificationStepId,
  trigger,
  canManageReports,
  themeColor
}) {
  const [asset, setAsset] = useState(initialAsset);
  const [popperContent, setPopperContent] = useState([]);
  const [modalOpen, setModalOpen] = useState(null);

  const { getAssetAccessKey } = useStore();

  const fetchAssetViewUrl = async data => {
    const response = await getAssetAccessKey(data);
    return response.data;
  };

  const [
    anchorEl,
    isPopperOpen,
    onPopperOpen,
    onPopperClose
  ] = usePopperState();

  const openModal = action => {
    setModalOpen(action);
    onPopperClose();
  };

  const popperActionsHandler = new Map([
    ['View', async () => {
      const url = await fetchAssetViewUrl({ assetName: asset?.assetName });
      if (url) {
        window.open(url, '_blank');
      }
    }],
    ['Replace', () => { openModal('Replace'); }],
    ['Remove', () => { openModal('Remove'); }],
    ['Add Report', () => { openModal('Add'); }]
  ]);

  const handlePopperActionClick = action => {
    const handler = popperActionsHandler.get(action);
    if (handler) handler();
  };

  const handleAssetDeletion = () => {
    setAsset(null);
    setPopperContent(['Add Report']);
  };

  const handleAssetUpload = newAsset => {
    setAsset(newAsset);
    setPopperContent(['View', 'Replace', 'Remove']);
  };

  const handleClick = event => {
    if (!asset) {
      setPopperContent(['Add Report']);
      return onPopperOpen(event);
    }

    const content = canManageReports ? ['View', 'Replace', 'Remove'] : ['View'];
    setPopperContent(content);
    onPopperOpen(event);
  };

  const handleClickAway = () => {
    onPopperClose();
  };

  return (
    <Box>
      {React.cloneElement(trigger, { onClick: handleClick })}
      <ActionsPopper
        anchorEl={anchorEl}
        open={isPopperOpen}
        onClose={handleClickAway}
      >
        {popperContent.map((item, index) => (
          <Typography key={index} onClick={() => handlePopperActionClick(item)}>{item}</Typography>
        ))}
      </ActionsPopper>
      {modalOpen && (
        <FileUploadModal
          open={!!modalOpen}
          action={modalOpen}
          onClose={() => setModalOpen(null)}
          asset={asset}
          certificationId={certificationId}
          certificationStepId={certificationStepId}
          onAssetDeleted={handleAssetDeletion}
          onAssetUploaded={handleAssetUpload}
          themeColor={themeColor}
        />
      )}
    </Box>
  );
}
