import { getSettings } from 'src/api/settings';

export default (set, get) => ({
  assessmentRanges: null,
  currentDate: null,

  async getSettings() {
    const data = await getSettings();
    const currentDate = new Date(data.currentDate || Date.now());

    set(state => ({ assessmentRanges: data.assessmentRanges, currentDate }), 'GET_SETTINGS');
  }
});
