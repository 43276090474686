import yup from 'src/shared/validation';

const authValidationSchema = yup.object({
  email: yup.string().email('Email must be a valid email').required('Email is required'),
  password: yup.string()
    .min(8, 'Passwords must be at least 8 characters in length')
    .max(99, 'Password must be at most 99 characters in length')
    .password(
      'Passwords must contain lowercase and uppercase letters, at least one number, and at least one special character'
    )
    .required('Password is required')
});
const loginValidationSchema = authValidationSchema.shape({
  password: yup.string().required('Password is required')
});
const resetPasswordValidationSchema = authValidationSchema.shape({
  password: yup.string()
  .min(8, 'Passwords must be at least 8 characters in length')
  .max(99, 'Password must be at most 99 characters in length')
  .password(
    'Passwords must contain lowercase and uppercase letters, at least one number, and at least one special character'
  )
  .required('New Password is required'),
  confirmPassword: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm New Password is required')
});
const forgotPasswordValidationSchema = resetPasswordValidationSchema.shape({
  code: yup.string().required('Verification Code is required')
});

export { loginValidationSchema, forgotPasswordValidationSchema, resetPasswordValidationSchema };
