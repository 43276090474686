import React, { createContext, useRef, useState } from 'react';

import Dialog from '../components/Dialog';

export const DialogContext = createContext();

export function DialogProvider({ children }) {
  const [dialogs, setDialogs] = useState([]);

  const openDialog = ({ onOk = closeDialog, ...rest }) => {
    const dialog = { ...rest, open: true, onOk };
    setDialogs(dialogs => [...dialogs, dialog]);
  };

  const closeDialog = () => {
    setDialogs(dialogs => {
      const latestDialog = dialogs.pop();
      if (!latestDialog) return dialogs;
      if (latestDialog.onClose) latestDialog.onClose();
      return [...dialogs].concat({ ...latestDialog, open: false });
    });
  };

  const contextValue = useRef([openDialog, closeDialog]);

  return (
    <DialogContext.Provider value={contextValue.current}>
      {children}
      {dialogs.map((dialog, index) => {
        return <Dialog key={index} {...dialog} />;
      })}
    </DialogContext.Provider>
  );
}

export const DialogConsumer = DialogContext.Consumer;
