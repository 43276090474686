export default {
  LOGIN: '/login',
  MFA_AUTH: '/login/auth',
  RESET_PASSWORD: '/reset-password',
  FORGOT_PASSWORD: '/forgot-password',
  HOME: '/dashboard',
  DASHBOARD: '/dashboard',
  CERTIFICATION_BASELINE: '/certifications/:id/baselines/:baselineId',
  CERTIFICATION_BASELINE_PILLAR:
    '/certifications/:id/baselines/:baselineId/pillars/:pillarId',
  SETTINGS: '/settings',
  SETTINGS_MANAGE_USERS: '/settings/manage-users',
  SETTINGS_ADD_USERS: '/settings/add-users',
  NEXT: '/next'
};
