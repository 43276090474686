import React from 'react';
import { styled } from '@mui/material';
import MuiChevronRightIcon from '@mui/icons-material/ChevronRight';

const ChevronRightIcon = ({ expanded, ...props }) => <MuiChevronRightIcon {...props} />;

export default styled(ChevronRightIcon)({
  transform: ({ expanded }) => expanded ? 'rotate(90deg)' : '',
  transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
});
