import { createTheme } from '@mui/material/styles';
import base from './rewTheme';

export default createTheme({
  ...base,
  palette: {
    ...base.palette,
    primary: {
      main: '#E56D12',
      light: '#FCB00A',
      lighter: '#FAF0DE',
      dark: '#6A3900',
      contrastText: '#FFF'
    },
    gradient: {
      ...base.palette.gradient,
      primary: 'linear-gradient(180deg, rgba(204, 73, 0, 0.63) 0%, #CC4900 100%)'
    },
    breadcrumbs: '#FAF0DE'
  }
});
