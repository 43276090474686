import React, { useState } from 'react';
import { Button, Dialog, Divider, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import _, { curry, noop } from 'lodash';
import styled from '@emotion/styled';
import useStore from 'src/next/store';
import Snackbar from 'src/next/mui/Snackbar';
import useSnackbar from 'src/next/util/useSnackbar';
import wrapPromise from 'src/next/util/wrapPromise';
import useLoading from 'src/next/util/useLoading';
import ResourceItem from './ResourceItem';

const SUPPLIER_IO_DESTINATION_NAME = 'supplierIo';

const StyledResourceItem = styled(ResourceItem)`
  & :hover {
    cursor: pointer;
  }
`;

function SupplierIODialog({ isOpen = false, isLoading = false, onClose, onConfirm }) {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth='md'
      fullWidth
    >
      <Grid container
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
        p={4}
      >
        <Grid item xs={12}>
          <Typography variant="modalHeadline">
            Find Diverse Suppliers and Vendors for Your Organization
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            MLT Racial Equity at Work has partnered with Supplier.io to provide you with a list of
            Black- and Hispanic-owned suppliers and vendors. Working with these vendors can increase
            your score, and – more importantly – increase your organization’s efforts to create a more
            equitable workplace. To access this list, sign up using the button below; we will share
            your name and email address with Supplier.io, who will provide you with login credentials to
            the Supplier.io system within one business day.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          columnSpacing={6}
          mt={1}
        >
          <Grid item>
            <LoadingButton loading={isLoading} onClick={onConfirm} sx={{ px: 6 }}>
              Sign Up
            </LoadingButton>
          </Grid>
          <Grid item>
            <Button variant="unmarked" size="small" onClick={onClose}>
              Close
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} container rowSpacing={2} mt={3}>
          <Grid item xs={12}>
            <Divider sx={{ color: 'tertiary.main', borderColor: 'inherit' }} />
          </Grid>
          <Grid item>
            <Typography variant="bodyBold">
              Have a Supplier.io account already?
            </Typography>
            <Typography mt={1}>
              If you already have an account with Supplier.io, please visit <a
                href="https://explorer.supplier.io/Login/sign_in"
                target="_blank"
                rel="noreferrer noopener"
              >
                Supplier.io
              </a> to access the full list of diverse suppliers and vendors.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}

function SupplierIOResource({ title, ...props }) {
  const { sendToDestination } = useStore(state => state.user);
  const [isOpen, setIsOpen] = useState(false);
  const [snackbarData, openSnackbar] = useSnackbar();
  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);
  const openSuccessSnackbar = () => openSnackbar('Your request has been sent to Supplier.io', 'success');
  const openErrorSnackbar = () => openSnackbar('An error occurred', 'error');
  const withSnackbar = curry(wrapPromise)(_, openSuccessSnackbar, openErrorSnackbar);
  const [confirm, isConfirmLoading] = useLoading(withSnackbar(async data => {
    await sendToDestination(SUPPLIER_IO_DESTINATION_NAME);
    closeDialog();
  }, noop));

  return (
    <>
      <div>
        <StyledResourceItem
          title="Find Diverse Suppliers and Vendors for Your Organization"
          component="div"
          onClick={openDialog}
          image="/static/next/supplier-io.png"
          {...props}
        />
        <SupplierIODialog
          isOpen={isOpen}
          isLoading={isConfirmLoading}
          onClose={closeDialog}
          onConfirm={confirm}
        />
      </div>
      <Snackbar
        {...snackbarData}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        style={{ top: 125 }}
      />
    </>
  );
}

export default SupplierIOResource;
