import { useMemo } from 'react';
import useCertificationId from 'src/next/certification/useCertificationId';
import useStore from 'src/next/store';

function useCertificationAssignmentType() {
  const { certificationAssignments } = useStore(state => state.auth.user);
  const certificationId = useCertificationId();

  return useMemo(() => {
    const currentCertification = certificationAssignments.find(c => c.certification === certificationId);

    return currentCertification?.type;
  }, [certificationId, certificationAssignments]);
}

export default useCertificationAssignmentType;
