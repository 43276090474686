import winston, { format } from 'winston';
import Transport from 'winston-transport';
import * as api from 'src/api/logs';
import { LOG_LEVEL } from 'src/constants';

/* eslint-disable no-console */

class HttpApiTransport extends Transport {
  async log(info, callback = () => { }) {
    setImmediate(() => this.emit('logged', info));

    try {
      await api.add({ level: info.level, message: info.message });
    } catch (err) {
      console.error('Failed to post logs to the server.\n', err);
    } finally {
      callback();
    }
  }
}

const { combine, simple, json, timestamp, colorize } = format;
const jsonConsoleTransport = new winston.transports.Console({ format: combine(timestamp(), json()) });
const simpleConsoleTransport = new winston.transports.Console({ format: combine(colorize(), simple()) });
const httpApiTransport = new HttpApiTransport();

const logger = winston.createLogger({
  level: LOG_LEVEL,
  transports: [simpleConsoleTransport, jsonConsoleTransport, httpApiTransport]
});

logger.on('error', err => console.error('An error within the logger occured.\n', err));

export default logger;
