import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Card, CardContent, Divider, Link, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ROUTES } from 'src/router';
import AuthLayout from 'src/layouts/AuthLayout';

import ResetPassword from './ResetPassword';

const useStyles = makeStyles(theme => ({
  cardContent: {
    padding: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    minHeight: 400
  }
}));

const ResetPasswordView = () => {
  const classes = useStyles();
  const [progressLoading, setProgressLoading] = useState(false);
  const title = 'Set Password';

  return (
    <AuthLayout title={title} isLoading={progressLoading}>
      <Card>
        <CardContent className={classes.cardContent}>
          <Box my={1} alignItems="center">
            <div>
              <Typography
                style={{ fontWeight: 'bold', fontSize: 24 }}
                color="textPrimary"
                variant="subtitle1"
                gutterBottom>
                {title}
              </Typography>
            </div>
          </Box>
          <Divider />
          <Box flexGrow={1} mt={2}>
            <ResetPassword setProgressLoading={setProgressLoading} />
          </Box>
          <Box my={3}>
            <Divider />
          </Box>
          <Link
            component={RouterLink}
            to={ROUTES.LOGIN}
            variant="body2"
            color="textSecondary"
            underline="hover">
            Cancel
          </Link>
        </CardContent>
      </Card>
    </AuthLayout>
  );
};

export default ResetPasswordView;
