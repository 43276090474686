import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export default function ConfirmationDialog({
  title,
  open = false,
  disabled = false,
  loading = false,
  closeButtonLabel = 'Cancel',
  confirmButtonLabel = 'Confirm',
  closeButtonColor = 'primary',
  confirmButtonColor = 'primary',
  onClose,
  onConfirm,
  children
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='md'
      fullWidth
    >
      <DialogTitle variant='modalHeadline'>
        {title}
      </DialogTitle>
      <DialogContent variant='subtitle2'>
        {children}
      </DialogContent>
      <DialogActions sx={{ mb: 2 }}>
        <Grid container justifyContent='center' spacing={1}>
          <Grid item>
            <LoadingButton
              disabled={disabled}
              loading={loading}
              onClick={onConfirm}
              color={confirmButtonColor}
            >
              {confirmButtonLabel}
            </LoadingButton>
          </Grid>
          <Grid item>
            <LoadingButton
              variant='text'
              disabled={loading}
              onClick={onClose}
              color={closeButtonColor}
            >
              {closeButtonLabel}
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
