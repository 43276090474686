import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Box,
  TextField,
  Button
} from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import withFormik from 'src/next/util/withFormik';
import yup from 'src/shared/validation';
import UserActiveLabel from './UserActiveLabel';

const StyledTextField = withFormik(props => (
  <TextField
    fullWidth
    InputLabelProps={{ shrink: true }}
    sx={{ mb: 5 }}
    {...props}
  />
));

const StyledTextFieldForPhone = withFormik(props => (
  <MuiPhoneNumber
    fullWidth
    InputLabelProps={{ shrink: true }}
    sx={{ '& svg': { height: '0.85em' }, mb: 5 }}
    autoFormat={false}
    countryCodeEditable={false}
    variant='outlined'
    defaultCountry='us'
    disableAreaCodes={true}
    inputProps={{
      onChange: props.onChange
    }}
    {...props}
  />
));

export default function UserEdit({ user = {}, title, note = '', isLoading = false, onSave, onClose }) {
  const exists = !!user.id;

  const [validationSchema, setValidationSchema] = useState(
    yup.object({
      first: yup.string().required('First name is required'),
      last: yup.string().required('Last name is required'),
      mobile: yup.string().phoneNumberInternational('Please enter a valid mobile phone number starting with a plus sign, followed by the country code and national number').required('Mobile phone number is required'),
      workPhone: yup.string().phoneNumberInternational('Please enter a valid work phone number starting with a plus sign, followed by the country code and national number').required('Work phone number is required'),
      email: yup.string().email('Please enter a valid email').required('Email is required')
    })
  );

  const formik = useFormik({
    initialValues: { first: '', last: '', email: '', mobile: '', workPhone: '', ...user },
    validationSchema,
    onSubmit: onSave
  });

  // Update validation schema dynamically to make an exception
  // for users with no workPhone saved in DB
  useEffect(() => {
    setValidationSchema(prevSchema => {
      return yup.object({
        ...prevSchema.fields,
        workPhone: exists && !user.workPhone ? yup.string().nullable().phoneNumberInternational('Please enter a valid work phone number starting with a plus sign, followed by the country code and national number').required('Work phone number is required') : prevSchema.fields.workPhone
      });
    });
  }, [exists, user.workPhone]);

  return (
    <Box p={2}>
      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        mb={2}
      >
        <Grid item container width='auto' alignItems='center'>
          <Grid item>
            <Typography variant='modalHeadline' mr={2}>
              {title}
            </Typography>
          </Grid>
          <Grid item>
            {
              exists &&
              <UserActiveLabel isEnabled={user.enabled} />
            }
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant='smallLabel2'>
            {note}
          </Typography>
        </Grid>
      </Grid>

      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <StyledTextField
              label="First Name"
              name="first"
              formik={formik}
              disabled={exists || isLoading}
            />
          </Grid>
          <Grid item xs={6}>
            <StyledTextField
              label="Last Name"
              name="last"
              formik={formik}
              disabled={exists || isLoading}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <StyledTextFieldForPhone
              label="Phone (Mobile)"
              name="mobile"
              formik={formik}
              disabled={isLoading}
            />
          </Grid>
          <Grid item xs={6}>
            <StyledTextFieldForPhone
              label="Phone (Work)"
              name="workPhone"
              formik={formik}
              disabled={isLoading}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <StyledTextField
              label="Email Address"
              name="email"
              formik={formik}
              disabled={isLoading}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent='center' spacing={1}>
          <Grid item>
            <LoadingButton type='submit' loading={isLoading}>
              Save
            </LoadingButton>
          </Grid>
          <Grid item>
            <Button variant='text' disabled={isLoading} onClick={onClose}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
