import { useCallback, useState } from 'react';

export default function useLoading(fn) {
  const [isLoading, setIsLoading] = useState(false);
  const wrappedFn = useCallback(async (...args) => {
    setIsLoading(true);
    try {
      // Need to await before returning so `finally` isn't called too soon
      const value = await fn(...args);

      return value;
    } finally {
      setIsLoading(false);
    }
  }, [fn]);

  return [wrappedFn, isLoading];
}
