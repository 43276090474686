import React from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { User as UserIcon } from 'react-feather';
import styled from '@emotion/styled';
import { map } from 'lodash';
import { prettifyDateTime } from 'src/utils/datetime';

const AvatarContainer = styled.div`
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const AvatarImage = styled(AvatarContainer)`
  object-fit: cover;
`;

const AvatarIcon = styled(AvatarContainer)`
  border: 1px solid ${({ theme }) => theme.palette.tertiary.lighter};
  fill: currentColor;
  stroke-linecap: butt;
`;

function CommentListItem({ comment, final }) {
  const { first, last, photoUrl } = comment.user;
  const fullName = `${first} ${last}`;

  return (
    <>
      <Grid item xs={12} container columnSpacing={2} rowSpacing={2}>
        <Grid item>
          {
            photoUrl
              ? <AvatarImage as="img" alt={fullName} src={photoUrl} />
              : <AvatarIcon as={UserIcon} />
          }
        </Grid>
        <Grid item xs={true} container direction="column">
          <Grid item container justifyContent="space-between">
            <Grid item>
              <Typography variant="subtitle2">
                {fullName}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="smallLabel2" sx={{ color: 'tertiary.main' }}>
                {prettifyDateTime(comment.createdAt)}
              </Typography>
            </Grid>
          </Grid>
          <Grid item py={1}>
            <Typography variant="userText" sx={{ whiteSpace: 'pre-line' }}>
              {comment.content}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {
        !final
          ? (
            <Divider flexItem sx={{ mt: 3 }} />
          )
          : null
      }
    </>
  );
}

function CommentList({ comments = [] }) {
  const isFinal = idx => idx === (comments.length - 1);

  return (
    <Grid container direction="column" rowSpacing={3}>
      {
        map(comments, (comment, idx) => (
          <CommentListItem
            key={comment.id}
            comment={comment}
            final={isFinal(idx)}
          />
        ))
      }
    </Grid>
  );
}

export default CommentList;
