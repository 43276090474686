import { getBaseline } from 'src/api/baselines';
import { selectLatestBaseline } from '../selectors';

const init = () => ({
  isLoading: false,
  current: null
});

export const createActions = (set, get) => ({
  refetch: async () => {
    const { current: certification } = get().certification;
    const { current: baseline } = get().baseline;
    // TODO(marko): This should be set to `false` after request finishes? Also add error handling.
    set(state => { state.baseline.isLoading = true; }, 'baseline/refetchBegin');
    const data = await getBaseline(certification.id, baseline.id);
    set(state => { state.baseline.current = data; }, 'baseline/refetchSuccess');
  },
  fetch: (certificationId, id) => {
    set(state => { state.baseline.isLoading = true; }, 'baseline/fetchBegin');
    return getBaseline(certificationId, id)
      .then(baseline => set(state => { state.baseline.current = baseline; }, 'baseline/fetchSuccess'))
      .finally(() => set(state => { state.baseline.isLoading = false; }, 'baseline/fetchFinally'));
  },
  dispose: () => {
    set(state => { state.baseline.current = null; }, 'baseline/dispose');
  }
});

export default { init, createActions };

export function setLatestCertificationBaseline(state, certification) {
  const { fetch, dispose } = state.actions.baseline;
  const latestBaseline = selectLatestBaseline(state);
  if (!latestBaseline) return dispose();
  const { id } = latestBaseline;
  fetch(certification.id, id);
}
