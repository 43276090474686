import oldStore from 'src/store';
import { logout } from 'src/utils/auth';

export default (set, get, getRoot) => ({
  user: null,

  async initialize() {
    const oldStoreState = oldStore.getState();
    const { isSessionLoaded } = oldStoreState.auth;

    if (!isSessionLoaded) {
      const { loadSession } = oldStoreState.actions.auth;

      await loadSession();
    }

    const { account } = oldStoreState.auth;

    set(state => ({ user: account }), 'INITIALIZE_USER');
  },

  hasRole(role) {
    return get().user?.role === role;
  },

  logout() {
    logout();

    getRoot().reset();
    // TODO(marko): Remove this once we move to new store completely.
    oldStore.getState().reset();
  }
});
