import { useCallback } from 'react';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import { compareByDate } from 'src/utils/datetime';

export const selectCertification = state => state.certification.current;
export const selectAssessment = state => state.certification.current?.assessment;
export const selectIsCertificationSaving = state => state.certification.isSaving;
export const selectIsCertificationLoading = state => state.certification.isLoading;

export const selectLatestBaseline = state => {
  const certification = selectCertification(state);
  const baselines = get(certification, 'baselines', []);
  const [baseline] = [...baselines].sort(compareByDate('createdAt'));
  return baseline;
};

export const selectQuestionNumbering = state => {
  const { pillars } = selectAssessment(state);
  const indicators = flatMap(pillars, it => it.indicators);
  const questions = flatMap(indicators, it => it.questions);

  return questions.reduce((numbering, question, idx) => ({
    ...numbering,
    [question.id]: idx + 1
  }), {});
};

function getIndicatorMaxScore(indicator) {
  const maxScore = get(indicator, 'scoring.maxScore', Infinity);
  const questionsMaxScore = indicator.questions.reduce((acc, question) => acc + question.maxScore, 0);

  return Math.min(maxScore, questionsMaxScore);
}

function getPillarMaxScore(pillar) {
  return pillar.indicators.reduce((acc, indicator) => acc + getIndicatorMaxScore(indicator), 0);
}

function getAssessmentMaxScore(assessment) {
  return assessment.pillars.reduce((acc, pillar) => acc + getPillarMaxScore(pillar), 0);
}

export const selectMaxScore = state => {
  const assessment = selectAssessment(state);

  return getAssessmentMaxScore(assessment);
};

export const selectPillarMaxScore = id => useCallback(state => {
  const { pillars } = selectAssessment(state);
  const pillar = pillars.find(it => it.id === id);

  return getPillarMaxScore(pillar);
}, [id]);

export const selectIndicatorMaxScore = id => useCallback(state => {
  const { pillars } = selectAssessment(state);
  const indicators = flatMap(pillars, it => it.indicators);
  const indicator = indicators.find(it => it.id === id);

  return getIndicatorMaxScore(indicator);
}, [id]);
