import React from 'react';
import useStore from 'src/store';
import { selectCertification } from 'src/store/certification/selectors';
import { useFetchCertification } from 'src/store/certification/hooks';
import DashboardLayout from 'src/layouts/DashboardLayout';
import { selectPartner } from 'src/store/partner/selectors';
import { useFetchPartner } from 'src/store/partner/hooks';
import Router from './router';
import Navigation from './components/Navigation';

export default App;

function App() {
  const certification = useStore(selectCertification);
  const partner = useStore(selectPartner);

  useFetchPartner();
  useFetchCertification();

  if (!certification) return null;
  return (
    <DashboardLayout
      organization={partner}
      renderNavigation={() => <Navigation />}
    >
      <Router />
    </DashboardLayout>
  );
}
