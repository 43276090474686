import React, { Children } from 'react';
import { Typography, Box, Button } from '@mui/material';
import styled from '@emotion/styled';
import Popper from '../mui/Popper';

const ActionButton = styled(({ children, ...props }) => (
  <Button
    variant='unmarked'
    size='small'
    {...props}
  >
    <Typography
      className='action-text'
      variant="body2"
      role='button'
    >
      {children}
    </Typography>
  </Button>
))`
  padding: ${({ theme }) => theme.spacing(0, 1, 1, 1)};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;

  .action-text {
    text-decoration: underline;
    color: white;
  }
`;

const ActionsTriangle = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 5px solid ${props => props.theme.palette.tertiary.dark};
  margin: auto;
`;

const ActionsContainer = styled(({ children, ...props }) => (
  <>
    <ActionsTriangle />
    <Box
      pt={1}
      {...props}
    >
      {Children.map(Children.toArray(children), (c, i) => (
        c &&
        <ActionButton key={i} {...c.props} />
      ))}
    </Box>
  </>
))`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.tertiary.dark};
  border-radius: 5px;
`;

export default function ActionsPopper({
  anchorEl,
  open,
  onClose,
  children
}) {
  return (
    <Popper
      placement="bottom"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      elevation={0}
      backgroundColor='transparent'
    >
      <ActionsContainer>
        {children}
      </ActionsContainer>
    </Popper>
  );
}
