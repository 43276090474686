import React from 'react';
import { colors, Grid, Link } from '@mui/material';
import styled from '@emotion/styled';
import { ArrowRight as ArrorRightIcon } from 'react-feather';
import ResourceIcon from './ResourceIcon';

const ICON_COLOR = '#263238';

const ItemLink = styled(props => (
  <Link rel="noreferrer noopener" {...props} />
))`
  margin-bottom: ${({ theme }) => theme.spacing(1)};

  &:last-child {
    margin-bottom: 0;
  }
`;

const ItemContent = styled(props => (
  <Grid container wrap="nowrap" {...props} />
))`
  align-items: center;
  min-height: 60px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.palette.tertiary.lighter};
  border-radius: 4px;
  padding: ${({ hasPadding, theme }) => hasPadding ? theme.spacing(1, 2) : '0 16px 0 0'};
`;

const ItemType = styled(props => (
  <Grid container {...props} />
))`
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  color: ${ICON_COLOR};
  background-color: #ECEFF1;
`;

const ImageWrapper = styled(props => (
  <Grid container {...props} />
))`
  align-items: center;
  width: 170px;
  height: 78px;
  background-color: #ECEFF1;
`;

const ItemTitle = styled(props => (
  <Grid item component="span" {...props} />
))`
  margin-left: ${({ theme }) => theme.spacing(2)};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  color: ${colors.common.black};
`;

const ItemAction = styled(props => (
  <Grid item {...props} />
))`
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  color: ${ICON_COLOR};
`;

function ResourceItem({ link, title, image, ...props }) {
  return (
    <ItemLink href={link} {...props}>
      <ItemContent hasPadding={!image}>
        {
          image ? (
            <ImageWrapper>
              <img src={image} width="170" />
            </ImageWrapper>
          ) : (
            <ItemType>
              <ResourceIcon width="20" height="24" {...props} />
            </ItemType>
          )
        }
        <ItemTitle>
          {title}
        </ItemTitle>
        <ItemAction>
          <ArrorRightIcon width="20" height="20" />
        </ItemAction>
      </ItemContent>
    </ItemLink>
  );
}

export default ResourceItem;
