import React from 'react';
import { styled, TextField as MuiTextField } from '@mui/material';
import { useErrorOutlineStyles } from 'src/hooks/useGlobalStyles';
import { applyMargins } from 'src/theme/margin';

const TextField = ({ name, formBag, InputProps = {}, ...props }) => {
  const globalErrorOutineClasses = useErrorOutlineStyles();
  const { touched, error } = formBag ? formBag.getFieldMeta(name) : {};
  const fieldProps = formBag ? formBag.getFieldProps(name) : {};

  return (
    <MuiTextField
      variant="outlined"
      margin="normal"
      error={touched && !!error}
      helperText={(touched && error) || ' '}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: globalErrorOutineClasses.label,
          focused: touched && error ? globalErrorOutineClasses.error : globalErrorOutineClasses.focusedLabel
        }
      }}
      fullWidth
      {...props}
      {...fieldProps}
      InputProps={{
        classes: {
          root: globalErrorOutineClasses.textFieldRoot,
          error: globalErrorOutineClasses.error,
          notchedOutline: globalErrorOutineClasses.notchedOutline,
          focused: touched && error ? globalErrorOutineClasses.error : globalErrorOutineClasses.clicked
        },
        ...InputProps
      }}
    />
  );
};

export default styled(TextField)(applyMargins);
