import React from 'react';
import { Box, Card, Modal, Paper, Portal, styled } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Typography from 'src/components/common/Typography';
import AppFooter from 'src/next/footer/AppFooter';
import Slide from './Slide';
import Navigation from './Navigation';
import welcomeInfo from './content';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    top: '64px',
    outline: 'none',
    zIndex: 2000,
    height: '94%',
    width: '100%',
    backgroundColor: '#F7F8FC',
    overflow: 'auto',
    borderRadius: 0,
    '& .MuiMobileStepper-root': {
      justifyContent: 'center'
    }
  },
  slideFooter: {
    marginBottom: '65px',
    zIndex: 2500
  },
  sliderContainer: {
    width: '80%',
    margin: 'auto',
    maxWidth: '1440px',
    position: 'relative',
    top: '-40%'
  },
  sliderRoot: {
    margin: 'auto',
    marginTop: theme.spacing(8),
    padding: theme.spacing(7),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    borderRadius: '10px'
  },
  contentList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  detailsList: {
    paddingLeft: theme.spacing(7.5)
  },
  detailTitle: {
    fontWeight: 600,
    fontSize: 18
  },
  detailImg: {
    width: 25,
    height: 25,
    marginRight: theme.spacing(2),
    overflow: 'hidden'
  },
  link: {
    marginTop: theme.spacing(1),
    textDecoration: 'underline'
  },
  whiteText: {
    color: '#fff'
  }
}));

const Background = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: '40%'
}));

const WelcomeSlider = ({ open, onClose }) => {
  const containerEl = React.createRef();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const numberOfSteps = welcomeInfo.length;
  const activeSlide = welcomeInfo[activeStep];
  const scrollToTop = () => containerEl.current?.scrollTo(0, 0);
  const goToNextStep = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    scrollToTop();
  };
  const goToPreviousStep = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    scrollToTop();
  };

  return (
    <Portal>
      <Modal open={open}>
        <Paper elevation={3} ref={containerEl} className={classes.root}>
          <Background />
          <Box className={classes.sliderContainer}>
            <Box mt={4}>
              <Typography variant="h1" className={classes.whiteText}>
                MLT Racial Equity at Work
              </Typography>
              <Typography variant="subtitle1" className={classes.whiteText}>
                A proven program for building more meritocratic workplaces.
              </Typography>
            </Box>
            <Card className={classes.sliderRoot}>
              <Slide {...activeSlide} />
              <Navigation
                {...{ activeStep, numberOfSteps }}
                onBack={goToPreviousStep}
                onNext={goToNextStep}
                onClose={onClose}
              />
            </Card>
          </Box>
          <AppFooter className={classes.slideFooter}/>
        </Paper>
      </Modal>
    </Portal>
  );
};

export default WelcomeSlider;
