import React, { useState } from 'react';
import { InputAdornment } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TextField from 'src/components/common/TextField';
import { Eye as EyeIcon, EyeOff as EyeOffIcon } from 'react-feather';

function PasswordTextField(props) {
  const [isRevealed, setIsRevealed] = useState(false);

  return (
    <TextField
      type={isRevealed ? 'text' : 'password'}
      { ...props }
      InputProps={{
        endAdornment: (
          <InputAdornment sx={{ marginRight: 1 }} position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setIsRevealed(!isRevealed)}
              edge="end">
              {isRevealed ? <EyeOffIcon /> : <EyeIcon />}
            </IconButton>
          </InputAdornment>
        )
      }}>
    </TextField>
  );
}

export default PasswordTextField;
