import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useStore from 'src/store';
import { selectIsAuthenticated } from 'src/store/auth/selectors';
import { ROUTES } from '.';

const AnonRoute = ({ children, ...props }) => {
  const isAuthenticated = useStore(selectIsAuthenticated);
  if (isAuthenticated) return <Redirect to={ROUTES.NEXT} />;
  return <Route {...props}>{children}</Route>;
};

export default AnonRoute;
