import React from 'react';
import { Chip } from '@mui/material';
import styled from '@emotion/styled';

const StyledChip = styled(Chip)`
  font-size: 12px;
`;

export default function CertificationEmployeeChip({ isPrimary }) {
  const color = isPrimary ? '#4CAF50' : '#263238';
  const backgroundColor = isPrimary ? '#E8F5E9' : '#ECEFF1';
  const label = isPrimary ? 'PRIMARY' : 'SECONDARY';

  return (
    <StyledChip size='small' label={label} sx={{ color, backgroundColor }} />
  );
}
