import { getPartners } from 'src/api/partners';

export default (set, get) => ({
  partners: [],

  async getPartners() {
    const data = await getPartners();

    set(state => ({ partners: data }), 'GET_PARTNERS');
  }
});
