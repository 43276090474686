import { useMemo } from 'react';
import { parse } from 'query-string';
import { useLocation } from 'react-router-dom';

function useQueryParams() {
  const location = useLocation();
  return useMemo(() => parse(location.search), [location.search]);
}

export default useQueryParams;
