import axios from 'axios';
import { API_URL } from '../constants';

export const getUsers = async params => {
  const url = `${API_URL}/users`;
  const { data } = await axios.get(url, { params });
  return data;
};
getUsers.QUERY_KEY = 'getUsers';

export const getProfile = async () => {
  const url = `${API_URL}/users/profile`;
  const { data } = await axios.get(url);
  return data;
};
getUsers.QUERY_KEY = 'getProfile';

export const getCertificationPsTeamMembers = async certificationId => {
  const url = `${API_URL}/users/ps-team-members/${certificationId}`;
  const { data } = await axios.get(url);
  return data;
};
getCertificationPsTeamMembers.QUERY_KEY = 'getCertificationPsTeamMembers';

export const updateUser = async payload => {
  const url = `${API_URL}/users`;
  const { data } = await axios.patch(url, payload);
  return data;
};

export const updateUserAssignment = (certificationId, payload) => {
  const url = `${API_URL}/users/assignments/${certificationId}`;
  return axios.patch(url, payload)
    .then(res => res.data);
};

export const sendCurrentUserToDestination = name => {
  const url = `${API_URL}/users/me/destination/${name}`;
  return axios.post(url);
};
