import React from 'react';
import { Box, Grid, styled } from '@mui/material';
import Typography from 'src/components/common/Typography';
import AuthorImage from 'src/assets/images/welcomeSlider/image-author.png';
import CheckList from './CheckList';

const Header = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  fontWeight: 'bold'
}));

const ImageAuthor = styled('img')(({ theme }) => ({
  maxWidth: 117,
  float: 'right',
  [theme.breakpoints.up('xs')]: {
    marginRight: 0
  },
  [theme.breakpoints.up('sm')]: {
    marginRight: theme.spacing(4)
  },
  [theme.breakpoints.up('md')]: {
    marginRight: theme.spacing(12)
  }
}));

const Image = styled('img')(({ theme }) => ({
  display: 'block',
  margin: 'auto',
  overflow: 'hidden',
  [theme.breakpoints.up('xs')]: {
    maxWidth: 250,
    maxHeight: 250
  },
  [theme.breakpoints.up('sm')]: {
    maxWidth: 350,
    maxHeight: 350
  }
}));

function Slide({ imgPath, label, details, checklist, footer }) {
  return (
    <Grid container>
      {imgPath && (
        <Grid item md={4}>
          <Box>
            <Image src={imgPath} alt={label} />
            <ImageAuthor src={AuthorImage} alt="illustration by Freepik Storyset" />
          </Box>
        </Grid>
      )}
      <Grid item md={imgPath ? 6 : 12}>
        <Box mb={2}>
          <Header variant="h3">{label}</Header>
          <Typography
            component="p"
            dangerouslySetInnerHTML={{
              __html: details
            }}
          />
          {checklist && <CheckList {...checklist} mt={3} />}
          {footer && (
            <Box display="flex" justifyContent="center">
              <Typography variant="h5" bold>{footer}</Typography>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default Slide;
