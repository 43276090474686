import React from 'react';
import Typography from 'src/components/common/Typography';

function TextDefinitions({ label, content }) {
  return (
    <Typography variant="subtitle2">
      <Typography component="span" bold>{label}:</Typography> {content}
    </Typography>
  );
}

export default TextDefinitions;
