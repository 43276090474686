/*
NOTE: The order of the routes is important, since we are iterating them when creating breadcrumbs
i.e. on /administration/users route, /:id/users will be matched as well, but we want the former to be matched first,
just like in the Route components
*/
export default {
  HOME: '/next',
  ADMINISTRATION_USERS: '/next/administration/users',
  CERTIFICATION: '/next/:certificationId',
  CERTIFICATION_USERS: '/next/:certificationId/users',
  CERTIFICATION_STEP: '/next/:certificationId/steps/:certificationStepId',
  CERTIFICATION_PILLAR: '/next/:certificationId/steps/:certificationStepId/pillars/:pillarId',
  CERTIFICATION_INDICATOR: '/next/:certificationId/steps/:certificationStepId/pillars/:pillarId/indicators/:indicatorId'
};
