import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { Search as SearchIcon, ChevronDown as ChevronDownIcon } from 'react-feather';
import { debounce } from 'lodash';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { getCertificationStatusByFrontendTerminology } from '../certification/certificationUtil';

const SEARCH_DELAY_MS = 400;
const SELECT_HEIGHT = 56;

const Header = styled(Paper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2)};
  box-shadow: ${({ theme }) => theme.shadows[2]};
`;

const FilterIcon = styled(props => (
  <Box {...props}>
    <ChevronDownIcon color='white' />
  </Box>
))`
  background: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${SELECT_HEIGHT}px;
  height: ${SELECT_HEIGHT}px;
  border-radius: inherit;
`;

const SelectedCount = ({ count }) => (
  <Box mr={4}>
    <Typography color={count ? 'primary.dark' : 'tertiary.main'}>
      {count ? `${count} selected` : 'Select items'}
    </Typography>
  </Box >
);

const StyledSelect = styled(props => <Select MenuProps={{ elevation: 2 }} {...props} />)`
  height: ${SELECT_HEIGHT}px;

  .MuiSelect-icon {
    top: 0;
    right: 0;
  }
`;

const FilterSelect = ({ title, options, ...props }) => {
  return (
    <Box
      display='block'
      mt={2}
    >
      <Typography
        variant='bodyBold'
        display='block'
        mb={1}
      >
        {title}
      </Typography>
      <StyledSelect
        fullWidth
        multiple
        displayEmpty
        renderValue={values => <SelectedCount count={values.length} />}
        IconComponent={FilterIcon}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          PaperProps: {
            style: {
              maxHeight: window.innerHeight ? window.innerHeight * 0.28 : '300px'
            }
          },
          getContentAnchorEl: null
        }}
        {...props}
      >
        {options.map(option => (
          <MenuItem
            key={option}
            value={option}
          >
            {title === 'Status' ? getCertificationStatusByFrontendTerminology(option) : option}
          </MenuItem>
        ))}
      </StyledSelect>
    </Box>
  );
};

export default function PartnerFilters({ partners, certifications, filters, search, onFiltersChange, onSearch, onClear, years }) {
  const [searchLocal, setSearchLocal] = useState('');
  const filterSelects = [
    {
      title: 'Industries',
      selector: 'industries',
      options: useMemo(() => {
        const industriesSet = new Set(partners.map(p => p.industry));
        const sortedIndustries = Array.from(industriesSet).sort();
        return sortedIndustries;
      }, [partners])
    },
    {
      title: 'Programs',
      selector: 'assessmentTypes',
      options: useMemo(() => new Set(certifications.map(c => c.assessmentType.title)), [certifications])
    },
    {
      title: 'Year',
      selector: 'certificationYears',
      options: years
    }
  ];

  const setFilters = useCallback(localFilters => {
    onFiltersChange({ ...filters, ...localFilters });
  }, [filters, onFiltersChange]);
  const searchDebounced = useCallback(debounce(onSearch, SEARCH_DELAY_MS), [onSearch]);

  const searchPartners = useCallback(({ target: { value } }) => {
    setSearchLocal(value);
    searchDebounced(value);
  }, [setSearchLocal, searchDebounced]);

  useEffect(() => {
    setSearchLocal(search);
  }, [search]);

  return (
    <Card>
      <Header>
        <Typography variant='modalHeadline'>
          Filter Partners
        </Typography>
        <Button
          size='small'
          variant='unmarked'
          onClick={onClear}
        >
          Clear Filters
        </Button>
      </Header>
      <CardContent>
        <TextField
          value={searchLocal}
          onChange={searchPartners}
          placeholder="Search Partners"
          type="search"
          fullWidth
          sx={{ mt: 1 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
        <Box display='block' mt={2}>
          <Typography variant='bodyBold' display='block' mb={1}>
            Certified this year only
          </Typography>
          <Switch
            checked={filters.isCertifiedInCurrentYear}
            onChange={event => setFilters({ isCertifiedInCurrentYear: event.target.checked })} />
        </Box>
        {filterSelects.map(f => (
          <FilterSelect
            key={f.selector}
            title={f.title}
            options={[...f.options]}
            value={filters[f.selector] ?? []}
            onChange={event => setFilters({ [f.selector]: event.target.value })}
          />
        ))}
      </CardContent>
    </Card>
  );
}
