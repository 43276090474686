import styled from '@emotion/styled';
import {
  Avatar,
  Box,
  Card,
  CircularProgress,
  Grid,
  Link,
  Typography
} from '@mui/material';
import React from 'react';
import { AlertCircle as AlertCircleIcon } from 'react-feather';
import { generatePath } from 'react-router-dom';
import { CERTIFICATION_STEP_LABEL_BY_TYPE, getPillarMaxScore } from './certificationUtil';
import CertificationStepSummaryIndicatorList from './CertificationStepSummaryIndicatorList';
import CertificationStepSummaryStatus from './CertificationStepSummaryStatus';
import PillarOverviewIcon from '../assessment/pillar_overview.png';
import routes from '../navigation/routes';

const PILLAR_SCORE_SIZE = 108;
const PILLAR_SCORE_BOTTOM_OFFSET = PILLAR_SCORE_SIZE / 2;
const PILLAR_SCORE_THICKNESS = 16;
const PILLAR_SCORE_LABEL_OFFSET = PILLAR_SCORE_THICKNESS / 2;
const PILLAR_SCORE_RIGHT_OFFSET = 40;
const PILLAR_SCORE_CONTAINER_WIDTH = PILLAR_SCORE_SIZE + PILLAR_SCORE_RIGHT_OFFSET;

const StyledAlertCircleIcon = styled(AlertCircleIcon)`
  min-width: 24px;
  color: #FFF;
  fill: ${({ theme }) => theme.palette.error.main};
`;

const StyledAvatar = styled(Avatar)`
  width: 48px;
  height: 48px;
  margin-right: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) => theme.palette.primary.dark};
  border: 2px solid;
`;

const Header = styled(Box)`
  min-height: 80px;
  padding: ${({ theme }) => theme.spacing(1, 3)};
  background-color: ${({ theme }) => theme.palette.primary.dark};
  color: white;
  display: flex;
  align-items: center;
  box-shadow: ${({ theme }) => theme.shadows[3]};
`;

const PillarScoreLabel = styled(({ currentValue, maxValue, ...props }) => {
  return (
    <Box {...props}>
      <Typography variant="bodyBold" mb={0.5}>Score</Typography>
      <Typography variant="h5">{currentValue}/{maxValue}</Typography>
    </Box>
  );
})`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: ${PILLAR_SCORE_LABEL_OFFSET}px;
  left: ${PILLAR_SCORE_LABEL_OFFSET}px;
  width: calc(100% - ${PILLAR_SCORE_THICKNESS}px);
  height: calc(100% - ${PILLAR_SCORE_THICKNESS}px);
  background-color: ${({ theme }) => theme.palette.background.default};
  color: ${({ theme }) => theme.palette.rew.main};
  border-radius: 50%;
`;

const PillarScore = styled(({ currentValue = 0, maxValue, ...props }) => {
  const percentage = (currentValue / maxValue) * 100;

  return (
    <Box {...props}>
      <CircularProgress
        variant="determinate"
        color="rew"
        value={percentage}
        size={PILLAR_SCORE_SIZE}
        thickness={PILLAR_SCORE_THICKNESS}
      />
      <PillarScoreLabel
        currentValue={currentValue}
        maxValue={maxValue}
      />
    </Box>
  );
})`
  position: absolute;
  bottom: -${PILLAR_SCORE_BOTTOM_OFFSET}px;
  right: ${PILLAR_SCORE_RIGHT_OFFSET}px;
  width: ${PILLAR_SCORE_SIZE}px;
  height: ${PILLAR_SCORE_SIZE}px;
  background: ${({ theme }) => theme.palette.gradient.disabled};
  border-radius: 50%;
  box-shadow: ${({ theme }) => theme.shadows[3]};
`;

function Pillar({
  pillar,
  scores,
  completion,
  indicatorIndexById,
  certificationStep,
  userRole,
  avgReviewScoreByIndicatorId,
  skippedIndicatorIds
}) {
  const maxScore = getPillarMaxScore(pillar);

  return (
    <Card>
      <Header position='relative'>
        <StyledAvatar src={PillarOverviewIcon} />
        <Link to={generatePath(routes.CERTIFICATION_PILLAR, {
          certificationId: certificationStep.certification.id,
          certificationStepId: certificationStep.id,
          pillarId: pillar.id
        })}>
          <Typography variant="h4" component="span" color="white">
            {pillar.name}
          </Typography>
        </Link>
        {
          pillar.isAssessed &&
          <PillarScore
            currentValue={scores.pillarScore[pillar.id]}
            maxValue={maxScore}
          />
        }
      </Header>
      <CertificationStepSummaryIndicatorList
        scoreById={scores.indicatorScore}
        completionById={completion.indicatorCompletion}
        indicators={pillar.indicators}
        globalIndexById={indicatorIndexById}
        certificationStep={certificationStep}
        showCompletionAlerts={completion.completed > 0}
        userRole={userRole}
        pillarScoreContainerWidth={PILLAR_SCORE_CONTAINER_WIDTH}
        avgReviewScoreByIndicatorId={avgReviewScoreByIndicatorId}
        skippedIndicatorIds={skippedIndicatorIds}
        showScoreColumn={pillar.isAssessed}
      />
    </Card>
  );
}

export default function CertificationStepSummary({
  certification,
  certificationStep,
  pillars = [],
  isCurrent,
  isDraft,
  isReview,
  isApproved,
  onSubmit,
  onApprove,
  onReject,
  isSubmitting,
  isApproving,
  isRejecting,
  userRole
}) {
  const { score, completion, type } = certificationStep;
  const allIndicators = pillars.flatMap(p => p.indicators);
  const indicatorIndexById = allIndicators.reduce((acc, i, index) => acc.set(i.id, index), new Map());
  const skippedIndicators = (certificationStep.markedIndicators ?? []).filter(({ skipReason }) => !!skipReason);
  const skippedIndicatorIds = new Set(skippedIndicators.map(({ indicator: { id } }) => id));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} mt={1} mb={3}>
        <Typography variant='h1'>
          {CERTIFICATION_STEP_LABEL_BY_TYPE[type]} Summary
        </Typography>
        {
          certification?.partner?.name &&
            <Typography mt={-1} variant="h3" color="tertiary.dark">
              {certification.partner.name}
            </Typography>
        }
      </Grid>
      <Grid
        item
        container
        xs={8}
        spacing={2}
        direction='column'
      >
        {pillars.map(p => (
          <Grid
            key={p.id}
            item
          >
            <Pillar
              pillar={p}
              completion={completion}
              scores={score}
              indicatorIndexById={indicatorIndexById}
              certificationStep={certificationStep}
              userRole={userRole}
              skippedIndicatorIds={skippedIndicatorIds}
            />
          </Grid>
        ))}
      </Grid >
      <Grid container item xs={4} spacing={2} direction='column'>
        <Grid item>
          <CertificationStepSummaryStatus
            certificationStep={certificationStep}
            pillars={pillars}
            completion={completion}
            scores={score}
            status={certification.status}
            isCurrent={isCurrent}
            isDraft={isDraft}
            isReview={isReview}
            isApproved={isApproved}
            onSubmit={onSubmit}
            onApprove={onApprove}
            onReject={onReject}
            isSubmitting={isSubmitting}
            isApproving={isApproving}
            isRejecting={isRejecting}
            userRole={userRole}
            Header={Header}
            IncompleteIndicatorIcon={StyledAlertCircleIcon}
          />
        </Grid>
      </Grid>
    </Grid >
  );
}
