import React, { useState } from 'react';
import { Switch } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Divider
} from '@mui/material';
import AuthLayout from 'src/layouts/AuthLayout';
import AnonRoute from 'src/router/AnonRoute';
import { ROUTES } from 'src/router';
import SendCodeView from './SendCodeView';
import ResetPasswordView from './ResetPasswordView';
import Footer from './Footer';
import SuccessView from './SuccessView';

const FORGOT_PASSWORD_RESET_ROUTE = `${ROUTES.FORGOT_PASSWORD}/reset`;
const FORGOT_PASSWORD_SUCCESS_ROUTE = `${ROUTES.FORGOT_PASSWORD}/success`;

const ForgetPassword = () => {
  const [isLoading, setLoading] = useState(false);

  return (
    <AuthLayout title="Forgot Password" isLoading={isLoading}>
      <Card>
        <CardContent sx={{ paddingTop: 4 }}>
          <Box mt={1} mb={2} alignItems="center">
            <Typography
              style={{ fontWeight: 'bold', fontSize: 24 }}
              color="textPrimary"
              gutterBottom
              variant="subtitle1">
              <Switch>
                <AnonRoute exact path={FORGOT_PASSWORD_SUCCESS_ROUTE}>
                  Password Changed
                </AnonRoute>
                <AnonRoute>
                  Forgot Password
                </AnonRoute>
              </Switch>
            </Typography>
            <Switch>
              <AnonRoute exact path={ROUTES.FORGOT_PASSWORD}>
                <Typography color="textPrimary" gutterBottom variant="subtitle1">
                  Enter the email address you use to log in, and we’ll send you a code to
                  reset your password.
                </Typography>
              </AnonRoute>
              <AnonRoute exact path={FORGOT_PASSWORD_RESET_ROUTE}>
                <Typography color="textPrimary" variant="subtitle1">
                  We have sent a 6-digit verification code to your email, you should
                  receive the message in your inbox shortly.
                </Typography>
              </AnonRoute>
              <AnonRoute exact path={FORGOT_PASSWORD_SUCCESS_ROUTE}>
                <Typography color="textPrimary" gutterBottom variant="subtitle1">
                  You may now log in to the system using your new password
                </Typography>
              </AnonRoute>
            </Switch>
          </Box>
          <Divider />
          <Box flexGrow={1} mt={2}>
            <Switch>
              <AnonRoute
                path={FORGOT_PASSWORD_SUCCESS_ROUTE}
                component={SuccessView}
              />
              <AnonRoute path={FORGOT_PASSWORD_RESET_ROUTE}>
                <ResetPasswordView
                  setLoading={setLoading}
                  isLoading={isLoading}
                />
              </AnonRoute>
              <AnonRoute>
                <SendCodeView setLoading={setLoading} isLoading={isLoading} />
              </AnonRoute>
            </Switch>
          </Box>
          <AnonRoute exact path={`(${ROUTES.FORGOT_PASSWORD}|${FORGOT_PASSWORD_RESET_ROUTE})`}>
            <Box my={3}>
              <Divider />
            </Box>
            <Footer />
          </AnonRoute>
        </CardContent>
      </Card>
    </AuthLayout >
  );
};

export default ForgetPassword;
