import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Button,
  Collapse as MuiCollapse,
  ListItem as MuiListItem,
  List as MuiList,
  styled
} from '@mui/material';
import clsx from 'clsx';
import useNavItemStyles from './useNavItemStyles';

const ListItem = styled(MuiListItem)(() => ({
  display: 'flex',
  flexFlow: 'column',
  paddingTop: 0,
  paddingBottom: 0,
  alignItems: 'flex-start',
  position: 'relative'
}));
const List = styled(MuiList)(() => ({ width: '100%' }));
const Collapse = styled(MuiCollapse)(() => ({ width: '100%' }));

const LinkSection = ({
  title,
  status,
  children,
  to,
  className,
  isOpen,
  ...props
}) => {
  const classes = useNavItemStyles({ active: isOpen });

  return (
    <ListItem
      {...props}
      disableGutters
      className={classes.item}
    >
      <Button
        to={to}
        component={NavLink}
        className={clsx(classes.button, className)}
      >
        {title}
      </Button>
      <Collapse in={isOpen} timeout={0}>
        <List disablePadding>{isOpen ? children : null}</List>
      </Collapse>
    </ListItem>
  );
};

export default LinkSection;
