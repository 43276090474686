import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Container } from '@mui/material';
import { ROUTES } from 'src/router';

const BaselineOverview = React.lazy(() => import('./Overview'));
const BaselineForm = React.lazy(() => import('./Form'));

function Baseline() {
  return (
    <Container maxWidth="xl">
      <Switch>
        <Route path={ROUTES.CERTIFICATION_BASELINE_PILLAR} component={BaselineForm} />
        <Route component={BaselineOverview} />
      </Switch>
    </Container>
  );
}

export default Baseline;
