import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  AppBar,
  Badge,
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Link,
  MenuItem,
  MenuList,
  Toolbar,
  Dialog
} from '@mui/material';
import { Bell as BellIcon, Calendar as CalendarIcon, User as UserIcon } from 'react-feather';
import { ReactComponent as HomeIcon } from 'src/assets/next/home-icon.svg';
import { ASSIGNMENT_TYPES } from 'src/constants';
import routes from 'src/next/navigation/routes';
import useCertificationId from 'src/next/certification/useCertificationId';
import usePopperState from 'src/next/util/usePopperState';
import AppointmentList from '../appointment/AppointmentList';
import CommentNotificationList from '../notification/CommentNotificationList';
import Popper from '../mui/Popper';
import SingleSideSkew from '../mui/SingleSideSkew';
import useDashboardRoute from '../navigation/useDashboardRoute';
import useStore from '../store';
import useDialogState from '../util/useDialogState';
import useSnackbar from '../util/useSnackbar';
import Snackbar from '../mui/Snackbar';
import ChangePassword from '../user/ChangePassword';

const SytledToolbar = styled(Toolbar)`
  &.MuiToolbar-root {
    height: 70px;
    padding: 0;
  }
`;

const FullHeightGrid = styled(Grid)`
  height: 100%;
`;

const StyledSingleSideSkew = styled(SingleSideSkew)`
  background-color: ${({ theme }) => theme.palette.primary.dark};
`;

const LogoLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: ${({ theme }) => theme.spacing(3)};
  line-height: 0;
`;

const AccountLink = styled(Link)`
  text-decoration: underline;
`;

const StyledUserIcon = styled(UserIcon)`
  fill: currentColor;
  stroke-linecap: butt;
`;

const StyledIconButton = styled(IconButton, { shouldForwardProp: prop => prop !== 'focused' })`
  &:hover {
    background-color: rgba(255, 255, 255, 0.04);
  }

  ${props => (
    // NOTE(marko): Ideally this would've been handled by the underlying IconButton and Ripple
    // components, but alas, it's not. The issue here is that the button loses focus as the menu
    // opens, which is fine as far as the functionality goes, but the ripple should keep the styling
    // until the menu is closed and the button is properly blured.
    props.focused
      ? `
        background-color: rgba(255, 255, 255, 0.12) !important;
        transition: background-color 550ms linear;
      `
      : ''
  )}
`;

export function Layout({ children }) {
  const dashboardRoute = useDashboardRoute();

  return (
    <AppBar position="sticky" elevation={3}>
      <SytledToolbar>
        <FullHeightGrid container justifyContent="space-between" alignItems="center">
          <FullHeightGrid item container alignItems="center" xs="auto">
            <StyledSingleSideSkew width="164px">
              <LogoLink to={dashboardRoute}>
                <HomeIcon width={28} height={28} />
              </LogoLink>
            </StyledSingleSideSkew>
          </FullHeightGrid>
          <FullHeightGrid
            item
            xs={true}
            container
            alignItems="center"
            justifyContent="center"
          >
            <img alt="Logo" src="/static/next/rew-reverse-logo.svg" width={96} height={48} />
          </FullHeightGrid>
          <FullHeightGrid item xs="auto">
            <StyledSingleSideSkew origin="top right" width="184px" pr={3}>
              {children}
            </StyledSingleSideSkew>
          </FullHeightGrid>
        </FullHeightGrid>
      </SytledToolbar>
    </AppBar>
  );
}

export default function BewAppBar() {
  const history = useHistory();
  const certificationId = useCertificationId();
  const { appointments, getAppointments, resetAppointments } = useStore(state => state.appointment);
  const { currentDate } = useStore(state => state.settings);
  const {
    seenNotifications,
    unseenNotifications,
    getNotifications,
    markNotificationSeen,
    resetNotifications
  } = useStore(state => state.notification);
  const { psTeamMembers, getPsTeamMembers, resetPsTeamMembers } = useStore(state => state.user);
  const { logout } = useStore(state => state.auth);

  const [isChangePassword, openChangePassword, closeChangePassword] = useDialogState();
  const [snackbarData, openSnackbar] = useSnackbar();
  const [
    appointmentsAnchorEl,
    isAppointmentsPopoverOpen,
    onAppointmentsPopoverOpen,
    onAppointmentsPopoverClose
  ] = usePopperState();
  const [profileAnchorEl, isProfileMenuOpen, onProfileMenuOpen, onProfileMenuClose] = usePopperState();
  const [
    notificationsAnchorEl,
    isNotificationsPopoverOpen,
    onNotificationsPopoverOpen,
    onNotificationsPopoverClose
  ] = usePopperState();

  const onNotificationClick = useCallback(notificationId => {
    onNotificationsPopoverClose();
    markNotificationSeen(notificationId, currentDate);
  }, []);
  const onLogoutClick = useCallback(() => {
    logout();
    history.push(routes.LOGIN);
  }, [logout]);

  useEffect(() => {
    if (certificationId) {
      getAppointments(certificationId);
      getNotifications(certificationId);
      getPsTeamMembers(certificationId);
    }

    return () => {
      resetAppointments();
      resetNotifications();
      resetPsTeamMembers();
    };
  }, [
    certificationId,
    getAppointments,
    getNotifications,
    getPsTeamMembers,
    resetAppointments,
    resetNotifications,
    resetPsTeamMembers
  ]);

  const isPsm = user => user.certificationAssignments.some(ca => ca.type === ASSIGNMENT_TYPES.PSM);
  const psm = psTeamMembers.find(isPsm);

  return (
    <>
      <Layout>
        <Dialog
          open={isChangePassword}
          fullWidth
        >
          <ChangePassword
            closeChangePassword={closeChangePassword}
            openSuccessSnackbar={() => openSnackbar('Password saved!', 'success')}
          />
        </Dialog>
        <Box display="flex" flexGrow={1} justifyContent="space-between" alignItems="center">
          <StyledIconButton
            color="inherit"
            size="large"
            focused={isNotificationsPopoverOpen}
            onClick={onNotificationsPopoverOpen}
          >
            <Badge badgeContent={unseenNotifications?.length ?? 0} color="error">
              <BellIcon />
            </Badge>
          </StyledIconButton>
          <StyledIconButton
            color="inherit"
            size="large"
            focused={isAppointmentsPopoverOpen}
            onClick={onAppointmentsPopoverOpen}
          >
            <Badge badgeContent={appointments?.length ?? 0} color="error">
              <CalendarIcon fill="currentColor" />
            </Badge>
          </StyledIconButton>
          <StyledIconButton
            color="inherit"
            size="large"
            focused={isProfileMenuOpen}
            onClick={onProfileMenuOpen}
          >
            <StyledUserIcon />
          </StyledIconButton>
        </Box>
      </Layout>

      <Popper
        open={isNotificationsPopoverOpen}
        anchorEl={notificationsAnchorEl}
        placement="bottom-end"
        onClose={onNotificationsPopoverClose}
      >
        <CommentNotificationList
          seenNotifications={seenNotifications}
          unseenNotifications={unseenNotifications}
          onNotificationClick={onNotificationClick}
          elevation={0}
        />
      </Popper>

      <Popper
        open={isAppointmentsPopoverOpen}
        anchorEl={appointmentsAnchorEl}
        placement="bottom-end"
        onClose={onAppointmentsPopoverClose}
      >
        <AppointmentList
          appointments={appointments}
          calendlyUrl={psm?.calendlyUrl}
          onRequestAppointment={onAppointmentsPopoverClose}
          elevation={0}
        />
      </Popper>

      <Popper
        open={isProfileMenuOpen}
        anchorEl={profileAnchorEl}
        placement="bottom-end"
        onClose={onProfileMenuClose}
      >
        <Card elevation={0} sx={{ width: 240 }}>
          <CardHeader
            title="Account"
            titleTypographyProps={{ variant: 'subtitle2' }}
          />
          <Divider />
          <MenuList autoFocusItem={isProfileMenuOpen}>
            <MenuItem onClick={onProfileMenuClose}>
              <AccountLink onClick={openChangePassword} variant="link2">
                Change Password
              </AccountLink>
            </MenuItem>
            <MenuItem onClick={onLogoutClick}>
              <AccountLink variant="link2">
                Log Out
              </AccountLink>
            </MenuItem>
          </MenuList>
        </Card>
      </Popper>
      <Snackbar {...snackbarData} autoHideDuration={3000} />
    </>
  );
}
