import { styled } from '@mui/material';

const Title = styled('span')(({ theme }) => ({
  marginRight: 'auto',
  padding: theme.spacing(1.5, 1, 1.5, 4),
  fontFamily: theme.typography.fontFamily,
  fontWeight: 600,
  textAlign: 'left',
  fontSize: '12px',
  color: theme.palette.text.common,
  textTransform: 'uppercase'
}));

export default Title;
