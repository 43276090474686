import { useMemo } from 'react';
import useCertificationId from 'src/next/certification/useCertificationId';
import useStore from 'src/next/store';

function useAssessmentType() {
  const { certifications } = useStore(state => state.certification);
  const certificationId = useCertificationId();

  return useMemo(() => {
    const certification = certifications.find(c => c.id === certificationId);

    return certification?.assessmentType?.title;
  }, [certificationId, certifications]);
}

export default useAssessmentType;
