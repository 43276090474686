import React from 'react';
import clsx from 'clsx';
import { Button, ListItem } from '@mui/material';
import { CertificationStatusColor } from 'src/models/certification';
import useNavItemStyles from './useNavItemStyles';

function NavButton({
  children,
  onClick,
  active,
  status,
  Icon,
  disabled,
  className,
  ...props
}) {
  const classes = useNavItemStyles({ active });
  const color = CertificationStatusColor[status];

  return (
    <ListItem
      {...{ ...props, color }}
      disableGutters
      className={classes.listItem}>
      <Button
        component="button"
        onClick={onClick}
        disabled={disabled}
        className={clsx(classes.button, className)}
      >
        {<Icon className={classes.icon} />}
        {children}
      </Button>
    </ListItem>
  );
}

export default NavButton;
