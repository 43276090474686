import { isEmpty, set } from 'lodash';
import { useState } from 'react';

const VALIDATION_OPTIONS = Object.freeze({
  abortEarly: false
});

class FormError extends Error {
  constructor(fields, message) {
    super(message ?? 'Form validation failed');
    this.fields = fields;
  }
}

function mapYupToFormError(yupError) {
  const fields = {};

  if (!isEmpty(yupError.inner)) {
    yupError.inner.filter(err => err.path).forEach(err => {
      set(fields, err.path, !isEmpty(err.inner) ? mapYupToFormError(err) : err.message);
    });
  } else if (yupError.path) {
    fields[yupError.path] = yupError.message;
  }

  return new FormError(fields, isEmpty(fields) ? yupError.message : null);
}

function useValidation(initialValue, validationSchema, onChange, processInput) {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(null);
  const handleChange = async input => {
    const processed = processInput(input);

    setValue(processed);

    try {
      const validated = await validationSchema.validate(
        processed,
        // `validate` mutates the options, so fresh copy is needed on each call
        { ...VALIDATION_OPTIONS }
      );

      setError(null);
      onChange(validated);
    } catch (err) {
      setError(mapYupToFormError(err));
    }
  };

  return [value, error, handleChange];
}

export default useValidation;
