import makeStyles from '@mui/styles/makeStyles';

export const useErrorOutlineStyles = makeStyles(() => ({
  textFieldRoot: {
    '&$error $notchedOutline': {
      borderColor: '#F32C02'
    },
    '&$clicked $notchedOutline': {
      borderColor: '#074E7D'
    },
    '&$clicked $label': {
      color: '#074E7D'
    },
    fontSize: 16,
    borderRadius: 8
  },
  error: {},
  disabled: {},
  notchedOutline: {},
  filled: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#4CAF50'
    }
  },
  clicked: {},
  label: {
    '&$focusedLabel': {
      color: '#074E7D'
    }
  },
  focusedLabel: {}
}));
