import React from 'react';
import ErrorPageLayout from './ErrorPageLayout';

function GenericErrorPage({ resetErrorBoundary }) {
  return (
    <ErrorPageLayout
      heading="Oops, something went wrong!"
      description="We can’t seem to find what you were looking for."
      onReset={resetErrorBoundary}
    />
  );
}

export default GenericErrorPage;
