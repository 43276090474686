import React, { useState } from 'react';
import * as auth from 'src/utils/auth';
import { Box, Button } from '@mui/material';
import { useHistory } from 'react-router';
import { ROUTES } from 'src/router';
import Input from './Input';
import Error from './Error';

const SendCodeView = ({ isLoading, setLoading }) => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const sendCode = () => {
    setLoading(true);
    setError('');
    return auth.sendCode(email)
      .then(() => {
        setLoading(false);
        const route = {
          pathname: `${ROUTES.FORGOT_PASSWORD}/reset`,
          search: `email=${encodeURIComponent(email)}`
        };
        history.push(route);
      })
      .catch(err => {
        setLoading(false);
        setError(err.message);
      });
  };
  return (
    <Box display="block" justifyContent="center">
      <Input
        label="Email Address"
        error={!!error}
        value={email}
        onChange={event => setEmail(event.target.value)} />
      {error && <Error my={3}>{error}</Error>}
      <Box mt={5}>
        <Button
          onClick={sendCode}
          disabled={isLoading || email.length < 1}
          variant="contained"
          color="primary"
          size="large">
          Send Code
        </Button>
      </Box>
    </Box>
  );
};

export default SendCodeView;
