import { useCallback, useState } from 'react';

export default function useDialogState(beforeOpen = () => { }, afterClose = () => { }) {
  const [isOpen, setOpen] = useState(false);
  const openDialog = useCallback(context => {
    beforeOpen(context);
    setOpen(true);
  }, [beforeOpen]);
  const closeDialog = useCallback(context => {
    setOpen(false);
    afterClose(context);
  }, [afterClose]);

  return [isOpen, openDialog, closeDialog];
}
