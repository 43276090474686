import React, { useEffect, useState } from 'react';
import { Box, Dialog, Typography } from '@mui/material';
import useStore from 'src/next/store';
import _, { curry } from 'lodash';
import { ROLES } from 'src/constants';
import Snackbar from 'src/next/mui/Snackbar';
import ConfirmationDialog from 'src/next/mui/ConfirmationDialog';
import UserList from './UserList';
import SearchableUserList from './SearchableUserList';
import UserEdit from './UserEdit';
import { api } from './userStore';
import useSnackbar from '../util/useSnackbar';
import wrapPromise from '../util/wrapPromise';
import useDialogState from '../util/useDialogState';
import useLoading from '../util/useLoading';

const getCertificationId = user => user?.certificationAssignments?.[0]?.certification?.id;

export default function UserMain() {
  const { user: loggedInUser } = useStore(state => state.auth);
  const {
    users,
    getUsers,
    addAdmin,
    updateAdmin,
    updateLoggedInUser,
    enable,
    disable,
    updateEmployee,
    promoteEmployee
  } = useStore(state => state.user);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserCertificationId, setCurrentUserCertificationId] = useState(null);
  const [snackbarData, openSnackbar] = useSnackbar();
  const [isCreateAdminOpen, openCreateAdmin, closeCreateAdmin] = useDialogState(setCurrentUser);
  const [isEditAdminOpen, openEditAdmin, closeEditAdmin] = useDialogState(setCurrentUser);
  const [isEditOpen, openEdit, closeEdit] = useDialogState(setCurrentUser);
  const [isPromoteOpen, openPromote, closePromote] = useDialogState(setCurrentUser);
  const [isDisableOpen, openDisable, closeDisable] = useDialogState(setCurrentUser);
  const [isEnableOpen, openEnable, closeEnable] = useDialogState(setCurrentUser);
  const [isPasswordResetOpen, openPasswordReset, closePasswordReset] = useDialogState(setCurrentUser);

  const openSuccessSnackbar = () => openSnackbar('Changes Saved', 'success');
  const openErrorSnackbar = () => openSnackbar('An error occured', 'error');
  const withSnackbar = curry(wrapPromise)(_, openSuccessSnackbar, openErrorSnackbar);

  const [createAdmin, isCreateAdminLoading] = useLoading(withSnackbar(async data => {
    await addAdmin(data);
  }, closeCreateAdmin));
  const [editAdmin, isEditAdminLoading] = useLoading(withSnackbar(async data => {
    await updateAdmin(currentUser.id, data);
  }, closeEditAdmin));
  const [editUser, isEditLoading] = useLoading(withSnackbar(data => (
    loggedInUser.id === currentUser.id
      ? updateLoggedInUser(data)
      : updateEmployee(currentUserCertificationId, currentUser.id, data)
  ), closeEdit));
  const [promoteUser, isPromoteLoading] = useLoading(withSnackbar(async () => {
    await promoteEmployee(currentUserCertificationId, currentUser.id);
  }, closePromote));
  const [enableUser, isEnableLoading] = useLoading(withSnackbar(async () => {
    await enable(currentUser.id);
  }, closeEnable));
  const [disableUser, isDisableLoading] = useLoading(withSnackbar(async () => {
    await disable(currentUser.id);
  }, closeDisable));
  const [resetPassword, isResetPasswordLoading] = useLoading(withSnackbar(async () => {
    await api.resetPassword(currentUser.username);
  }, closePasswordReset));
  const openEnabledToggle = user => user.enabled ? openDisable(user) : openEnable(user);

  useEffect(() => {
    getUsers();
  }, [getUsers, isPromoteLoading]);

  const admins = users.filter(u => u.role === ROLES.ADMIN);
  const employees = users.filter(u => u.role === ROLES.EMPLOYEE);
  const currentFullName = `${currentUser?.first} ${currentUser?.last}`;
  const currentPrimary = employees.find(e => getCertificationId(e) === currentUserCertificationId && e.isPrimary);
  const currentPrimaryFullName = `${currentPrimary?.first} ${currentPrimary?.last}`;

  return (
    <>
      <Typography
        variant='h1'
        mb={2}
      >
        Edit Users
      </Typography>

      {
        loggedInUser?.role === ROLES.ADMIN &&
        <Box mb={4}>
          <UserList
            title='Administrators'
            addButtonTitle='Add Admin'
            users={admins}
            currentUser={loggedInUser}
            isPromoteHidden
            short
            onCreate={openCreateAdmin}
            onEdit={openEditAdmin}
            onToggleEnabled={openEnabledToggle}
          />
        </Box>
      }
      <SearchableUserList
        users={employees}
        currentUser={loggedInUser}
        onEdit={openEdit}
        onPromote={openPromote}
        onPasswordReset={openPasswordReset}
        onToggleEnabled={openEnabledToggle}
        setCurrentUserCertificationId={setCurrentUserCertificationId}
        isAdmin={loggedInUser?.role === ROLES.ADMIN}
      />

      <Dialog
        open={isCreateAdminOpen}
        onClose={closeCreateAdmin}
        maxWidth='lg'
        fullWidth
      >
        <UserEdit
          title='Add Administrator User'
          note='ALL FIELDS REQUIRED'
          isLoading={isCreateAdminLoading}
          onSave={createAdmin}
          onClose={closeCreateAdmin}
        />
      </Dialog>

      <Dialog
        open={isEditAdminOpen}
        onClose={closeEditAdmin}
        maxWidth='lg'
        fullWidth
      >
        <UserEdit
          user={currentUser}
          title='Edit Administrator User'
          isLoading={isEditAdminLoading}
          onSave={editAdmin}
          onClose={closeEditAdmin}
        />
      </Dialog>

      <Dialog
        open={isEditOpen}
        onClose={closeEdit}
        maxWidth='lg'
        fullWidth
      >
        <UserEdit
          user={currentUser}
          title='Edit User'
          note={loggedInUser?.role !== ROLES.STAFF ? 'NOTE: CONTACT YOUR PSM TO MAKE CHANGES TO NAME FIELDS' : undefined}
          isLoading={isEditLoading}
          onSave={editUser}
          onClose={closeEdit}
        />
      </Dialog>

      <ConfirmationDialog
        open={isPromoteOpen}
        onClose={closePromote}
        onConfirm={promoteUser}
        title={`Are you sure you want to promote ${currentFullName}?`}
        loading={isPromoteLoading}
      >
        <Typography>
          By confirming you will promote {currentFullName} (Secondary User) to become the new Primary User.
          This will also change the status of {currentPrimaryFullName} (Primary User) to the role of Secondary user.
        </Typography>
      </ConfirmationDialog>

      <ConfirmationDialog
        open={isEnableOpen}
        onClose={closeEnable}
        onConfirm={enableUser}
        title={`Are you sure you want to activate ${currentUser?.role} ${currentFullName}?`}
        loading={isEnableLoading}
      >
        <Typography>
          This will allow {currentFullName} to access the platform and all the information their organization has entered.
        </Typography>
      </ConfirmationDialog>

      <ConfirmationDialog
        open={isDisableOpen}
        onClose={closeDisable}
        onConfirm={disableUser}
        title={`Are you sure you want to deactivate ${currentUser?.role} ${currentFullName}?`}
        loading={isDisableLoading}
      >
        <Typography>
          This will prevent {currentFullName} from logging into the platform until they are restored to &quot;Active&quot;.
        </Typography>
      </ConfirmationDialog>

      <ConfirmationDialog
        open={isPasswordResetOpen}
        onClose={closePasswordReset}
        onConfirm={resetPassword}
        title={`Are you sure you want to reset/resend password for ${currentUser?.role} ${currentFullName}?`}
        loading={isResetPasswordLoading}
      >
        <Typography>
          If user has used their account, this action will start their reset password flow and send verification code via email.
          If user has not used their account, this action will send new temporary password via email.
        </Typography>
      </ConfirmationDialog>

      <Snackbar
        {...snackbarData}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        style={{ top: 125 }}
      />
    </>
  );
}
