import React from 'react';
import { FeatureToggles } from '@paralleldrive/react-feature-toggles';
import features from './features';

const togglePrefix = 'REACT_APP_FEATURE_';
const activeFeatures = Object.keys(features)
  .filter(name => process.env[`${togglePrefix}${name}`] === 'true');

export default function FeatureToggleProvider({ children }) {
  return (
    <FeatureToggles features={activeFeatures}>
      {children}
    </FeatureToggles>
  );
}
