import React from 'react';

function withFormik(Component) {
  return ({ formik, ...props }) => {
    const { touched, error } = formik ? formik.getFieldMeta(props.name) : {};
    const fieldProps = formik ? formik.getFieldProps(props.name) : {};

    return (
      <Component
        {...fieldProps}
        error={touched && !!error}
        helperText={touched && error}
        {...props}
      />
    );
  };
}

export default withFormik;
