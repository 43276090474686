import { createTheme } from '@mui/material/styles';
import Link from './Link';

const palette = {
  primary: {
    main: '#074E7D',
    light: '#8DEDFF',
    dark: '#04273F',
    contrastText: '#FFF'
  },
  secondary: {
    main: '#00B553',
    light: '#DEF59E',
    dark: '#144026',
    contrastText: '#FFF'
  },
  tertiary: {
    main: '#A4A4A4',
    light: '#CCCCCC',
    lighter: '#E0E0E0',
    lightest: '#F0F0F0',
    dark: '#757575', // Also used in place of #848484, since designs are inconsistent
    darker: '#575757',
    contrastText: '#FFF'
  },
  error: {
    main: '#FF332F'
  },
  text: {
    primary: '#000'
  },
  background: {
    default: '#F7F8FC'
  },
  gradient: {
    disabled: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #CCCCCC'
  },
  breadcrumbs: '#FFFFFF',
  divider: '#E0E0E0'
};

export default createTheme({
  palette: {
    ...palette,
    rew: palette.primary,
    success: palette.secondary
  },
  shadows: {
    0: 'none',
    1: '2px 2px 10px 0 rgba(0,0,0,0.08)',
    2: '2px 2px 10px 0 rgba(0,0,0,0.12)',
    3: '2px 2px 10px 0 rgba(0,0,0,0.16)',
    4: '2px 2px 10px 0 rgba(0,0,0,0.2)',
    5: '2px 2px 10px 0 rgba(0,0,0,0.24)',
    6: '-8px 2px 10px -10px rgba(0,0,0,0.26), 8px 2px 10px -10px rgba(0,0,0,0.26)',
    24: '2px 2px 10px 0 rgba(0,0,0,0.2)'
  },
  typography: {
    fontFamily: 'Open Sans',
    h1: {
      fontSize: '56px',
      fontWeight: 700,
      lineHeight: '84px'
    },
    h2: {
      fontSize: '40px',
      fontWeight: 700,
      lineHeight: '66px'
    },
    h3: {
      fontSize: '32px',
      fontWeight: 700,
      lineHeight: '48px'
    },
    h4: {
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '36px'
    },
    h5: {
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '22px'
    },
    subtitle1: {
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '22px'
    },
    subtitle2: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '26px'
    },
    subtitle3: {
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '13px'
    },
    modalHeadline: {
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '30px'
    },
    body1: {
      fontSize: '16px',
      lineHeight: '26px'
    },
    body2: {
      fontSize: '14px',
      lineHeight: '23px'
    },
    body3: {
      fontSize: '14px',
      lineHeight: '18px'
    },
    body4: {
      fontSize: '14px',
      lineHeight: '16px'
    },
    bodyBold: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '22px'
    },
    smallLabel1: {
      fontSize: '13px',
      lineHeight: '21px'
    },
    smallLabel2: {
      fontSize: '12px',
      lineHeight: '20px'
    },
    smallLabelBold: {
      fontSize: '13px',
      fontWeight: 700,
      lineHeight: '21px'
    },
    microtext1: {
      fontSize: '11px',
      lineHeight: '12px'
    },
    microtext2: {
      fontSize: '11px',
      lineHeight: '18px'
    },
    microtext3: {
      fontSize: '9px',
      lineHeight: '12px'
    },
    microtextBold: {
      fontSize: '11px',
      fontWeight: 700,
      lineHeight: '13px'
    },
    link1: {
      fontSize: '16px',
      lineHeight: '26px'
    },
    link2: {
      fontSize: '14px',
      lineHeight: '23px'
    },
    userText: {
      fontSize: '18px',
      fontWeight: 300,
      lineHeight: '30px'
    }
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: '#F4F6F7'
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-expanded': {
            boxShadow: theme.shadows[2]
          }
        })
      }
    },
    MuiAlert: {
      defaultProps: {
        variant: 'filled'
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
        size: 'large'
      },
      styleOverrides: {
        root: {
          fontWeight: 600,
          textTransform: 'none',
          whiteSpace: 'nowrap',
          '&.Mui-disabled': {
            background: palette.gradient.disabled,
            color: '#FFF'
          }
        },
        text: {
          '&.Mui-disabled': {
            background: 'transparent',
            color: palette.tertiary.main
          }
        },
        sizeSmall: {
          minWidth: 54,
          height: 28,
          fontSize: 13,
          lineHeight: '21px'
        },
        sizeMedium: {
          minWidth: 70,
          height: 36,
          fontSize: '14px',
          lineHeight: '23px'
        },
        sizeLarge: {
          minWidth: 112,
          height: 42,
          fontSize: '18px',
          lineHeight: '30px'
        }
      },
      variants: [
        {
          props: { variant: 'unmarked' },
          style: {
            color: palette.tertiary.dark,
            fontWeight: 400,
            textDecoration: 'underline',
            '&:hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline'
            }
          }
        }
      ]
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: Link
      },
      styleOverrides: {
        root: ({ theme }) => ({
          fontFamily: theme.typography.fontFamily
        })
      }
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          color: palette.text.primary
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '5%'
        }
      }
    },
    MuiLink: {
      defaultProps: {
        component: Link,
        color: palette.text.primary
      },
      styleOverrides: {
        root: {
          textDecoration: 'none'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontFamily: theme.typography.fontFamily
        })
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none'
        }
      }
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          microtext1: 'p',
          microtext2: 'p',
          microtextBold: 'p'
        }
      }
    },
    MuiScopedCssBaseline: {
      styleOverrides: {
        root: ({ theme }) => ({
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          fontFamily: theme.typography.fontFamily
        })
      }
    }
  }
});
