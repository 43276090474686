import React from 'react';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';

const StyledLabel = styled(Typography, { shouldForwardProp: prop => prop !== 'isEnabled' })`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ isEnabled, theme }) => isEnabled ? theme.palette.secondary.main : theme.palette.tertiary.dark};
`;

export default function UserActiveLabel({ isEnabled }) {
  return (
    <StyledLabel isEnabled={isEnabled}>
      {isEnabled ? 'ACTIVE' : 'INACTIVE'}
    </StyledLabel>
  );
}
