import { Box, Link } from '@mui/material';
import React from 'react';
import { Link as RouterLink, useLocation, useRouteMatch } from 'react-router-dom';
import { CODE_SENT } from 'src/constants';
import { ROUTES } from 'src/router';

const Footer = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const showSendAgainLink = pathname === `${path}/reset`;
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Link
        onClick={() => localStorage.removeItem(CODE_SENT)}
        component={RouterLink}
        to={ROUTES.LOGIN}
        variant="body2"
        color="textSecondary"
        underline="hover">
        Cancel
      </Link>
      {showSendAgainLink && (
        <Link
          component={RouterLink}
          to={path}
          variant="subtitle2"
          color="textSecondary"
          underline="hover">
          Didn&apos;t Receive Code? Send it again.
        </Link>
      )}
    </Box>
  );
};

export default Footer;
