import merge from 'lodash/merge';
import { colors, responsiveFontSizes, adaptV4Theme, createTheme as createMuiTheme } from '@mui/material';

import Logger from 'src/utils/logger';
import { THEMES } from 'src/constants';

import { softShadows, strongShadows } from './shadows';
import typography from './typography';

export const COLORS = {
  AutoSaveColor: '#00A761',
  ErrorColor: '#ED6B59'
};

const neutrals = {
  white: '#fff',
  grey10: '#999',
  grey20: '#ccc'
};

const baseOptions = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    }
  }
};

const themesOptions = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      },
      MuiAppBar: {
        root: {
          borderRadius: 0
        }
      },
      MuiPaper: {
        root: {
          borderRadius: 8
        },
        rounded: {
          borderRadius: 8
        },
        elevation1: {
          borderRadius: 8,
          boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.08)'
        }
      },
      MuiTablePagination: {
        toolbar: {
          background: '#fff'
        }
      },
      MuiAccordion: {
        root: {
          borderRadius: '8px !important'
        }
      },
      MuiCardContent: {
        root: {
          paddingLeft: 24,
          paddingRight: 24
        }
      },
      MuiRadio: {
        root: {
          paddingTop: 2,
          paddingBottom: 2
        }
      },
      MuiDialogTitle: {
        root: {
          fontSize: 22,
          fontWeight: 'bold'
        }
      },
      MuiOutlinedInput: {
        root: {
          '&:hover $notchedOutline': {
            borderColor: '#4038CE'
          },
          '&$focused $notchedOutline': {
            borderColor: '#4038CE'
          },
          '&$disabled $notchedOutline': {
            borderColor: '#4CAF50'
          },
          '&$error $notchedOutline': {
            borderColor: '#F32C02'
          },
          '&$notchedOutline': {
            borderColor: '#4CAF50'
          }
        }
      },
      MuiButton: {
        // Name of the rule
        containedPrimary: {
          backgroundColor: '#074E7D',
          fontSize: 18,
          fontWeight: 600,
          '&:hover': {
            backgroundColor: '#002d57'
          }
        },
        containedSecondary: {
          fontWeight: 600,
          fontSize: 18,
          backgroundColor: '#E2E1F9',
          '&:hover': {
            backgroundColor: '#E2E1F9',
            opacity: 0.7
          },
          color: 'black'
        },
        label: {
          textTransform: 'none'
        },
        outlinedSecondary: {
          border: '2px solid #4038CE',
          color: '#000',
          '&:hover': {
            border: '2px solid #4038CE'
          }
        }
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: '#fff',
          borderRadius: 13,
          boxShadow: '2px 4px 6px -2px rgba(0, 0, 0, 0.12)',
          border: '1px solid #F5F5F5'
        }
      }
    },
    palette: {
      mode: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: '#F7F8FC',
        paper: colors.common.white
      },
      primary: {
        main: '#541775'
      },
      secondary: {
        main: '#4038CE'
      },
      success: {
        main: '#00B8AE'
      },
      text: {
        primary: '#1E1E1E',
        secondary: '#A4A4A4',
        common: '#757575',
        primary10: '#263238'
      },
      neutrals,
      completed: COLORS.AutoSaveColor,
      attention: COLORS.ErrorColor,
      main: '#074E7D'
    },
    shadows: softShadows
  },
  {
    name: THEMES.ONE_DARK,
    palette: {
      mode: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#282C34',
        dark: '#1c2025',
        paper: '#282C34'
      },
      primary: {
        main: '#8a85ff'
      },
      secondary: {
        main: '#8a85ff'
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb'
      },
      neutrals,
      main: '#074E7D'
    },
    shadows: strongShadows
  },
  {
    name: THEMES.UNICORN,
    palette: {
      mode: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#2a2d3d',
        dark: '#222431',
        paper: '#2a2d3d'
      },
      primary: {
        main: '#a67dff'
      },
      secondary: {
        main: '#a67dff'
      },
      text: {
        primary: '#f6f5f8',
        secondary: '#9699a4'
      },
      neutrals,
      completed: COLORS.AutoSaveColor,
      attention: COLORS.ErrorColor,
      main: '#074E7D'
    },
    shadows: strongShadows
  }
];

const zIndex = {
  mobileStepper: 1000,
  speedDial: 1050,
  appBar: 1100,
  drawer: 1200,
  overlay: 1250,
  modal: 1300,
  snackbar: 1400,
  tooltip: 1500
};

// eslint-disable-next-line import/prefer-default-export
export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find(theme => theme.name === config.theme);

  if (!themeOptions) {
    Logger.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(adaptV4Theme(merge({}, baseOptions, themeOptions,
    { direction: config.direction, zIndex }
  )));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
