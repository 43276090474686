import React, { useEffect, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';

const AlertSnackbar = ({ children, message, severity, ...props }) => (
  <Snackbar
    autoHideDuration={5000}
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    style={{ top: 125 }}
    {...props}
  >
    <Alert
      onClose={props.onClose}
      severity={severity}
      action={false}
    >
      {children ?? message}
    </Alert>
  </Snackbar>
);

export default function BewSnackbar({ message, severity, onClose, ...props }) {
  const [snackPack, setSnackPack] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);
  const [currentSeverity, setSeverity] = useState();

  useEffect(() => {
    if (snackPack.length && !messageInfo) { // Set a new snack when we don't have an active one
      // Delay setting new values until message removed, so you don't rerender them before animation ends
      setMessageInfo({ ...snackPack[0] });
      setSnackPack(prev => prev.slice(1));
      setSeverity(severity);
      setIsOpen(true);
    } else if (snackPack.length && messageInfo && isOpen) { // Close an active snack when a new one is added
      setIsOpen(false);
    }
  }, [snackPack, messageInfo, isOpen]);

  useEffect(() => {
    if (message) {
      setSnackPack(prev => [...prev, { message, key: Date.now() }]);
    }
  }, [message]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') { // Do not delete on clickaway
      return;
    }
    setIsOpen(false);
    onClose?.(event, reason);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  return (
    <AlertSnackbar
      key={messageInfo?.key}
      open={isOpen}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
      message={messageInfo?.message}
      severity={currentSeverity}
      {...props}
    />
  );
}
