import React from 'react';
import { Card, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { CertificationStatus } from 'src/models/certification';
import {
  LOGO_SIZE,
  LogoWrapper,
  CurrentCycleLabel
} from './CertificationStatusElement';

const {
  BASELINE_DRAFT,
  BASELINE_REVIEW,
  EVALUATION_IN_REVIEW
} = CertificationStatus;

export default function CertificationStatusReviewElement({ certification, partner }) {
  const theme = useTheme();
  const currentPosition = certification.currentCycle?.position;
  const status = certification.status;
  const isBaseline = status === BASELINE_DRAFT || status === BASELINE_REVIEW || status === EVALUATION_IN_REVIEW;

  return (
    <Card sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box display='flex' flexDirection='row'>
        <LogoWrapper
          updatedAt={certification?.updatedAt}
          type={certification?.assessmentType?.title}
          width={LOGO_SIZE}
          height={LOGO_SIZE}
        />
        <Box ml={4} display='flex' flexDirection='column'>
          <CurrentCycleLabel isActive marginLeft>
            Current Cycle:&nbsp;
            <b>
              {currentPosition}
            </b>
          </CurrentCycleLabel>
          <Typography
            variant='h4'
            fontWeight={600}
            fontSize={20}
            mt={1}
          >
            {partner.name}
          </Typography>
          <Box display='flex'>
            <Typography variant='body1'
              fontWeight={700}
              fontSize={16}
              mt={1}
              color={isBaseline ? theme.palette.grey[600] : theme.palette.primary.main}
            >
              Reviewer Score:&nbsp;
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        minWidth='162px'
        mt={3}
        mr={3}
      >
      </Box>
    </Card >
  );
}
