import React from 'react';
import { TextField } from '@mui/material';
import { useErrorOutlineStyles } from 'src/hooks/useGlobalStyles';

const Input = props => {
  const globalErrorOutineClasses = useErrorOutlineStyles();
  return (
    <TextField
      {...props}
      variant="outlined"
      margin="normal"
      InputLabelProps={{
        shrink: true,
        classes: {
          root: globalErrorOutineClasses.label,
          focused: globalErrorOutineClasses.focusedLabel
        }
      }}
      InputProps={{
        classes: {
          root: globalErrorOutineClasses.textFieldRoot,
          error: globalErrorOutineClasses.error,
          notchedOutline: globalErrorOutineClasses.notchedOutline,
          focused: globalErrorOutineClasses.clicked
        }
      }}
      fullWidth required />
  );
};

export default Input;
