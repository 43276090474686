import { getPartners } from 'src/api/partners';
import logger from 'src/utils/logger';

const log = logger.child({ store: 'psm' });

const init = () => ({
  partners: null,
  isLoading: false
});

const createActions = set => ({
  fetch: () => {
    set(state => { state.partners.isLoading = true; }, 'psm/partner/fetchBegin');
    return getPartners()
      .then(partners => set(state => { state.partners.partners = partners; }, 'psm/partner/fetchSuccess'))
      .catch(err => log.error('fetchPartners', err))
      .finally(() => set(state => { state.partners.isLoading = false; }, 'psm/partner/fetchFinally'));
  },
  setPartners: partners => set(state => { state.partners.partners = partners; }, 'psm/partner/setPartners')
});

export default { init, createActions };
