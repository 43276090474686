import styled from '@emotion/styled';
import { Button, Card, Grid, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { cloneDeep } from 'lodash';
import React, { useMemo } from 'react';
import { prettifyDateTime } from 'src/utils/datetime';
import Question from './Question';

const Score = styled(Typography)`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  box-shadow: ${({ theme }) => theme.shadows[3]};
`;

const Navigation = ({ currentPosition, assessedCount, updatedAt, onNext, onPrevious, showIndicatorPosition }) => {
  const theme = useTheme();
  const dateString = prettifyDateTime(updatedAt);

  const handlePreviousClick = () => {
    onPrevious();
    window.scrollTo({ top: 220, behavior: 'auto' });
  };

  const handleNextClick = () => {
    onNext();
    window.scrollTo({ top: 220, behavior: 'auto' });
  };

  return (
    <>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <Button onClick={handlePreviousClick}>
          Prev
        </Button>
        {
          showIndicatorPosition &&
          <Typography color={theme.palette.tertiary.dark}>
            Indicator {currentPosition} of {assessedCount}
          </Typography>
        }
        <Button onClick={handleNextClick}>
          Next
        </Button>
      </Box>
      <Typography
        component='div'
        variant='smallLabel2'
        color={theme.palette.tertiary.dark}
        textAlign='right'
        mt={2}
      >
        {updatedAt ? `PROGRESS SAVED - ${dateString}` : ''}
      </Typography>
    </>
  );
};

const IndicatorNameHeader = styled(({ indicator, position, score, showScore, ...props }) => (
  <Box {...props}>
    <Typography
      variant="h4"
      component="span"
    >
      {
        indicator.isOrdered
          ? `${position}. ${indicator.name}`
          : indicator.name
      }
    </Typography>
    {
      showScore &&
      <Box
        display='flex'
        alignItems='center'
        whiteSpace='nowrap'
        ml={1}
      >
        {
          indicator.isAssessed &&
          <>
            <Typography
              variant='bodyBold'
              component='span'
            >
              Indicator Score
            </Typography>
            <Score
              variant='bodyBold'
              ml={1}
            >
              {score}
            </Score>
          </>
        }
      </Box>
    }
  </Box>
))`
  min-height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: ${({ theme }) => theme.spacing(1, 3)};
  background-color: ${({ theme }) => theme.palette.primary.dark};
  box-shadow: ${({ theme }) => theme.shadows[3]};
`;

export default function IndicatorView({
  indicator,
  answers,
  position,
  cyclePosition,
  assessedCount,
  scores,
  updatedAt,
  isEditable = false,
  onPrevious,
  onNext,
  onAnswerUpdate
}) {
  const answersByQuestionId = useMemo(() => answers.reduce((acc, a) => acc.set(a.question.id, a), new Map()), [answers]);
  // Deep copy indicator.questions so we don't directly mutate state
  const indicatorCopy = cloneDeep(indicator);
  indicatorCopy.questions.forEach(question => {
    if (cyclePosition > 3 && question.titleYear4Plus) {
      updateQuestionPropertiesYear4Plus(question);
    }
  });

  return (
    <Grid container spacing={2}>
      {
        <Grid item xs={12}>
          <Card>
            <IndicatorNameHeader
              indicator={indicatorCopy}
              position={position}
              score={scores.indicatorScore?.[indicator.id] ?? 0}
              showScore={true}
            />
            <Box p={3}>
              {indicatorCopy.questions.map(q => (
                <Question
                  key={q.id}
                  question={q}
                  answer={answersByQuestionId.get(q.id)}
                  disabled={!isEditable}
                  onChange={answer => onAnswerUpdate(answer, q)}
                />
              ))}
            </Box>
          </Card>
        </Grid>
      }

      <Grid item xs={12} my={3} mx={4}>
        <Navigation
          currentPosition={position}
          assessedCount={assessedCount}
          updatedAt={updatedAt}
          onNext={onNext}
          onPrevious={onPrevious}
          showIndicatorPosition={indicator.isOrdered}
        />
      </Grid>
    </Grid>
  );
}

function updateQuestionPropertiesYear4Plus(question) {
  question.definition = question.definitionYear4Plus;
  question.fieldLabel = question.fieldLabelYear4Plus;
  question.fieldType = question.fieldTypeYear4Plus;
  question.fields = question.fieldsYear4Plus;
  question.instructions = question.instructionsYear4Plus;
  question.isOptional = question.isOptionalYear4Plus;
  question.metadata = question.metadataYear4Plus;
  question.notesFieldTitle = question.notesFieldTitleYear4Plus;
  question.options = question.optionsYear4Plus;
  question.scoring = question.scoringYear4Plus;
  question.title = question.titleYear4Plus;
  question.validation = question.validationYear4Plus;
}
