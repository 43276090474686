// TODO: grab them shared module

export const ProgramTypes = {
  BEW: 'Black Equity At Work',
  LEW: 'Latinx Equity At Work',
  GEW: 'Gender Equity At Work'
};

export const CertificationPlatformStatus = {
  INACTIVE: 'inactive',
  ACTIVE: 'active'
};

// TODO: Rename X_REVIEW to X_IN_REVIEW to match the server
export const CertificationStatus = {
  ONBOARDING: 'Onboarding',
  BASELINE_DRAFT: 'Baseline Draft',
  BASELINE_REVIEW: 'Baseline In Review',
  BASELINE_APPROVED: 'Baseline Approved',
  EVALUATION_DRAFT: 'Assessment In Progress',
  EVALUATION_IN_REVIEW: 'Assessment In Review',
  EVALUATION_APPROVED: 'Certified',
  YEAR_OF_EQUITY: 'Year of Equity'
};

export const ExtendedCertificationStatus = {
  ...CertificationStatus,
  BASELINE_NOT_STARTED: 'Baseline Not Started',
  EVALUATION_APPROVED_NO_BADGE: 'Evaluation Approved No Badge',
  EVALUATION_NOT_STARTED: 'Assessment Not Started',
  EVALUATION_NOT_STARTED_OPEN: 'Assessment Open Not Started'
};

export const certificationStatusesOrderedCycleOne = [
  CertificationStatus.ONBOARDING,
  CertificationStatus.BASELINE_DRAFT,
  CertificationStatus.BASELINE_REVIEW,
  CertificationStatus.BASELINE_APPROVED,
  CertificationStatus.EVALUATION_DRAFT,
  CertificationStatus.EVALUATION_IN_REVIEW,
  CertificationStatus.EVALUATION_APPROVED
];

export const certificationStatusesOrdered = [
  CertificationStatus.EVALUATION_DRAFT,
  CertificationStatus.EVALUATION_IN_REVIEW,
  CertificationStatus.EVALUATION_APPROVED
];

export const certificationStatusOrderByStatusCycleOne = certificationStatusesOrderedCycleOne
  .reduce((acc, value, index) => acc.set(value, index), new Map());

export const certificationStatusOrderByStatus = certificationStatusesOrdered
  .reduce((acc, value, index) => acc.set(value, index), new Map());

export const CommentType = { BASELINE: 'baseline' };

export const CertificationStatusColor = {
  [CertificationStatus.BASELINE_DRAFT]: '#FF9213',
  [CertificationStatus.BASELINE_REVIEW]: '#FF9213',
  [CertificationStatus.BASELINE_APPROVED]: '#00B960'
};

export const CertificationActiveSteps = {
  [CertificationStatus.BASELINE_DRAFT]: 0,
  [CertificationStatus.BASELINE_REVIEW]: 1,
  [CertificationStatus.BASELINE_APPROVED]: 1.5,
  [CertificationStatus.EVALUATION_DRAFT]: 4,
  [CertificationStatus.EVALUATION_IN_REVIEW]: 4.5,
  [CertificationStatus.EVALUATION_APPROVED]: 5
};

export const CertificationProgressTracker = [
  'Baseline in Progress',
  'Baseline Complete',
  'Equity in Progress',
  'Annual Review'
];
