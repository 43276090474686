import React from 'react';
import clsx from 'clsx';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  timeContainer: ({ isLabelTop }) => ({
    display: 'flex',
    flexDirection: isLabelTop ? 'column' : 'row',
    fontWeight: isLabelTop ? '500' : '700',
    alignItems: isLabelTop ? 'left' : 'center'
  }),
  timeWrapper: {
    display: 'flex',
    flexDirection: 'row'
  },
  currentPeriod: {
    '& $timeElement': {
      background: '#97110E'
    }
  },
  nextPeriod: {
    '& $timeElement': {
      background: '#074E7D'
    }
  },
  timeElement: {
    width: '44px',
    height: '44px',
    display: 'flex',
    flexDirection: 'column',
    color: '#fff',
    marginRight: '2px',
    textAlign: 'center',
    justifyContent: 'center'
  },
  timeValue: {
    fontSize: '16px',
    lineHeight: '16px',
    fontWeight: 700
  },
  timeLabel: {
    fontSize: '10px',
    lineHeight: '10px',
    fontWeight: 500
  },
  leftBorderRadius: {
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px'
  },
  rightBorderRadius: {
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px'
  }
}));

const DESCRIPTION_LABEL = {
  CURRENT: 'Certification Period closes in:',
  NEXT: 'Certification Period opens in:'
};

function TimeElement({ timeValue, timeLabel, hasLeftBorderRadius, hasRightBorderRadius }) {
  const styleClasses = useStyles();

  return (
    <Box
      className={clsx(
        styleClasses.timeElement,
        { [styleClasses.leftBorderRadius]: hasLeftBorderRadius },
        { [styleClasses.rightBorderRadius]: hasRightBorderRadius }
      )}
    >
      <Typography className={styleClasses.timeValue}>
        {timeValue}
      </Typography>
      <Typography className={styleClasses.timeLabel}>
        {timeLabel}
      </Typography>
    </Box>
  );
}

export default function CertificationCountdown({ period, isCurrent, remainingTime, isLabelTop = false }) {
  const { days, hours, minutes } = remainingTime;
  const styleClasses = useStyles({ isLabelTop });
  const dateLabel = `${period.title} ${period.startDate.getFullYear()}`;
  const descriptionLabel = isCurrent ? DESCRIPTION_LABEL.CURRENT : DESCRIPTION_LABEL.NEXT;

  return (
    <Box mt={1} className={styleClasses.timeContainer}>
      <Box mr={1}>{dateLabel} {descriptionLabel}</Box>
      <Box className={clsx(styleClasses.timeWrapper, {
        [styleClasses.currentPeriod]: isCurrent,
        [styleClasses.nextPeriod]: !isCurrent
      })}>
        <TimeElement timeValue={days} timeLabel='days' hasLeftBorderRadius />
        <TimeElement timeValue={hours} timeLabel='hours' />
        <TimeElement timeValue={minutes} timeLabel='min' hasRightBorderRadius />
      </Box>
    </Box>
  );
}
