import React from 'react';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import useStore from 'src/store';
import { selectIsSessionLoaded } from 'src/store/auth/selectors';
import AppNext from 'src/next/App';
import AnonRoute from './AnonRoute';
import PrivateRoute from './PrivateRoute';
import ROUTES from './routes';
import {
  Login,
  ResetPassword,
  ForgetPassword,
  MFA
} from '../views';

function Router({ children }) {
  const isSessionLoaded = useStore(selectIsSessionLoaded);

  return (
    <BrowserRouter>
      {isSessionLoaded && (
        <Switch>
          <AnonRoute path={ROUTES.MFA_AUTH} component={MFA} />
          <AnonRoute path={ROUTES.LOGIN} component={Login} />
          <AnonRoute path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
          <AnonRoute path={ROUTES.FORGOT_PASSWORD} component={ForgetPassword} />
          <PrivateRoute path={ROUTES.NEXT} component={AppNext} />
          {/* TODO: Remove this and replace /next with / after completely deprecating the old app */}
          {/* <PrivateRoute path="/">{children}</PrivateRoute> */}
          <Redirect to={ROUTES.NEXT} />
        </Switch>
      )}
    </BrowserRouter>
  );
}

export { ROUTES };
export default Router;
