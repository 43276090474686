import React, { useState, useCallback, useEffect, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useLocation } from 'react-router-dom';
import useSize from '@react-hook/size';
import { getIsIframed, MESSAGE_TYPES, postIframeMessage } from 'src/next/util/iframeMessaging';
import NavBar from './NavBar';
import TopBar from './TopBar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    width: '100%'
  },
  content: ({ isIframed }) => ({
    width: '100%',
    paddingTop: isIframed ? 0 : 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: isIframed ? 0 : 288
    }
  })
}));

function useScrollToTopOnPageChange() {
  const { pathname } = useLocation();
  const contentRef = useRef();
  useEffect(() => {
    contentRef.current.scrollTop = 0;
  }, [pathname]);
  return contentRef;
}

function Dashboard({ organization, renderNavigation, children }) {
  const isIframed = getIsIframed();
  const classes = useStyles({ isIframed });
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleMobileClose = useCallback(() => setMobileNavOpen(false), []);
  const contentRef = useScrollToTopOnPageChange();
  const [, height] = useSize(contentRef);

  useEffect(() => {
    postIframeMessage(MESSAGE_TYPES.CHANGE_HEIGHT, height);
  }, [height]);

  return (
    <div className={classes.root}>
      {
        !isIframed &&
        <>
          <TopBar onMobileNavOpen={() => setMobileNavOpen(!isMobileNavOpen)} />
          <NavBar
            organization={organization}
            onMobileClose={handleMobileClose}
            openMobile={isMobileNavOpen}
          >
            {renderNavigation()}
          </NavBar>
        </>
      }
      <div className={classes.content} ref={contentRef}>
        {children}
      </div>
    </div>
  );
}

export default Dashboard;
