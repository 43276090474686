import styled from '@emotion/styled';
import { Avatar, Button, Card, Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { generatePath } from 'react-router-dom';
import PillarOverviewIcon from './pillar_overview.png';
import routes from '../navigation/routes';

const avatarLeft = 40;
const avatarWidth = 98;
const avatarSpaceWidth = 2 * avatarLeft + avatarWidth;

const StyledAvatar = styled(Avatar)`
  width: ${avatarWidth}px;
  height: ${avatarWidth}px;
  position: absolute;
  top: 28px;
  left: ${avatarLeft}px;
  background-color: ${({ theme }) => theme.palette.primary.dark};
  box-shadow: ${({ theme }) => theme.shadows[3]};
`;

const Header = styled.div`
  position: relative;
  min-height: 72px;
  display: flex;
  align-items: center;
  color: white;
  padding: ${({ theme }) => theme.spacing(1, 3)};
  padding-left: ${avatarSpaceWidth}px;
  background-color: ${({ theme }) => theme.palette.primary.dark};
  box-shadow: ${({ theme }) => theme.shadows[3]};
`;

const Content = styled.div`
  padding: ${({ theme }) => theme.spacing(3, 3, 5)};
  padding-left: ${avatarSpaceWidth}px;

  & > * {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

export default function PillarView({ certification, certificationStep, pillar }) {
  const [firstIndicator] = pillar.indicators;

  return (
    <Card>
      <Header>
        <Typography variant="h4" component="span" pr={4}>
          {pillar.name}:&nbsp;
          <Box component='span' sx={{ fontWeight: 400, fontStyle: 'italic' }}>
            Overview
          </Box>
        </Typography>
        <StyledAvatar src={PillarOverviewIcon} /> {/* TODO: Should come from pillar metadata in the future */}
      </Header>
      <Content dangerouslySetInnerHTML={{ __html: pillar.metadata?.description }} />
      <Box textAlign='right' px={2}>
        <Divider />
        <Button to={generatePath(routes.CERTIFICATION_INDICATOR, {
          certificationId: certification.id,
          certificationStepId: certificationStep.id,
          pillarId: pillar.id,
          indicatorId: firstIndicator.id
        })} color="secondary" sx={{ mt: 2, mb: 3 }}>
          Begin
        </Button>
      </Box>
    </Card>
  );
}
