import axios from 'axios';
import { getCertifications, getCertification, submitCertification, reviewCertification } from 'src/api/certifications';
import { getCurrentCertificationCycle } from './certificationUtil';
import { API_URL } from '../../constants';

export const ACTIVITIES_URL = `${API_URL}/activities`;
export const CERTIFICATIONS_URL = `${API_URL}/certifications`;
export const ASSET_URL = `${API_URL}/assets`;

const getCertificationUrl = certificationId => `${CERTIFICATIONS_URL}/${certificationId}`;
const getLatestCertificationActivityUrl = certificationId => `${ACTIVITIES_URL}/${certificationId}/latest`;
const getBaselineUrl = (certificationId, baselineId) => `${getCertificationUrl(certificationId)}/baselines/${baselineId}`;
const getEvaluationUrl = (certificationId, evaluationId) => `${getCertificationUrl(certificationId)}/evaluation/${evaluationId}`;

const api = {
  getCertificationCycles(certificationId) {
    return axios.get(`${getCertificationUrl(certificationId)}/cycles`);
  },
  getCertificationStep(certificationId, certificationStepId) {
    return axios.get(`${getCertificationUrl(certificationId)}/steps/${certificationStepId}`);
  },
  getCertificationSteps(certificationId) {
    return axios.get(`${getCertificationUrl(certificationId)}/steps`);
  },
  getCertificationStepsAssets(certificationId) {
    return axios.get(`${getCertificationUrl(certificationId)}/assets`);
  },
  getLatestCertificationActivity(certificationId) {
    return axios.get(getLatestCertificationActivityUrl(certificationId));
  },
  getPillars(certificationId, certificationCycleId) {
    return axios.get(`${getCertificationUrl(certificationId)}/cycles/${certificationCycleId}/pillars`);
  },
  beginNextStep(certificationId) {
    return axios.put(`${getCertificationUrl(certificationId)}/nextStep`);
  },
  getCertificationsReport(stepPosition, cycleYear, assessmentType) {
    return axios.get(`${CERTIFICATIONS_URL}/report`, {
      responseType: 'blob',
      params: { stepPosition, cycleYear, assessmentType }
    });
  },
  upsertAnswer(certificationId, baselineId, data) {
    return axios.put(`${getBaselineUrl(certificationId, baselineId)}/answers`, data);
  },
  upsertEvaluationAnswer(certificationId, evaluationId, data) {
    return axios.put(`${getEvaluationUrl(certificationId, evaluationId)}/answers`, data);
  },
  getStepAssets(certificationId, certificationStepId) {
    return axios.get(`${getCertificationUrl(certificationId)}/steps/${certificationStepId}/assets`);
  },
  getAssetAccessKey({ assetName }) {
    return axios.get(`${ASSET_URL}/presigned-url/${assetName}`);
  },
  uploadStepAsset(certificationId, certificationStepId, data) {
    return axios.post(`${getCertificationUrl(certificationId)}/steps/${certificationStepId}`, data);
  },
  deleteStepAsset(certificationId, certificationStepId) {
    return axios.delete(`${getCertificationUrl(certificationId)}/steps/${certificationStepId}`);
  }
};

export default (set, get) => ({
  certifications: [],
  certificationCycles: [],
  certificationSteps: [],
  certificationStepsAssets: [],
  lastCertificationActivity: null,
  pillars: [],

  async getCertifications(params) {
    const data = await getCertifications(params);

    set(() => ({ certifications: data }), 'GET_CERTIFICATIONS');
  },

  async getCertificationById(certificationId) {
    const data = await getCertification(certificationId);

    set(() => ({ certifications: [data] }), 'GET_CERTIFICATION_BY_ID');
  },

  resetCertifications() {
    set(() => ({ certifications: [] }), 'RESET_CERTIFICATIONS');
  },

  async getCertificationCycles(certificationId) {
    const data = await api.getCertificationCycles(certificationId);

    set(() => ({ certificationCycles: data }), 'GET_CERTIFICATION_CYCLES');
  },

  resetCertificationCycles() {
    set(() => ({ certificationCycles: [] }), 'RESET_CERTIFICATION_CYCLES');
  },

  async getCertificationStep(certificationId, certificationStepId) {
    const data = await api.getCertificationStep(certificationId, certificationStepId);

    set(({ certificationSteps }) => ({
      certificationSteps: certificationSteps.map(cs => cs.id === data.id ? { ...cs, ...data } : cs)
    }), 'GET_CERTIFICATION_STEP_BY_ID');
  },

  async getCertificationSteps(certificationId) {
    const data = await api.getCertificationSteps(certificationId);

    set(() => ({ certificationSteps: data }), 'GET_CERTIFICATION_STEPS');
  },

  async getCertificationStepsAssets(certificationId) {
    const data = await api.getCertificationStepsAssets(certificationId);

    set(() => ({ certificationStepsAssets: data }), 'GET_CERTIFICATION_STEPS_ASSETS');
  },

  async getLatestCertificationActivity(certificationId) {
    const { data } = await api.getLatestCertificationActivity(certificationId);
    set(() => ({ lastCertificationActivity: data }), 'GET_LAST_CERTIFICATION_ACTIVITY');
  },

  resetCertificationSteps() {
    set(() => ({ certificationSteps: [] }), 'RESET_CERTIFICATION_STEPS');
  },

  resetCertificationActivities() {
    set(() => ({ lastCertificationActivity: null }), 'RESET_LAST_CERTIFICATION_ACTIVITY');
  },

  resetCertificationStepsAssets() {
    set(() => ({ certificationStepsAssets: [] }), 'RESET_CERTIFICATION_STEPS_ASSETS');
  },

  async getPillars(certificationId, certificationCycleId) {
    const data = await api.getPillars(certificationId, certificationCycleId);

    set(() => ({ pillars: data }), 'GET_PILLARS');
  },

  async resetPillars() {
    set(() => ({ pillars: [] }), 'RESET_PILLARS');
  },

  async refreshCertification(certificationId) {
    const state = get();

    await Promise.all([
      state.getCertificationById(certificationId),
      state.getCertificationCycles(certificationId),
      // Update steps as well, since they would contain outdated certification
      state.getCertificationSteps(certificationId)
    ]);

    const currentCycle = getCurrentCertificationCycle(get().certificationCycles);

    // Update pillars as well, since they are filtered by certification and cycle
    state.getPillars(certificationId, currentCycle.id);
  },

  async beginNextStep(certificationId) {
    const { data: stepId } = await api.beginNextStep(certificationId);

    await get().refreshCertification(certificationId);

    return stepId;
  },

  async upsertAnswer(certificationId, baselineId, question, data = {}) {
    await api.upsertAnswer(certificationId, baselineId, { questionId: question.id, ...data });
    await get().getCertificationStep(certificationId, baselineId);
  },

  async upsertEvaluationAnswer(certificationId, evaluationId, question, data = {}) {
    await api.upsertEvaluationAnswer(certificationId, evaluationId, { questionId: question.id, ...data });
    await get().getCertificationStep(certificationId, evaluationId);
  },

  async submitCertification(certificationId) {
    await submitCertification(certificationId);

    return get().refreshCertification(certificationId);
  },

  async reviewCertification(certificationId, isApproved, certificationStep) {
    await reviewCertification(certificationId, isApproved, certificationStep);

    return get().refreshCertification(certificationId);
  },

  getCertificationsReport(reportType, cycle, assessmentType) {
    return api.getCertificationsReport(reportType, cycle, assessmentType);
  },

  async getStepAssets(certificationId, certificationStepId) {
    return api.getStepAssets(certificationId, certificationStepId);
  },

  async getAssetAccessKey(data) {
    return api.getAssetAccessKey(data);
  },

  async uploadStepAsset(certificationId, certificationStepId, data) {
    return api.uploadStepAsset(certificationId, certificationStepId, data);
  },

  async deleteStepAsset(certificationId, certificationStepId) {
    return api.deleteStepAsset(certificationId, certificationStepId);
  }
});
