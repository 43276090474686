import { useCallback, useState } from 'react';

export default function usePopperState() {
  const [anchorEl, setAnchorEl] = useState(null);
  const isPopperOpen = Boolean(anchorEl);

  const onPopperOpen = useCallback(event => setAnchorEl(event.currentTarget), []);
  const onPopperClose = useCallback(() => setAnchorEl(null), []);

  return [
    anchorEl,
    isPopperOpen,
    onPopperOpen,
    onPopperClose
  ];
}
