import React from 'react';
import { Box } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Typography from 'src/components/common/Typography';
import EmbeddedContent from './EmbeddedContent';
import definitionsComponentMap from './content/definitionsComponentMap';

function Definitions({ definitions }) {
  const Component = definitionsComponentMap[definitions.type];
  if (Component) return <Component {...definitions} />;
  return null;
}

function Item({ label, definitions }) {
  return (
    <Box ml={1} my={3} display="flex" >
      <CheckBoxOutlineBlankIcon />
      <Box ml={1} pt={0.25} display="flex" flexDirection="column" alignItems="flex-start">
        <EmbeddedContent
          variant="subtitle2"
          dangerouslySetInnerHTML={{ __html: label }}
        />
        {definitions && (
          <>
            <br />
            <Definitions definitions={definitions} />
          </>
        )}
      </Box>
    </Box>
  );
}

function CheckList({ title, items, ...props }) {
  return (
    <Box {...props}>
      <Typography variant="h4" transform="uppercase">{title}</Typography>
      {items.map((item, idx) => <Item key={`${item.label}-${idx}`} {...item}/>)}
    </Box>
  );
}

export default CheckList;
