import React, { useCallback, useRef } from 'react';
import styled from '@emotion/styled';
import { ClickAwayListener, Grow, Paper, Popper } from '@mui/material';

const StyledPopper = styled(Popper)`
  z-index: 1300;
`;

function getTransformOriginValue(transformOrigin) {
  return [transformOrigin.horizontal, transformOrigin.vertical]
    .map(n => (typeof n === 'number' ? `${n}px` : n))
    .join(' ');
}

/**
 * A wrapper around the `Popper` component that makes it resemble the `Popover` and `Menu`
 * components, but also allowing the elements behind the `Popper` be clickable, which `Popover` and
 * `Menu` components do not.
 *
 * This component is also extended with a `transformOrigin` property from the `Popover`/`Menu` APIs
 * to allow setting the origin of the transitions.
 */
export default function BewPopper({
  transformOrigin = { vertical: 'top', horizontal: 'right' },
  elevation = 4,
  backgroundColor,
  ...props
}) {
  const paperRef = useRef(null);
  const setPaperStyle = useCallback(() => {
    const element = paperRef.current;

    if (!element) {
      return;
    }

    element.style.transformOrigin = getTransformOriginValue(transformOrigin);
  }, []);

  return (
    <StyledPopper {...props}>
      <ClickAwayListener onClickAway={props.onClose}>
        <Grow appear in={props.open} onEntering={setPaperStyle}>
          <Paper ref={paperRef} elevation={elevation} sx={{ backgroundColor }}>
            {props.children}
          </Paper>
        </Grow>
      </ClickAwayListener>
    </StyledPopper>
  );
}
