import axios from 'axios';
import { API_URL } from '../constants';

export const getSettings = async () => {
  const url = `${API_URL}/settings`;
  const { data } = await axios.get(url);

  return data;
};
getSettings.QUERY_KEY = 'getSettings';
