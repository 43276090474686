import React from 'react';
import styled from '@emotion/styled';
import { Card, Chip, Grid, Typography } from '@mui/material';

const StyledChip = styled(Chip)`
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  border: none;
  color: ${({ theme }) => theme.palette.tertiary.light};
  margin-left: ${({ theme }) => theme.spacing(2)};
`;

export default function CertificationProgressGuidance({ certification }) {
  const partnerSinceYear = certification?.partner?.createdAt.slice(0, 4);

  return (
    <Card sx={{ p: 2 }}>
      <Grid container direction='row' wrap='nowrap' justifyContent='space-between'>
        <Grid item>
          <Typography variant="h1">
            {certification?.partner?.name}
          </Typography>
        </Grid>
        <Grid item display="flex" alignItems="center" mr={2}>
          <StyledChip
            variant='outlined'
            label={`Partner since ${partnerSinceYear}`}
          />
        </Grid>
      </Grid>
    </Card>
  );
}
