import React from 'react';
import { Card, CardHeader as MuiCardHeader, CardContent } from '@mui/material';
import styled from '@emotion/styled';
import ResourceItem from './ResourceItem';
import SupplierIOResource from '../resource/SupplierIOResource';

const StyledCardContent = styled(CardContent, {
  shouldForwardProp: prop => prop !== 'isPillarResourceList'
})`
  display: flex;
  flex-direction: column;
  padding: ${({ theme, isPillarResourceList }) => isPillarResourceList ? theme.spacing(2, 0, 0, 0) : theme.spacing(2)};

  &:last-child {
    padding-bottom: ${({ theme, isPillarResourceList }) => isPillarResourceList ? theme.spacing(0) : theme.spacing(2)};
  }
`;

const StyledCard = styled(Card, {
  shouldForwardProp: prop => prop !== 'isPillarResourceList'
})`
  width: 100%;
  box-shadow: ${({ isPillarResourceList }) => (isPillarResourceList ? 'none' : 'default')};
`;

const StyledCardHeader = styled(MuiCardHeader, {
  shouldForwardProp: prop => prop !== 'isPillarResourceList'
})`
  padding: ${({ theme, isPillarResourceList }) => isPillarResourceList ? theme.spacing(0) : theme.spacing(2, 2, 0, 2)};
`;

const TARGET = {
  BLANK: '_blank',
  SELF: '_self'
};

const getTarget = key => TARGET[key] || TARGET.BLANK;

const titleTypographyPropsMap = new Map([
  ['Resources', { variant: 'modalHeadline' }],
  ['Key Resources', { variant: 'subtitle2' }],
  ['Supplier.io', { variant: 'subtitle2' }]
]);

function ResourceList({ title, keyResources = [], pillarResources = [], isSupplierIo = false }) {
  const isPillarResourceList = pillarResources.length > 0;

  const titleTypographyProps = titleTypographyPropsMap.get(title);
  const resourceList = isPillarResourceList ? pillarResources : keyResources;

  return (
    <StyledCard isPillarResourceList={isPillarResourceList}>
      <StyledCardHeader
        title={title}
        titleTypographyProps={titleTypographyProps}
        isPillarResourceList={isPillarResourceList}
      />
      <StyledCardContent isPillarResourceList={isPillarResourceList}>
        {
          isSupplierIo ? (
            <SupplierIOResource />
          ) : (
            resourceList.map(({ title, href, link, target, type }, idx) => (
              <ResourceItem
                key={idx}
                title={title}
                href={href || link}
                target={getTarget(target)}
                type={type}
              />
            ))
          )
        }
      </StyledCardContent>
    </StyledCard>
  );
}

export default ResourceList;
