import { useEffect, useRef, useState } from 'react';
import { debounce, find } from 'lodash';

// Matches 10 frames at 60 Hz, default wait value in MUI's debounce implementation
const RESIZE_HANDLER_WAIT = 166;
const SCROLLER_CLASS_NAME = 'MuiTabs-scroller';
const FLEX_CONTAINER_CLASS_NAME = 'MuiTabs-flexContainer';

const findChild = (element, className) => {
  if (!element || !className) {
    return null;
  }

  return find(element.children, child => child.classList.contains(className));
};

/**
 * Detects whether to hide tabs scroll buttons by comparing the actual width (scroll width) of all
 * tab elements against the display width (client width) of tabs root container. This hook is useful
 * to handle a case when the tabs root container is not large enough to accommodate both scroll buttons
 * and tab elements without overflow. Initially tabs root container can be large enough to accommodate
 * tab elements, but if the user starts to resize the window and scroll buttons are displayed there is
 * no way to hide them once the window is restored to its full size due to the previously mentioned case.
 * @return {Array} An array with two elements, first is reference that must be assigned to the MUI `Tabs`,
 * and other is flag denoting whether scroll buttons can be hidden.
 */
function useTabsScrollButtonDisplay() {
  const tabsRef = useRef(null);
  const [canHideScrollButtons, setCanHideScrollButtons] = useState(false);

  useEffect(() => {
    const handleResize = debounce(() => {
      const scrollerEl = findChild(tabsRef.current, SCROLLER_CLASS_NAME);
      const flexContainerEl = findChild(scrollerEl, FLEX_CONTAINER_CLASS_NAME);

      // 1 is added to compensate for potential rounding errors with browser zooms.
      setCanHideScrollButtons(flexContainerEl?.scrollWidth < tabsRef.current.clientWidth + 1);
    }, RESIZE_HANDLER_WAIT);

    window.addEventListener('resize', handleResize);

    return () => {
      handleResize.cancel();
      window.removeEventListener('resize', handleResize);
    };
  }, [tabsRef.current]);

  return [tabsRef, canHideScrollButtons];
}

export default useTabsScrollButtonDisplay;
