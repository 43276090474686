import React, { forwardRef } from 'react';
import { Box, Container, Skeleton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Helmet } from 'react-helmet';
import times from 'lodash/times';
import Typography from 'src/components/common/Typography';

const useStyles = makeStyles(({ breakpoints }) => ({
  title: {
    fontWeight: 'bold',
    fontSize: 24,
    [breakpoints.up('md')]: {
      fontSize: 56
    }
  }
}));

const PageTitle = ({ children }) => {
  const classes = useStyles();

  return (
    <Typography
      component="h1"
      className={classes.title}
    >
      {children}
    </Typography>
  );
};

const PageProgress = props => {
  return (
    <Container maxWidth="lg">
      <Box display="flex" flexDirection="column" py={6.5} px={3.5} {...props}>
        <Box mb={3}><Skeleton variant="rectangular" height={16} width={40} /></Box>
        <Box mb={8}><Skeleton variant="rectangular" height={48} width={300} /></Box>
        {times(3).map(id => (
          <Box key={id} mb={2}>
            <Skeleton height={100} variant="rectangular" />
          </Box>
        ))}
      </Box>
    </Container>
  );
};

const Page = forwardRef(function Page({ children, title = '', ...rest }, ref) {
  return (
    <div ref={ref} {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
});

export { PageTitle, PageProgress };
export default Page;
