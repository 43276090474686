import React, { useEffect } from 'react';
import useStore from 'src/next/store';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import _ from 'lodash';
import { ASSIGNMENT_TYPES } from 'src/constants';
import Page from '../../components/Page';
import useCertificationAssignmentType from '../certification/useCertificationAssignmentType';
import CertificationStatusElement from '../certification/CertificationStatusElement';
import assessmentType from '../assessment/assessmentType';
import ContactPsTeamMembersWidget from '../certification/ContactPsTeamMembersWidget';
import { PeriodsConfig } from '../certification/periodsConfig';

const { PSA, PSM } = ASSIGNMENT_TYPES;
const CERTIFICATION_BY_ASSESSMENT_TYPE_ENTRIES = Object.keys(assessmentType)
  .map(type => [type, { certificationSteps: [], assessment: { type }, certificationAssignments: [] }]);

export default function MyProgramsMain() {
  const certificationAssignmentType = useCertificationAssignmentType();
  const isPsTeamMember = [PSA, PSM].includes(certificationAssignmentType);
  const { partners, getPartners } = useStore(state => state.partner);
  const { assessmentRanges, currentDate } = useStore(state => state.settings);
  const certifications = partners.flatMap(p => p.certifications);
  const periodsConfig = new PeriodsConfig(assessmentRanges, currentDate);
  const periods = periodsConfig.getPeriods();

  // Add assessment type to user assignment so we can display it on My Programs page
  certifications.forEach(certification => certification.certificationAssignments.forEach(assignment => {
    assignment.user.assessmentType = certification.assessmentType.title;
  }));

  /*
  NOTE: If there's multiple certifications of the same type, they will be overwritten, but it should not happen.
  If we ever need to cover that use case, we'll have to create multiple empty certifications, i.e. if there are 3
  BEW and 1 HEW in the database, we should create additional 2 HEW empty certifications
  */
  const certificationsByType = certifications.reduce(
    (acc, c) => acc.set(c.assessmentType.title, c),
    new Map(CERTIFICATION_BY_ASSESSMENT_TYPE_ENTRIES)
  );

  const psTeamMembers = _([...certificationsByType.values()])
    .flatMap(c => c.certificationAssignments)
    .filter(ca => [ASSIGNMENT_TYPES.PSM, ASSIGNMENT_TYPES.PSA].includes(ca.type))
    .map(ca => ({ ...ca.user, certificationAssignments: [ca] }))
    .uniqBy('id')
    .value();

  // If there is only one psm we don't want to show assessment type
  if (psTeamMembers.length === 1) {
    delete psTeamMembers[0].assessmentType;
  }

  useEffect(() => {
    getPartners();
  }, []);

  return (
    <Page title='MLT Racial Equity at Work Online Portal'>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          container
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography variant='h1' mb={2}>
            My Programs
          </Typography>
        </Grid>
        <Grid
          item
          xs={8}
        >
          {[...certificationsByType.entries()].map(([type, certification], index) => (
            <Box mb={2} key={index}>
              {certification.id && (
                <CertificationStatusElement
                  certification={certification}
                  isAdmin={false}
                  isPsTeamMember={isPsTeamMember}
                  periods={periods}
                  currentDate={currentDate} />
              )}
            </Box>
          ))}
        </Grid>
        {/* TODO: Uncomment after implementing */}
        <Grid container item xs={4}>
          <Grid item xs={12}>
            <ContactPsTeamMembersWidget psTeamMembers={psTeamMembers} />
          </Grid>
          {/* <Grid
            item
            xs={12}
          >
            announcements
          </Grid> */}
        </Grid>
      </Grid>
    </Page>
  );
}
