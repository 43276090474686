import React from 'react';
import styled from '@emotion/styled';
import { Breadcrumbs, Grid, Link, Typography } from '@mui/material';
import { ChevronRight } from 'react-feather';
import SingleSideSkew from 'src/next/mui/SingleSideSkew';
import useBreadcrumbs from './useBreadcrumbs';
import useAssessmentType from '../assessment/useAssessmentType';
import AssessmentLogo from '../assessment/AssessmentLogo';

const GridContainer = styled(props => (<Grid container {...props} />))`
  height: 54px;
  box-shadow: ${({ theme }) => theme.shadows[3]};
`;

const ChevronRightIcon = styled(ChevronRight)`
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  color: ${props => props.theme.palette.text.secondary};
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding: ${({ theme }) => theme.spacing(0, 5)};

  background-color: ${({ theme }) => theme.palette.breadcrumbs};

  // NOTE(marko): This is a bit of a convoluted selector to select the last "separator" between
  // breadcrumbs, since material-ui uses the "li" element for both the breadcrumbs and the
  // separators between them.
  //
  // Ideally, we would use a ":nth-last-child(1 of .MuiBreadcrumbs-separator)" selector so it's more
  // clear what is being selected, but alas, this syntax is only supported in Safari atm.
  li:nth-last-of-type(2) ${ChevronRightIcon} {
    color: ${props => props.theme.palette.text.primary};
  }
`;

const BreadcrumbLink = styled(Link)`
  text-decoration: underline;
  color: ${props => props.theme.palette.text.secondary};
`;

export default function BewBreadcrumbs(props) {
  const breadcrumbs = useBreadcrumbs();
  const assessmentType = useAssessmentType();

  return (
    <GridContainer>
      {
        assessmentType && (
          <Grid item xs="auto" container alignItems="center">
            <SingleSideSkew width="129px" pl={3} backgroundColor="primary.main">
              <AssessmentLogo type={assessmentType} width={82} height={41} />
            </SingleSideSkew>
          </Grid>
        )
      }
      <Grid item xs={true}>
        <StyledBreadcrumbs separator={<ChevronRightIcon />}>
          {breadcrumbs.map(([route, name], index) => {
            const last = index === breadcrumbs.length - 1;

            return last ? (
              <Typography variant="body4" key={route}>
                {name}
              </Typography>
            ) : (
              <BreadcrumbLink variant="body4" to={route} key={route}>
                {name}
              </BreadcrumbLink>
            );
          })}
        </StyledBreadcrumbs>
      </Grid>
    </GridContainer>
  );
}
