import axios from 'axios';
import { API_URL } from '../../constants';

const GROUP_COMMENTS_URL = `${API_URL}/comments/groups`;

const api = {
  getGroupComments(params) {
    return axios.get(GROUP_COMMENTS_URL, { params });
  },

  creatGroupComment(payload) {
    return axios.post(GROUP_COMMENTS_URL, payload);
  }
};

export default (set, get) => ({
  groupComments: [],

  async getGroupComments({ certificationStepId, questionGroupId }) {
    const data = await api.getGroupComments({ certificationStepId, questionGroupId });

    set(state => ({ groupComments: data }), 'GET_GROUP_COMMENTS');
  },

  resetGroupComments() {
    set(state => ({ groupCommnets: [] }), 'RESET_GROUP_COMMENTS');
  },

  async creatGroupComment(payload) {
    const data = await api.creatGroupComment(payload);
    const { groupComments } = get();

    set(state => ({ groupComments: [...groupComments, data] }), 'CREATE_GROUP_COMMENTS');
  }
});
