import React, { useState } from 'react';
import { Box, Card, Typography, Tabs, Tab, Divider } from '@mui/material';
import styled from '@emotion/styled';
import ResourceList from '../resource/ResourceList';

const StyledTabs = styled(Tabs)`
  background-color: ${({ theme }) => theme.palette.primary.dark};
  box-shadow: ${({ theme }) => theme.shadows[3]};

  .MuiTabs-indicator {
    bottom: 10px;
    border: 2px solid #fff
  }

  .MuiTabs-flexContainer {
    height: 72px;
    margin-left: 10px;
  }
`;

const StyledTab = styled(({ name, ...props }) => (
  <Tab
    id={`${name}-tab`}
    aria-controls={`${name}-tabpanel`}
    label={<Typography variant="h4" color="#fff" component="span">{name}</Typography>}
    {...props}
  />))`
  text-transform: capitalize;

  &.MuiButtonBase-root {
    & .MuiTypography-root {
      color: ${({ theme }) => theme.palette.primary.lighter};
    }
  }

  &.MuiButtonBase-root.Mui-selected {
    opacity: 1;

    & .MuiTypography-root {
      color: #FFF;
    }
  }
`;

const Content = styled.div`
  padding: ${({ theme }) => theme.spacing(3)};
`;

const ScoringBox = styled(Box)`
  & > * {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }

  & > :last-child {
    margin-bottom: 0;
  }

  table {
    width: 100%;
    background-color: ${({ theme }) => theme.palette.background.default};
    border: solid transparent;
    border-width: 0 4px;
    border-radius: 4px;
    border-collapse: collapse;
    box-shadow: ${({ theme }) => theme.shadows[1]};
  }

  tr > th, td {
    &:last-child {
      width: 37%;
    }
  }

  tr {
    border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.lighter};

    &:last-child {
      border-bottom: none;
    }
  }

  th {
    padding: ${({ theme }) => theme.spacing(2, 2)};
    border-bottom: 1px solid ${({ theme }) => theme.palette.tertiary.lighter};
    text-align: left;
    font-size: ${({ theme }) => theme.typography.subtitle3.fontSize};
    font-weight: ${({ theme }) => theme.typography.subtitle3.fontWeight};
    line-height: ${({ theme }) => theme.typography.subtitle3.lineHeight};
  }

  td {
    padding: ${({ theme }) => theme.spacing(1, 2)};
    font-size: ${({ theme }) => theme.typography.body2.fontSize};
    font-weight: ${({ theme }) => theme.typography.body2.fontWeight};
    line-height: ${({ theme }) => theme.typography.body2.lineHeight};
  }
`;

const GuidanceBox = styled(Box)`
  & > * {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }

  & > :last-child {
    margin-bottom: 0;
  }

  li > ul {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }

  ul {
    padding-left: ${({ theme }) => theme.spacing(2)};

    & > li {
      margin-bottom: ${({ theme }) => theme.spacing(1)};
    }

    & > :last-child {
      margin-bottom: 0;
    }
  }
`;

function TabPanel({ children, value, index, name, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${name}-tabpanel`}
      aria-labelledby={`${name}-tab`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function QuestionGroupMetadata({ questionGroup, position }) {
  const [currentTab, setCurrentTab] = useState(0);
  const { scoring, scoringYear4Plus, guidance, guidanceYear4Plus, resources } = questionGroup?.metadata ?? {};

  const handleTabChange = (_event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Card>
      <StyledTabs
        value={currentTab}
        onChange={handleTabChange}
        aria-label="scoring guidance tabs">
        <StyledTab name="guidance" />
        <StyledTab name="scoring" />
      </StyledTabs>
      <Content>
        <TabPanel value={currentTab} index={0} name="guidance">
          <GuidanceBox dangerouslySetInnerHTML={{ __html: (guidanceYear4Plus && position > 3) ? guidanceYear4Plus : guidance }} />
          {
            resources?.length &&
            <>
              <Divider sx={{ my: 2 }} />
              <ResourceList title='Resources' pillarResources={resources}/>
            </>
          }
        </TabPanel>
        <TabPanel value={currentTab} index={1} name="scoring">
          <ScoringBox dangerouslySetInnerHTML={{ __html: (scoringYear4Plus && position > 3) ? scoringYear4Plus : scoring }} />
        </TabPanel>
      </Content>
    </Card>
  );
}
