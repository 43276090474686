import axios from 'axios';
import { API_URL } from '../constants';

export const routes = {
  certifications: `${API_URL}/certifications`,
  certification: id => `${API_URL}/certifications/${id}`,
  submitCertification: id => `${API_URL}/certifications/${id}/submit`,
  reviewCertification: id => `${API_URL}/certifications/${id}/review`,
  baselineComments: `${API_URL}/comments`,
  certificationTasks: certificationId => {
    return `${API_URL}/certifications/${certificationId}/tasks`;
  },
  certificationTask: (certificationId, taskId) => {
    return `${API_URL}/certifications/${certificationId}/tasks/${taskId}`;
  }
};

export async function getCertifications(params = {}) {
  const url = routes.certifications;
  const { data } = await axios.get(url, { params });
  return data;
}
getCertifications.QUERY_KEY = 'getCertifications';

export async function getCertification(id) {
  const url = routes.certification(id);
  const response = await axios.get(url);
  return response.data;
}
getCertification.QUERY_KEY = 'getCertification';

export async function submitCertification(id) {
  const url = routes.submitCertification(id);
  return axios.put(url);
}

export async function reviewCertification(id, isApproved, certificationStep) {
  const url = routes.reviewCertification(id);
  return axios.put(url, { isApproved, certificationStep });
}

export async function updateCertificationBaselineAnswers(certificationId, baselineId, answers) {
  const url = routes.baselineAnswers(certificationId, baselineId);
  const response = await axios.put(url, answers[0]);
  return response.data[0];
}

// TODO: Fix createComment method, comments are no longer under the certification router
export async function createComment(createCommentDTO) {
  const { certificationId, baselineId } = createCommentDTO.location;
  const url = routes.baselineComments(certificationId, baselineId);
  const response = await axios.post(url, createCommentDTO);
  return response.data;
}

// TODO: Fix this method, tasks are action-items now
export async function getCertificationTasks(certificationId) {
  const url = routes.certificationTasks(certificationId);
  const response = await axios.get(url);
  return response.data;
}
getCertificationTasks.QUERY_KEY = 'getCertificationTasks';

export async function createTask(certificationId, createTaskDTO) {
  const url = routes.certificationTasks(certificationId);
  const response = await axios.post(url, createTaskDTO);
  return response.data;
}

export async function updateTask(certificationId, taskId, updateTaskDTO) {
  const url = routes.certificationTask(certificationId, taskId);
  const response = await axios.patch(url, updateTaskDTO);
  return response.data;
}

export async function deleteTask(certificationId, taskId) {
  const url = routes.certificationTask(certificationId, taskId);
  const response = await axios.delete(url);
  return response.data;
}
