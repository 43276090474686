import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

function Link({ to, ...props }, ref) {
  return (
    to
      ? <RouterLink ref={ref} to={to} {...props} />
      : <a ref={ref} {...props} />
  );
}

export default React.forwardRef(Link);
