import React from 'react';
import styled from '@emotion/styled';
import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { get } from 'lodash';
import { AlertCircle as AlertCircleIcon } from 'react-feather';
import { generatePath } from 'react-router-dom';
import routes from '../navigation/routes';

const INDICATOR_COLUMN_WIDTH = 420;

const StyledAlertCircleIcon = styled(AlertCircleIcon)`
  min-width: 24px;
  color: #FFF;
  fill: ${({ theme }) => theme.palette.error.main};
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

const StyledTableRow = styled(TableRow)`
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  height: 60px;
`;

const BaseCell = styled(({
  backgroundColor,
  children,
  className,
  color,
  key,
  ...props
}) => (
  <TableCell key={key} className={className}>
    <Box display="flex" alignItems="center" justifyContent="center" {...props}>
      {children}
    </Box>
  </TableCell>
))`
  background-color: ${({ theme, backgroundColor }) => get(theme.palette, backgroundColor, backgroundColor)};
  color: ${({ theme, color }) => get(theme.palette, color, color)};
`;

const HeadCell = styled(({ children, ...props }) => (
  <BaseCell {...props}>
    <Typography variant="subtitle3" noWrap>
      {children}
    </Typography>
  </BaseCell>
))`
  text-transform: uppercase;
`;

const BodyCell = ({ children, ...props }) => (
  <BaseCell {...props}>
    <Typography variant="link2">
      {children}
    </Typography>
  </BaseCell>
);

function EmptyHeadCells({ pillarScoreContainerWidth }) {
  return (
    <TableCell width={pillarScoreContainerWidth}>
      {/* empty cell */}
    </TableCell>
  );
}

function EmptyBodyCells() {
  return (
    <TableCell>
      {/* empty cell */}
    </TableCell>
  );
}

function CurrentHeadCells({ pillarScoreContainerWidth }) {
  return <EmptyHeadCells pillarScoreContainerWidth={pillarScoreContainerWidth} />;
}

function CurrentBodyCells() {
  return <EmptyBodyCells />;
}

function HeadRowOutline({ children, showScoreColumn }) {
  return (
    <TableRow>
      <HeadCell
        justifyContent="left"
        width={INDICATOR_COLUMN_WIDTH}
      >
        INDICATOR
      </HeadCell>
      {
        showScoreColumn &&
        <HeadCell>
          POINTS AWARDED
        </HeadCell>
      }
      {children}
    </TableRow>
  );
}

function BodyRowOutline({
  children,
  indicator,
  score,
  order,
  certificationStep,
  isCompleted,
  showCompletionAlerts,
  hasFeedback,
  userRole,
  showScoreColumn,
  ...props
}) {
  return (
    <StyledTableRow {...props}>
      <BaseCell
        justifyContent="left"
        width={INDICATOR_COLUMN_WIDTH}
      >
        <Typography variant="link2">
          {indicator.isOrdered && `${order}. `}

          <StyledLink to={generatePath(routes.CERTIFICATION_INDICATOR, {
            certificationId: certificationStep.certification.id,
            certificationStepId: certificationStep.id,
            pillarId: indicator.pillar.id ?? indicator.pillar,
            indicatorId: indicator.id
          })}
          onClick={() => window.scrollTo({ top: 220, behavior: 'auto' })}
          >
            {indicator.name}
          </StyledLink>
        </Typography>
        &nbsp;
        {
          (showCompletionAlerts && !isCompleted) &&
            <Box ml={1} display="inline-flex">
              <StyledAlertCircleIcon />
            </Box>
        }
      </BaseCell>
      { showScoreColumn &&
        <BodyCell>
          {score}
        </BodyCell>
      }
      {children}
    </StyledTableRow>
  );
}

export default function CertificationStepSummaryIndicatorList({
  indicators = [],
  scoreById,
  completionById,
  globalIndexById,
  certificationStep,
  showCompletionAlerts,
  userRole,
  pillarScoreContainerWidth,
  showScoreColumn
}) {
  return (
    <Box sx={{ px: 2 }}>
      <Table>
        <TableHead>
          <HeadRowOutline showScoreColumn={showScoreColumn} userRole={userRole}>
            <CurrentHeadCells pillarScoreContainerWidth={pillarScoreContainerWidth}
            />
          </HeadRowOutline>
        </TableHead>
        <TableBody>
          {
            indicators.map(i => {
              const score = scoreById[i.id] ?? 0;
              const order = i.isOrdered
                ? globalIndexById.get(i.id) + 1
                : null;
              const completion = completionById[i.id] ?? {};
              const isCompleted = completion.total === completion.completed;

              return (
                <BodyRowOutline
                  key={i.id}
                  indicator={i}
                  score={score}
                  order={order}
                  certificationStep={certificationStep}
                  isCompleted={isCompleted}
                  showCompletionAlerts={showCompletionAlerts}
                  userRole={userRole}
                  showScoreColumn={showScoreColumn}
                >
                  <CurrentBodyCells />
                </BodyRowOutline>
              );
            })
          }
        </TableBody>
      </Table>
    </Box>
  );
}
