import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import useStore from 'src/store';
import { PageProgress } from 'src/components/Page';
import {
  useDisposeCertificationOnLeave,
  useDisposePartnerOnLeave,
  useLoadCertificationOnEnter,
  useSetPartnerOnEnter
} from 'src/hooks/storeNavigation';
import { ROUTES } from './routes';
import Baseline from './Baseline';
import { selectPartners } from './store/partners/selectors';

const Dashboard = React.lazy(() => import('./Dashboard'));
const PartnerOverview = React.lazy(() => import('./PartnerOverview'));
const Settings = React.lazy(() => import('./Settings'));
const ManageUsers = React.lazy(() => import('./Settings/ManageUsers'));

function Router() {
  const partners = useStore(selectPartners);
  useLoadCertificationOnEnter(ROUTES.CERTIFICATION_BASELINE);
  useSetPartnerOnEnter(partners, ROUTES.PARTNER_OVERVIEW);
  useDisposeCertificationOnLeave([ROUTES.CERTIFICATION_BASELINE]);
  useDisposePartnerOnLeave([ROUTES.PARTNER_OVERVIEW, ROUTES.CERTIFICATION_BASELINE]);

  return (
    <Suspense fallback={<PageProgress />}>
      <Switch>
        <Route path={ROUTES.DASHBOARD} component={Dashboard} />
        <Route path={ROUTES.PARTNER_OVERVIEW} component={PartnerOverview} />
        <Route path={ROUTES.CERTIFICATION_BASELINE} component={Baseline} />
        <Route path={ROUTES.SETTINGS_MANAGE_USERS} component={ManageUsers} />
        <Route path={ROUTES.SETTINGS} component={Settings} />
        <Redirect from="/" to={ROUTES.NEXT} />
      </Switch>
    </Suspense>
  );
}

export default Router;
