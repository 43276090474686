import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  List,
  ListItem,
  CardActionArea
} from '@mui/material';
import styled from '@emotion/styled';
import React from 'react';
import routes from 'src/next/navigation/routes';
import { ArrowRight } from 'react-feather';
import { orderBy } from 'lodash';
import { generatePath } from 'react-router-dom';
import CertificationEmployeeChip from './CertificationEmployeeChip';

const ChipContainer = styled(Box)`
  width: 90px;
  text-align: center;
`;
const ManageUsersText = styled(Typography)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`;
const StyledActionArea = styled(CardActionArea)`
  background-color: #FAFAFA;
  display: flex;
  justify-content: flex-end;
`;

function CertificationEmployeeListItem({ employee }) {
  return (
    <>
      <Divider variant="middle" />
      <ListItem sx={{ p: 2, justifyContent: 'space-between' }}>
        <Typography variant="body4">
          {`${employee.first} ${employee.last}`}
        </Typography>
        <ChipContainer>
          <CertificationEmployeeChip isPrimary={employee.isPrimary} />
        </ChipContainer>
      </ListItem>
    </>
  );
}

export default function CertificationEmployeeList({ employees = [], certificationId }) {
  const employeesOrdered = orderBy(employees, 'isPrimary', 'desc');

  return (
    <Card>
      <CardHeader
        title="Users"
        titleTypographyProps={{ variant: 'subtitle2' }}
      />
      <CardContent sx={{ p: 0 }}>
        <List sx={{ p: 0 }}>
          {employeesOrdered.map(u => (
            <CertificationEmployeeListItem
              employee={u}
              key={u.id}
            />
          ))}
        </List>
      </CardContent>
      <Divider />
      <StyledActionArea
        sx={{ p: 2 }}
        to={generatePath(routes.CERTIFICATION_USERS, { certificationId })}
      >
        <ManageUsersText
          variant="body4"
          color="primary"
        >
          MANAGE USERS &nbsp; <ArrowRight />
        </ManageUsersText>
      </StyledActionArea>
    </Card>
  );
}
