import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Divider, Drawer, Typography, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ROUTES } from 'src/router';
import Logo from 'src/components/Logo';
import { useLogout, useAccount } from 'src/store/auth/hooks';
import { ROLES } from 'src/constants';

const useStyles = makeStyles(theme => ({
  mobileDrawer: {
    width: 288,
    top: 64,
    borderRadius: 0
  },
  desktopDrawer: {
    width: 288,
    top: 64,
    height: 'calc(100% - 64px)',
    borderRadius: 0
  },
  logo: {
    cursor: 'pointer'
  },
  title: {
    fontWeight: theme.typography.fontWeightBold
  },
  contentSection: {
    height: `calc(100% - 45px - ${theme.spacing(2)})`,
    overflowY: 'auto'
  },
  bottomSection: {
    position: 'fixed',
    top: 'auto',
    bottom: 0,
    width: 288,
    left: 0,
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: 'white'
  },
  securityInfoButton: {
    margin: '0 auto 16px auto',
    width: 224,
    display: 'block'
  },
  card: {
    margin: '0 auto 16px auto',
    width: 224,
    backgroundColor: theme.palette.grey[100]
  },
  copyright: {
    color: theme.palette.grey[500],
    fontSize: 11,
    textAlign: 'center',
    padding: 16
  },
  help: {
    fontSize: 14,
    color: theme.palette.grey[800]
  },
  link: {
    textDecoration: 'none',
    fontSize: 12,
    '&:hover': {
      textDecoration: 'none'
    }
  },
  logoutBtn: {
    color: '#ED6B59',
    textTransform: 'none',
    textDecoration: 'underline',
    justifyContent: 'left',
    paddingLeft: 0
  }
}));

function UserInfo({ organization, ...props }) {
  const account = useAccount();
  const classes = useStyles();
  const logout = useLogout();

  return (
    <Box {...props}>
      <Box
        display="flex"
        mb={2}
        flexDirection="column"
        justifyContent="left"
      >
        <RouterLink to={ROUTES.HOME}>
          <Logo src={organization?.logo} height={60} className={classes.logo} />
        </RouterLink>
      </Box>
      <Typography className={classes.title} to="#" variant="h5" color="textPrimary" underline="none">
        {account.role === ROLES.EMPLOYEE ? organization?.name : 'MLT'}
      </Typography>
      <Typography variant="subtitle1" color="textPrimary">
        {account.first} {account.last}
      </Typography>
      <Button className={classes.logoutBtn} onClick={logout} aria-label="logout">
        <Typography variant="subtitle1">Log Out</Typography>
      </Button>
    </Box>
  );
}

function NavBar({
  organization,
  children,
  onMobileClose,
  openMobile
}) {
  const classes = useStyles();

  const content = (
    <>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        id="nav-bar"
        className={classes.contentSection}>
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <UserInfo organization={organization} p={4} />
          <Divider />
          <Box mb={{ xs: 8, lg: 0 }}>
            {children}
          </Box>
        </PerfectScrollbar>
      </Box>
      <Box className={classes.bottomSection}>
        <Divider />
        <p className={classes.copyright}>&copy; Copyright MLT 2021</p>
      </Box>
    </>
  );

  return <>
    <Drawer
      anchor="left"
      sx={{ display: 'block', visibility: { xs: 'visible', lg: 'hidden' } }}
      classes={{ paper: classes.mobileDrawer }}
      onClose={onMobileClose}
      open={openMobile}
      variant="temporary">
      {content}
    </Drawer>
    <Drawer
      anchor="left"
      sx={{ display: 'block', visibility: { xs: 'hidden', lg: 'visible' } }}
      classes={{ paper: classes.desktopDrawer }}
      variant="persistent"
      open>
      {content}
    </Drawer>
  </>;
}

export default NavBar;
