import React, { useEffect, useState } from 'react';
import { Typography, Dialog, Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import useStore from 'src/next/store';
import _, { groupBy, curry } from 'lodash';
import styled from '@emotion/styled';
import ConfirmationDialog from 'src/next/mui/ConfirmationDialog';
import Snackbar from 'src/next/mui/Snackbar';
import useLoading from 'src/next/util/useLoading';
import useDialogState from 'src/next/util/useDialogState';
import { ROLES } from 'src/constants';
import UserList from './UserList';
import UserEdit from './UserEdit';
import wrapPromise from '../util/wrapPromise';
import useSnackbar from '../util/useSnackbar';

const PartnerName = styled(Typography)`
  font-size: 32px;
  font-weight: 700;
  color: ${props => props.theme.palette.tertiary.dark};
`;

export default function CertificationUserMain() {
  const { certificationId } = useParams();
  const {
    getCertificationById,
    certifications
  } = useStore(state => state.certification);
  const { user: loggedInUser } = useStore(state => state.auth);
  const {
    users,
    updateLoggedInUser,
    enable,
    disable,
    getEmployees,
    addEmployee,
    updateEmployee,
    promoteEmployee
  } = useStore(state => state.user);
  const [currentUser, setCurrentUser] = useState(null);
  const [snackbarData, openSnackbar] = useSnackbar();
  const [isCreateOpen, openCreate, closeCreate] = useDialogState(setCurrentUser);
  const [isEditOpen, openEdit, closeEdit] = useDialogState(setCurrentUser);
  const [isPromoteOpen, openPromote, closePromote] = useDialogState(setCurrentUser);
  const [isDisableOpen, openDisable, closeDisable] = useDialogState(setCurrentUser);
  const [isEnableOpen, openEnable, closeEnable] = useDialogState(setCurrentUser);

  const openSuccessSnackbar = () => openSnackbar('Changes Saved', 'success');
  const openErrorSnackbar = () => openSnackbar('An error occured', 'error');
  const withSnackbar = curry(wrapPromise)(_, openSuccessSnackbar, openErrorSnackbar);

  const [createUser, isCreateLoading] = useLoading(withSnackbar(async data => {
    await addEmployee(certificationId, data);
  }, closeCreate));
  const [editUser, isEditLoading] = useLoading(withSnackbar(data => (
    loggedInUser.id === currentUser.id
      ? updateLoggedInUser(data)
      : updateEmployee(certificationId, currentUser.id, data)
  ), closeEdit));
  const [promoteUser, isPromoteLoading] = useLoading(withSnackbar(async () => {
    await promoteEmployee(certificationId, currentUser.id);
  }, closePromote));
  const [enableUser, isEnableLoading] = useLoading(withSnackbar(async () => {
    await enable(currentUser.id);
  }, closeEnable));
  const [disableUser, isDisableLoading] = useLoading(withSnackbar(async () => {
    await disable(currentUser.id);
  }, closeDisable));
  const openEnabledToggle = user => user.enabled ? openDisable(user) : openEnable(user);

  useEffect(() => {
    getCertificationById(certificationId);
    getEmployees(certificationId);
  }, [certificationId, getEmployees]);

  const currentCertification = certifications.find(c => c.id === +certificationId);
  const { true: primaryEmployees = [], false: secondaryEmployees = [] } = groupBy(users, 'isPrimary');
  const currentFullName = `${currentUser?.first} ${currentUser?.last}`;
  const primaryFullName = `${primaryEmployees[0]?.first} ${primaryEmployees[0]?.last}`;
  const loggedInEmployee = users.find(e => e.id === loggedInUser.id);
  const isPrimary = loggedInEmployee?.isPrimary;
  const isSecondary = loggedInEmployee?.isPrimary === false;
  const isAdmin = loggedInUser?.role === ROLES.ADMIN;
  const isStaff = loggedInUser?.role === ROLES.STAFF;

  return (
    <>
      <Typography variant='h1'>
        User Management
      </Typography>
      <PartnerName mb={4}>
        {currentCertification?.partner?.name}
      </PartnerName>
      <Box mb={2}>
        <UserList
          title='Primary User'
          users={primaryEmployees}
          currentUser={loggedInUser}
          onEdit={openEdit}
          onToggleEnabled={openEnabledToggle}
          isCreateHidden
          isPromoteHidden
          isEditHidden={isSecondary}
          isToggleEnabledHidden={isPrimary || isSecondary}
        />
      </Box>
      <UserList
        title='Secondary Users'
        users={secondaryEmployees}
        currentUser={loggedInUser}
        onCreate={openCreate}
        onEdit={openEdit}
        onPromote={openPromote}
        onToggleEnabled={openEnabledToggle}
        isCreateHidden={!isAdmin && !isStaff && !isPrimary}
        isPromoteHidden={isSecondary}
        isEditHidden={isSecondary}
        isToggleEnabledHidden={isSecondary}
      />

      <Dialog
        open={isCreateOpen}
        onClose={closeCreate}
        maxWidth='lg'
        fullWidth
      >
        <UserEdit
          title='Add Secondary User'
          note='ALL FIELDS REQUIRED'
          isLoading={isCreateLoading}
          onSave={createUser}
          onClose={closeCreate}
        />
      </Dialog>

      <Dialog
        open={isEditOpen}
        onClose={closeEdit}
        maxWidth='lg'
        fullWidth
      >
        <UserEdit
          user={currentUser}
          title='Edit User'
          note={loggedInUser?.role !== ROLES.STAFF ? 'NOTE: CONTACT YOUR PSM TO MAKE CHANGES TO NAME FIELDS' : undefined}
          isLoading={isEditLoading}
          onSave={editUser}
          onClose={closeEdit}
        />
      </Dialog>

      <ConfirmationDialog
        open={isPromoteOpen}
        onClose={closePromote}
        onConfirm={promoteUser}
        title={`Are you sure you want to promote ${currentFullName}?`}
        loading={isPromoteLoading}
      >
        <Typography>
          By confirming you will promote {currentFullName} (Secondary User) to become the new Primary User.
          This will also change the status of {primaryFullName} (Primary User) to the role of Secondary user.
        </Typography>
      </ConfirmationDialog>

      <ConfirmationDialog
        open={isEnableOpen}
        onClose={closeEnable}
        onConfirm={enableUser}
        title={`Are you sure you want to activate ${currentUser?.role} ${currentFullName}?`}
        loading={isEnableLoading}
      >
        <Typography>
          This will allow {currentFullName} to access the platform and all the information their organization has entered.
        </Typography>
      </ConfirmationDialog>

      <ConfirmationDialog
        open={isDisableOpen}
        onClose={closeDisable}
        onConfirm={disableUser}
        title={`Are you sure you want to deactivate ${currentUser?.role} ${currentFullName}?`}
        loading={isDisableLoading}
      >
        <Typography>
          This will prevent {currentFullName} from logging into the platform until they are restored to &quot;Active&quot;.
        </Typography>
      </ConfirmationDialog>

      <Snackbar
        {...snackbarData}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        style={{ top: 125 }}
      />
    </>
  );
}
