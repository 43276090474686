import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import routes from 'src/next/navigation/routes';
import { Typography } from '@mui/material';
import useSnackbar from 'src/next/util/useSnackbar';
import _, { curry } from 'lodash';
import Snackbar from 'src/next/mui/Snackbar';
import CertificationStepSummary from './CertificationStepSummary';
import { getCertificationStepMetadata, getLatestAssessmentStep } from './certificationUtil';
import wrapPromise from '../util/wrapPromise';
import PillarMain from '../assessment/PillarMain';
import useStore from '../store';
import useDialogState from '../util/useDialogState';
import ConfirmationDialog from '../mui/ConfirmationDialog';

export default function CertificationStepMain({
  certification,
  certificationSteps = []
}) {
  const { certificationStepId } = useParams();
  const {
    submitCertification,
    reviewCertification,
    pillars,
    getPillars
  } = useStore(state => state.certification);
  const { user } = useStore(state => state.auth);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [isSubmitDialogOpen, openSubmitDialog, closeSubmitDialog] = useDialogState();
  const [isApproveDialogOpen, openApproveDialog, closeApproveDialog] = useDialogState();
  const [isRejectDialogOpen, openRejectDialog, closeRejectDialog] = useDialogState();
  const [snackbarData, openSnackbar] = useSnackbar();
  const shownStep = certificationSteps.find(cs => cs.id === +certificationStepId);
  const latestAssessmentStep = getLatestAssessmentStep(+certificationStepId, certificationSteps);
  const {
    isCurrent,
    isDraft,
    isReview,
    isApproved
  } = useMemo(
    () => getCertificationStepMetadata(shownStep?.id, certificationSteps, certification),
    [shownStep, certificationSteps, certification]
  );

  const openSubmissionSnackbar = () => openSnackbar('Action completed successfully!', 'success');
  const openErrorSnackbar = () => openSnackbar('An error occurred!', 'error');
  const withSnackbar = curry(wrapPromise)(_, openSubmissionSnackbar, openErrorSnackbar, () => {});

  const submitAndClose = useCallback(withSnackbar(async () => {
    try {
      setIsSubmitting(true);
      await submitCertification(certification.id);
    } finally {
      setIsSubmitting(false);
      closeSubmitDialog();
    }
  }, [submitCertification, certification, withSnackbar]));

  const reviewAndClose = useCallback(withSnackbar(async (isApproved, step) => {
    try {
      isApproved ? setIsApproving(true) : setIsRejecting(true);
      await reviewCertification(certification.id, isApproved, step);
    } finally {
      isApproved ? setIsApproving(false) : setIsRejecting(false);
      isApproved ? closeApproveDialog() : closeRejectDialog();
    }
  }, [reviewCertification, certification]));

  useEffect(() => {
    if (shownStep?.certificationCycle) {
      getPillars(certification.id, shownStep.certificationCycle.id);
    }
  }, [certification.id, shownStep?.certificationCycle, getPillars]);

  return (
    !(shownStep && pillars.length > 0)
      ? null
      : <>
        <Switch>
          <Route path={routes.CERTIFICATION_STEP} exact>
            <CertificationStepSummary
              certificationStep={shownStep}
              certification={certification}
              pillars={pillars}
              isCurrent={isCurrent}
              isDraft={isDraft}
              isReview={isReview}
              isApproved={isApproved}
              onSubmit={openSubmitDialog}
              onApprove={openApproveDialog}
              onReject={openRejectDialog}
              isSubmitting={isSubmitting}
              isApproving={isApproving}
              isRejecting={isRejecting}
              userRole={user?.role}
            />

            <ConfirmationDialog
              open={isSubmitDialogOpen}
              loading={isSubmitting}
              onClose={closeSubmitDialog}
              onConfirm={() => submitAndClose(true)}
              PaperProps={{ elevation: 0 }}
              maxWidth='md'
              fullWidth
              title={
                <Typography variant='modalHeadline'>
                  Are you sure you want to <Typography color='secondary' variant='modalHeadline'>Submit</Typography> this assessment?
                </Typography>
              }
              confirmButtonColor='secondary'
              confirmButtonLabel='Submit'
            >
            </ConfirmationDialog>

            <ConfirmationDialog
              open={isApproveDialogOpen}
              loading={isApproving}
              onClose={closeApproveDialog}
              onConfirm={() => reviewAndClose(true, shownStep)}
              PaperProps={{ elevation: 0 }}
              maxWidth='md'
              fullWidth
              title={
                <Typography variant='modalHeadline'>
                  Are you sure you want to <Typography color='secondary' variant='modalHeadline'>Approve</Typography> this assessment?
                </Typography>
              }
              confirmButtonColor='secondary'
              confirmButtonLabel='Approve'
            >
            </ConfirmationDialog>

            <ConfirmationDialog
              open={isRejectDialogOpen}
              loading={isRejecting}
              onClose={closeRejectDialog}
              onConfirm={() => reviewAndClose(false, shownStep)}
              PaperProps={{ elevation: 0 }}
              maxWidth='md'
              fullWidth
              title={
                <Typography variant='modalHeadline'>
                  Are you sure you want to <Typography color='error' variant='modalHeadline'>Reject</Typography> this assessment?
                </Typography>
              }
              confirmButtonColor='error'
              confirmButtonLabel='Reject'
            >
            </ConfirmationDialog>
          </Route>
          <Route path={routes.CERTIFICATION_PILLAR}>
            <PillarMain
              pillars={pillars}
              certificationStep={shownStep}
              certification={certification}
              latestAssessmentStep={latestAssessmentStep}
            />
          </Route>
        </Switch>
        <Snackbar
          {...snackbarData}
          autoHideDuration={4000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          style={{ top: 125 }}
        />
      </>
  );
}
