import React, { useCallback } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Link,
  Typography,
  TextField,
  FormHelperText,
  Button
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useActions } from 'src/store';
import { ROUTES } from 'src/router';
import { useStateWithMountCheck } from 'src/hooks/useIsMountedRef';
import { sendMFACode } from 'src/utils/auth';
import AuthLayout from 'src/layouts/AuthLayout';
import { useErrorOutlineStyles } from 'src/hooks/useGlobalStyles';
import { api } from '../../../next/user/userStore';

const useStyles = makeStyles(theme => ({
  cardContent: {
    padding: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    minHeight: 400
  },
  whiteColor: {
    color: '#fff'
  }
}));

const MFA = () => {
  const classes = useStyles();
  const { loadSession } = useActions('auth');
  const globalErrorOutineClasses = useErrorOutlineStyles();
  const [isLoading, setLoading] = useStateWithMountCheck(false);
  const [mfaCode, setMfaCode] = useStateWithMountCheck('');
  const [error, setError] = useStateWithMountCheck('');
  const history = useHistory();
  const handleMFACode = useCallback(async () => {
    try {
      setLoading(true);
      await sendMFACode({ mfaCode });
      await loadSession();
      await api.updateUserLastLogin();
      history.push(ROUTES.NEXT);
    } catch (err) {
      setError('Incorrect authentication code');
      setLoading(false);
    }
  }, [mfaCode, history, setError, setLoading]);

  const getSubmitBtn = () => {
    const label = 'Log in';
    let clickHandler = handleMFACode;

    if (isLoading) {
      clickHandler = null;
    }

    return (
      <Box mt={5}>
        <Button
          id="login-btn"
          data-testid="login-btn"
          onClick={clickHandler}
          variant="contained"
          color="primary"
          disabled={isLoading || !mfaCode}
          size="large">
          {label}
        </Button>
      </Box>
    );
  };

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      handleMFACode();
    }
  };

  return (
    <AuthLayout title="SMS Code Authentication" isLoading={isLoading}>
      <Card>
        <CardContent className={classes.cardContent}>
          <Box mt={3} mb={2} alignItems="center">
            <Typography
              style={{ fontWeight: 'bold', fontSize: 24 }}
              color="textPrimary"
              gutterBottom
              variant="subtitle1">
              SMS Code Authentication
            </Typography>
            <Typography color="textPrimary" variant="subtitle1">
              We have sent an authentication code to the phone number that you have registered with the account
            </Typography>
          </Box>
          <Divider />
          <Box flexGrow={1} mt={3} mb={2}>
            <TextField
              id="mfa-code-input"
              onChange={event => setMfaCode(event.target.value)}
              onKeyDown={handleKeyDown}
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: globalErrorOutineClasses.label,
                  focused: error ? globalErrorOutineClasses.error : globalErrorOutineClasses.focusedLabel
                }
              }}
              InputProps={{
                classes: {
                  root: globalErrorOutineClasses.textFieldRoot,
                  error: globalErrorOutineClasses.error,
                  notchedOutline: globalErrorOutineClasses.notchedOutline,
                  focused: error ? globalErrorOutineClasses.error : globalErrorOutineClasses.clicked
                }
              }}
              error={error}
              label="Authentication code"
              variant="outlined"
              disabled={isLoading}
              fullWidth
              required
              className={clsx(globalErrorOutineClasses, { [globalErrorOutineClasses.filled]: mfaCode.length > 0 })}
              data-testid="mfa-code-input"
            />
            {error && (
              <Box ml={1.75}>
                <FormHelperText error>{error}</FormHelperText>
              </Box>
            )}
          </Box>
          {getSubmitBtn()}
          <Box my={3}>
            <Divider />
          </Box>
          <Link
            component={RouterLink}
            to={ROUTES.LOGIN}
            variant="body2"
            color="textSecondary"
            underline="hover">
            Cancel
          </Link>
        </CardContent>
      </Card>
    </AuthLayout>
  );
};

export default MFA;
