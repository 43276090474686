import { useMemo } from 'react';
import assessmentType from './assessmentType';
import useAssessmentType from './useAssessmentType';

const nameByAssessmentType = {
  [assessmentType.BEW]: 'Black Equity at Work',
  [assessmentType.HEW]: 'Hispanic Equity at Work'
};

function useAssessmentName(certification) {
  const currentAssessmentType = certification
    ? certification.assessmentType?.title
    : useAssessmentType();

  return useMemo(
    () => nameByAssessmentType[currentAssessmentType] ?? 'Unknown Assessment',
    [currentAssessmentType]
  );
}

export default useAssessmentName;
