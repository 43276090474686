import { useRouteMatch } from 'react-router-dom';
import routes from 'src/next/navigation/routes';

const certificationRoutes = Object.entries(routes)
  .filter(([name]) => name.startsWith('CERTIFICATION'))
  .map(([, path]) => path);

function useCertificationId() {
  const match = useRouteMatch({ path: certificationRoutes, exact: true });

  return match ? +match.params.certificationId : null;
}

export default useCertificationId;
