import React, { useCallback, useState } from 'react';
import {
  Card,
  Table,
  TableBody,
  TableRow,
  TableCell,
  useTheme,
  IconButton,
  TableHead,
  TableSortLabel,
  TextField,
  InputAdornment,
  Pagination,
  Box
} from '@mui/material';
import { MoreVertical as MoreVerticalIcon, Search as SearchIcon } from 'react-feather';
import styled from '@emotion/styled';
import { ASSIGNMENT_TYPES } from 'src/constants';
import usePopperState from 'src/next/util/usePopperState';
import _, { debounce, isBoolean } from 'lodash';
import ClearIcon from '@mui/icons-material/Clear';
import UserActionsPopper from './UserActionsPopper';
import CertificationEmployeeChip from '../certification/CertificationEmployeeChip';
import usePagination from '../util/usePagination';

const ROWS_PER_PAGE = 20;
const SEARCH_DELAY_MS = 400;

const StyledTableCell = styled(TableCell)`
  color: inherit;
`;

function sortByLastNameCaseInsensitive(a, b) {
  const lastnameA = (a?.last || '').toLowerCase();
  const lastnameB = (b?.last || '').toLowerCase();

  if (lastnameA < lastnameB) return -1;
  if (lastnameA > lastnameB) return 1;
  return 0;
}

const UserListItem = styled(({
  user,
  isCurrent,
  onEdit,
  onPromote,
  onPasswordReset,
  onToggleEnabled,
  short,
  setCurrentUserCertificationId,
  isAdmin,
  ...props
}) => {
  const theme = useTheme();
  const [
    anchorEl,
    isPopperOpen,
    onPopperOpen,
    onPopperClose
  ] = usePopperState();

  return (
    <>
      <TableRow {...props} sx={{ color: !user.enabled && theme.palette.tertiary.dark }}>
        <StyledTableCell>
          {user.last}
        </StyledTableCell>
        <StyledTableCell>
          {user.first}
        </StyledTableCell>
        <StyledTableCell>
          {user.organization || 'DEACTIVATED'}
        </StyledTableCell>
        <StyledTableCell>
          {
            isBoolean(user.isPrimary) &&
            <CertificationEmployeeChip
              isPrimary={user.assignmentType === ASSIGNMENT_TYPES.PRIMARY_EMPLOYEE}
            />
          }
        </StyledTableCell>
        {
          isAdmin &&
          <StyledTableCell>
            <Box ml={!user.lastLogin ? 3 : 0}>
              {user.lastLogin ? user.lastLogin : '-'}
            </Box>
          </StyledTableCell>
        }
        <StyledTableCell align='right'>
          <IconButton onClick={onPopperOpen}>
            <MoreVerticalIcon />
          </IconButton>
        </StyledTableCell>
      </TableRow >

      <UserActionsPopper
        open={isPopperOpen}
        anchorEl={anchorEl}
        onClose={onPopperClose}
        user={user}
        isCurrent={isCurrent}
        onEdit={onEdit}
        onPromote={onPromote}
        onPasswordReset={onPasswordReset}
        onToggleEnabled={onToggleEnabled}
        certificationId={user.certificationId}
        setCurrentUserCertificationId={setCurrentUserCertificationId}
        isSecondary = {user.assignmentType === ASSIGNMENT_TYPES.SECONDARY_EMPLOYEE}
        isAdmin={isAdmin}
        isDeactivated={!user.organization}
      />
    </>
  );
})`
  border-top: 1px solid ${props => props.theme.palette.divider};
`;

const headCells = [
  { label: 'Last Name', sortProps: ['last'] },
  { label: 'First Name', sortProps: ['first'] },
  { label: 'Organization', sortProps: ['organization'] },
  { label: 'Role', sortProps: ['assignmentType'] },
  { label: 'Last login', sortProps: ['lastLogin'] }
];

export default function SearchableUserList({
  users,
  currentUser,
  onEdit,
  onPromote,
  onPasswordReset,
  onToggleEnabled,
  setCurrentUserCertificationId,
  isAdmin
}) {
  const [search, setSearch] = React.useState('');
  const [inputValue, setInputValue] = useState('');
  const [order, setOrder] = React.useState('asc');
  const [orderByIndex, setOrderByIndex] = React.useState(0);

  const sortProps = headCells[orderByIndex]?.sortProps;
  const searchRegex = new RegExp(search, 'i');
  let transformedUsers = _(users)
  .flatMap(u => {
    const certificationAssignments = u.certificationAssignments || [];
    return certificationAssignments.map(certificationAssignment => {
      const organization = certificationAssignment.certification?.partner?.name;
      const assignmentType = certificationAssignment.type;
      const certificationId = certificationAssignment.certification?.id;
      const certificationSalesforceId = certificationAssignment.certification?.salesforceId;

      return { ...u, organization, assignmentType, certificationSalesforceId, certificationId };
    });
  })
  .filter(u => searchRegex.test(`${u.first} ${u.last}`) ||
    searchRegex.test(`${u.last} ${u.first}`) ||
    searchRegex.test(u.organization))
  .orderBy([u => (u[sortProps] || '').toLowerCase()], [order])
  .value();

  // if sorting is based on role, we need to make sure that all records are also sorted by last name
  if (sortProps[0] === 'assignmentType') {
    const secondaryEmployees = transformedUsers
    .filter(user => user.assignmentType === ASSIGNMENT_TYPES.SECONDARY_EMPLOYEE)
    .sort(sortByLastNameCaseInsensitive);

    const primaryEmployees = transformedUsers
    .filter(user => user.assignmentType === ASSIGNMENT_TYPES.PRIMARY_EMPLOYEE)
    .sort(sortByLastNameCaseInsensitive);

    transformedUsers = order === 'asc'
      ? [...primaryEmployees, ...secondaryEmployees]
      : [...secondaryEmployees, ...primaryEmployees];
  }

  const { pageRows: pageUsers, page, setPage, pageCount } = usePagination(transformedUsers, ROWS_PER_PAGE);

  const createSortHandler = i => () => {
    if (i === orderByIndex) {
      setOrder(order === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderByIndex(i);
      setOrder('asc');
    }
  };

  const handleSearch = useCallback(debounce(event => {
    setSearch(event.target.value);
  }, SEARCH_DELAY_MS), []);

  const handleClear = () => {
    setSearch('');
    setInputValue('');
  };

  return (
    <Card sx={{ px: 2, pt: 2 }}>
      <TextField
        value={inputValue}
        onChange={event => {
          setInputValue(event.target.value);
          handleSearch(event);
        }}
        placeholder='Search Users or Organizations'
        type='text'
        fullWidth
        sx={{ mb: 2 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: inputValue && (
            <IconButton aria-label='clear search' onClick={handleClear}>
              <ClearIcon fontSize='small' />
            </IconButton>
          )
        }}
      />
      <Table>
        <TableHead>
          <TableRow>
            {
              headCells.map((c, i) => (
                (isAdmin || c.label !== 'Last login') && <TableCell
                  key={i}
                  sortDirection='desc'
                  sx={{ fontWeight: 'bold' }}
                >
                  <TableSortLabel
                    active={orderByIndex === i}
                    direction={orderByIndex === i ? order : 'asc'}
                    onClick={createSortHandler(i)}
                  >
                    {c.label}
                  </TableSortLabel>
                </TableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            pageUsers.map(u => {
              const isCurrent = currentUser.id === u.id;

              return (
                <UserListItem
                  user={u}
                  key={u.username + '-' + u.certificationSalesforceId}
                  isCurrent={isCurrent}
                  onEdit={onEdit}
                  onPromote={onPromote}
                  onPasswordReset={onPasswordReset}
                  onToggleEnabled={onToggleEnabled}
                  setCurrentUserCertificationId={setCurrentUserCertificationId}
                  isAdmin={isAdmin}
                />
              );
            })
          }
        </TableBody>
      </Table>
      <Pagination
        shape="rounded"
        count={pageCount}
        page={page}
        onChange={(event, newPage) => setPage(newPage)}
        sx={{ my: 2, display: 'flex', justifyContent: 'center' }}
      />
    </Card>
  );
}
