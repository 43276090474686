import { useEffect } from 'react';
import useStore, { useActions } from 'src/store';
import { selectCertification } from 'src/store/certification/selectors';
import { selectPartners } from './store/partners/selectors';

const matchPartner = certification => partner => (
  partner.id === (certification.partner?.id ?? certification.partner)
);

export function useSetPartnerOnCertificationChange() {
  const certification = useStore(selectCertification);
  const partners = useStore(selectPartners);
  const partnerActions = useActions('partner');

  useEffect(() => {
    if (!certification) {
      return;
    }

    const partner = partners.find(matchPartner(certification));

    partnerActions.setCurrent(partner);
  }, [certification?.id]);
}
