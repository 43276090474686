import React, { createContext, useEffect, useState } from 'react';
import merge from 'lodash/merge';

import { THEMES } from 'src/constants';
import Logger from 'src/utils/logger';

const defaultSettings = {
  direction: 'ltr',
  responsiveFontSizes: true,
  theme: THEMES.LIGHT
};

export const restoreSettings = () => {
  try {
    const storedData = window.localStorage.getItem('settings');
    if (storedData) return JSON.parse(storedData);
  } catch (err) {
    Logger.error(err);
  }
};

export const storeSettings = settings => {
  window.localStorage.setItem('settings', JSON.stringify(settings));
};

const SettingsContext = createContext({
  settings: defaultSettings,
  saveSettings: () => {}
});

export const SettingsProvider = ({ settings, children }) => {
  const [currentSettings, setCurrentSettings] = useState(settings || defaultSettings);

  const saveSettings = (update = {}) => {
    const mergedSettings = merge({}, currentSettings, update);
    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  useEffect(() => {
    const restoredSettings = restoreSettings();
    if (restoredSettings) {
      setCurrentSettings(restoredSettings);
    }
  }, []);

  useEffect(() => {
    document.dir = currentSettings.direction;
  }, [currentSettings]);

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings
      }}>
      {children}
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;
export default SettingsContext;
