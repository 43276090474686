import produce from 'immer';

/**
 * Zustand middleware that wraps the `set` method to always use the immutable version of the state,
 * with the help of the `immer` library.
 *
 * NOTE(marko): This middleware should be used inside the `devtools` middleware, exposed by zustand.
 * The reason for this is that the `devtools` middleware allows state mutations to be named so
 * they're easily debuggable by redux developer tools extension. Also note that we've swapped the
 * order of 2nd and 3rd argument here, so we can use the `set` method in our application without
 * having to supply the `replace` argument each time we want to use it, since it will pretty much
 * always be `false`.
 */
export const immer = config => (set, get, api) => config((partial, name, replace) => {
  const nextState = typeof partial === 'function'
    ? produce(partial)
    : partial;
  return set(nextState, replace, name);
}, get, api);
