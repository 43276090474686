export class PeriodsConfig {
  constructor(assessmentRanges, currentDate = new Date()) {
    this.assessmentRanges = assessmentRanges;
    this.currentDate = currentDate;
  }

  getPeriods() {
    return [
      new Period('February', 1, this.assessmentRanges, this.currentDate),
      new Period('August', 2, this.assessmentRanges, this.currentDate)
    ];
  }
}

class Period {
  constructor(title, position, assessmentRanges, currentDate) {
    this.title = title;
    this.position = position;
    this.assessmentRanges = assessmentRanges;
    this.currentDate = currentDate;
  }

  get startDate() {
    const startDateValue = this.assessmentRanges[this.position].startDate;
    return typeof startDateValue === 'string' ? this.getDate(startDateValue) : startDateValue;
  }

  get endDate() {
    const endDateValue = this.assessmentRanges[this.position].endDate;
    return typeof endDateValue === 'string' ? this.getDate(endDateValue) : endDateValue;
  }

  get isCurrent() {
    return this.startDate <= this.currentDate && this.currentDate < this.endDate;
  }

  get isNext() {
    return this.startDate > this.currentDate;
  }

  get isClosed() {
    return this.currentDate > this.endDate;
  }

  hasNextStep(currentCycleSteps) {
    const nextPeriodSteps = currentCycleSteps.filter(step => step.position !== this.position);
    return nextPeriodSteps.length > 0;
  }

  getDate(dateString) {
    const [month, day] = dateString.split('-');
    const currentYear = this.currentDate.getFullYear();

    return new Date(currentYear, month - 1, day);
  }
}
