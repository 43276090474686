import React from 'react';
import {
  Link as LinkIcon,
  Book as BookIcon,
  Image as ImageIcon,
  Youtube as YoutubeIcon
} from 'react-feather';
import resourceTypes from './resourceTypes';

const iconComponents = {
  [resourceTypes.WEBPAGE]: LinkIcon,
  [resourceTypes.IMAGE]: ImageIcon,
  [resourceTypes.VIDEO]: YoutubeIcon,
  [resourceTypes.MLTx]: BookIcon
};

function ResourceIcon(props) {
  const Component = iconComponents[props.type] || BookIcon;
  return (
    <Component {...props} />
  );
}

export default ResourceIcon;
