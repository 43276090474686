import React from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  pillarIcon: {
    marginRight: theme.spacing(1),
    color: '#999',
    fontSize: '1.3rem'
  },
  pillarCompletedIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.secondary.main,
    fontSize: '1.3rem'
  },
  pillarTextContainer: {
    marginTop: 1
  },
  pillarText: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
    color: '#999'
  },
  pillarSelectedText: {
    color: theme.palette.secondary.main
  },
  pillarProgressText: {
    fontSize: '0.7rem',
    fontStyle: 'italic',
    marginLeft: 5,
    whiteSpace: 'nowrap',
    color: '#999'
  },
  pillarContainer: {
    marginTop: theme.spacing(3)
  },
  label: {
    fontWeight: 'bold'
  },
  labelError: {
    color: theme.palette.error.main
  },
  answer: {
    padding: 10
  },
  programText: {
    marginBottom: theme.spacing(2),
    fontSize: '1rem',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  progressText: {
    fontSize: 12,
    fontWeight: 600
  },
  progressPercentageText: {
    fontSize: '0.8rem',
    fontWeight: 'bold',
    color: '#666'
  },
  pillarProgress: {
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0
    },
    cursor: 'pointer'
  },
  progressBarWidth: {
    width: '100%'
  },
  progressRoot: {
    height: 5
  },
  progressColorPrimary: {
    backgroundColor: '#C4C4C4'
  },
  progressBarColorPrimary: {
    backgroundColor: theme.palette.secondary.main
  }
}));

function BaselineProgress({ completed = 0, total = 0, ...props }) {
  const classes = useStyles();
  const percentage = (completed / total) * 100;

  return (
    <Box {...props}>
      <Box display="flex" justifyContent="space-between" className={classes.progressBarWidth}>
        <Typography component="span" className={classes.progressText} gutterBottom>
          Baseline Assessment {percentage.toFixed(1)}% complete
        </Typography>
      </Box>

      <LinearProgress
        variant="determinate"
        classes={{
          root: classes.progressRoot,
          colorPrimary: classes.progressColorPrimary,
          barColorPrimary: classes.progressBarColorPrimary
        }}
        className={classes.progressBarWidth}
        value={percentage}
      />
    </Box>
  );
}

export default BaselineProgress;
