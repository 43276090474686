import axios from 'axios';
import qs from 'query-string';
import { getSession, logout, getCurrentUser } from './auth';

import { API_URL } from '../constants';

axios.defaults.paramsSerializer = function (params) {
  return qs.stringify(params, { arrayFormat: 'none' });
};

axios.interceptors.request.use(
  config => {
    const { url = '' } = config;
    // only add `Authorization` heder for our API request
    const shouldIncludeAuthorization = url.search(API_URL) > -1;
    // append `api` path
    config.url = url.replace(API_URL, `${API_URL}/api`);
    // can't use async/await for react-query to work
    // calling `getSession` will handle refreshing token automatically
    // https://github.com/aws-amplify/amplify-js/blob/master/packages/amazon-cognito-identity-js/src/CognitoUser.js#L1404-L1418
    return getSession()
      .then(session => {
        const accessToken = (session && session.getAccessToken().getJwtToken()) || null;
        if (shouldIncludeAuthorization && accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return Promise.resolve(config);
      })
      .catch(() => Promise.resolve(config));
  },
  err => Promise.reject(err)
);

axios.interceptors.response.use(
  response => response.data,
  err => {
    // Log the user out if Unauthorized 401
    if (err.response?.status === 401 && getCurrentUser()) {
      logout();
      window.location.reload();
    }
    const error = {
      ...err.response,
      ...((err.response?.data && err.response.data.error) || { message: 'Unknown Error' })
    };
    return Promise.reject(error);
  }
);
