import { getCLS, getFCP, getFID, getLCP, getTTFB } from 'web-vitals';
import isFunction from 'lodash/isFunction';

const reportWebVitals = onPerfEntry => {
  if (!isFunction(onPerfEntry)) return;
  getCLS(onPerfEntry);
  getFID(onPerfEntry);
  getFCP(onPerfEntry);
  getLCP(onPerfEntry);
  getTTFB(onPerfEntry);
};

export default reportWebVitals;
