import axios from 'axios';
import { API_URL } from '../constants';

export const getEmployees = async certificationId => {
  const url = `${API_URL}/certifications/${certificationId}/employees`;
  const { data } = await axios.get(url);
  return data;
};
getEmployees.QUERY_KEY = 'getEmployees';

export async function addEmployee(certificationId, employeeDTO) {
  const url = `${API_URL}/certifications/${certificationId}/employees`;
  const response = await axios.post(url, employeeDTO);
  return response.data;
}
