import React from 'react';
import { Typography as MuiTypography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles(({ palette, typography }) => ({
  text: ({ size, transform, bold, dark }) => ({
    fontSize: size,
    textTransform: transform,
    fontWeight: bold && typography.fontWeightBold,
    color: dark && palette.text.common
  })
}));

function Typography({
  bold,
  transform,
  size,
  dark,
  className,
  ...props
}) {
  const classes = useStyles({ bold, transform, size, dark });

  return (
    <MuiTypography
      {...props}
      className={clsx(classes.text, className)}
    />
  );
}

export default Typography;
