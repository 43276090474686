import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from 'src/router';
import { PageProgress } from 'src/components/Page';
import Baseline from './Baseline';

const Dashboard = React.lazy(() => import('./Dashboard'));
const Settings = React.lazy(() => import('./Settings'));
const ManageUsers = React.lazy(() => import('./Settings/ManageUsers'));
const SettingAddUser = React.lazy(() => import('./Settings/AddUser'));

function Router() {
  return (
    <Suspense fallback={<PageProgress />}>
      <Switch>
        <Route path={ROUTES.DASHBOARD} component={Dashboard} />
        <Route path={ROUTES.CERTIFICATION_BASELINE} component={Baseline} />
        <Route path={ROUTES.SETTINGS_MANAGE_USERS} component={ManageUsers} />
        <Route path={ROUTES.SETTINGS_ADD_USERS} component={SettingAddUser} />
        <Route path={ROUTES.SETTINGS} component={Settings} />
        <Redirect to={ROUTES.NEXT}></Redirect>
      </Switch>
    </Suspense>
  );
}

export { ROUTES };
export default Router;
