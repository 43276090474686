import axios from 'axios';
import isNil from 'lodash/isNil';
import { routes as certificationRoutes } from 'src/api/certifications';

const routes = {
  baseline: (certificationId, baselineId) => {
    const certification = certificationRoutes.certification(certificationId);
    return `${certification}/baselines/${baselineId}`;
  },
  answers: (certificationId, baselineId) => {
    const certification = certificationRoutes.certification(certificationId);
    return `${certification}/baselines/${baselineId}/answers`;
  }
};

export async function getBaseline(certificationId, baselineId, params) {
  const url = routes.baseline(certificationId, baselineId);
  const response = await axios.get(url, { params });
  return response.data;
}
getBaseline.QUERY_KEY = 'getBaseline';

export async function getAnswers(certificationId, baselineId, params) {
  const url = routes.answers(certificationId, baselineId);
  const response = await axios.get(url, { params });
  return response.data;
}
getAnswers.QUERY_KEY = 'getAnswers';

// TODO: Rename to upsertAnswer
export async function addAnswer({
  certificationId,
  baselineId,
  question,
  value,
  notes
}) {
  const payload = {
    ...(!isNil(value) && { value }),
    ...(!isNil(notes) && { notes })
  };
  const url = routes.answers(certificationId, baselineId);
  const response = await axios.post(`${url}/${question.id}`, payload);
  return response;
}

export function patchBaseline(certificationId, baselineId, payload) {
  const url = routes.baseline(certificationId, baselineId);
  return axios.patch(url, payload).then(res => res.data);
}
