import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { mapValues } from 'lodash';
import BadgeIcon from './BadgeIcon';
import CertificationPeriods from './CertificationPeriods';
import CertificationCountdown from './CertificationCountdown';
import themeByAssessmentType from '../assessment/themeByAssessmentType';

const COLOR_BY_ASSESSMENT_TYPE = mapValues(themeByAssessmentType, theme => theme.palette.primary.main);

const useStyles = makeStyles(theme => ({
  assessmentStatus: {
    fontWeight: theme.typography.fontWeightBold
  },
  icon: () => ({
    width: 16,
    height: 16,
    marginRight: theme.spacing(1)
  })
}));

export default function CertificationOverview({ statusConfig, periods, currentDate }) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const classes = useStyles();
  const {
    title,
    isCertified,
    message,
    certificationCycleYear,
    assessmentType,
    isBaseline,
    score,
    icon: TitleIcon,
    actionButton,
    assessmentWindow
  } = statusConfig;
  const highlightColor = COLOR_BY_ASSESSMENT_TYPE[assessmentType];
  const handleActionButtonClick = () => {
    setIsButtonDisabled(true);
    actionButton.actionHandler();
  };

  return (
    <>
      <Grid container columns={10}>
        <Grid container item xs={isBaseline ? 10 : 6.5}>
          <Grid display="flex" justifyContent="start">
            {
              isCertified && (
                <Box mr={2}>
                  <BadgeIcon
                    assessmentType={assessmentType}
                    score={score}
                    year={certificationCycleYear}
                    width={52}
                    currentDate={currentDate} />
                </Box>
              )
            }
            <Box>
              <Grid mb={1} display="flex" alignItems="center">
                {TitleIcon && <TitleIcon className={classes.icon} />}
                <Typography className={classes.assessmentStatus}>
                  {title}
                </Typography>
              </Grid>
              <Grid>
                {message}
              </Grid>
            </Box>
          </Grid>
        </Grid>
        {
          !isBaseline && (
            <Grid container item xs={3.5} justifyContent="flex-end">
              <CertificationPeriods periods={periods} highlightColor={highlightColor} />
            </Grid>
          )
        }
      </Grid>
      {
        !actionButton.isHidden && (
          <Button
            sx={{ mt: 3, '& .MuiButton-startIcon': { marginRight: 0 } }}
            color="secondary"
            disabled={isButtonDisabled}
            startIcon={isButtonDisabled ? <CircularProgress color="inherit" size={20} /> : null}
            onClick={handleActionButtonClick}>
            {!isButtonDisabled && actionButton.label}
          </Button>
        )
      }
      {
        !assessmentWindow.isHidden && (
          <>
            <Divider sx={{ my: 2 }} />
            <CertificationCountdown
              period={assessmentWindow.period}
              isCurrent={assessmentWindow.isCurrent}
              remainingTime={assessmentWindow.remainingTime} />
          </>
        )
      }
    </>
  );
}
