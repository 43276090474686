import { styled } from '@mui/material';
import Typography from 'src/components/common/Typography';

const EmbeddedContent = styled(Typography)(({ theme }) => ({
  '& > ul, & > ol': {
    padding: theme.spacing(1, 0, 0, 2)
  }
}));

export default EmbeddedContent;
