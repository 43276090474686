import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { AppBar, Hidden, IconButton, Toolbar, SvgIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Menu as MenuIcon } from 'react-feather';

import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.appBar,
    ...(theme.name === THEMES.LIGHT
      ? {
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.main
      }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
        backgroundColor: theme.palette.background.default
      }
      : {})
  },
  toolbar: {
    minHeight: 64
  },
  logoutBtn: {
    color: 'white'
  }
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <RouterLink to="/">
          <Logo src="/static/white-logo.png" height={40} width={80} />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
