import { useEffect } from 'react';
import { selectCertification } from './selectors';
import useStore, { useActions } from '..';

export function useFetchCertification(partnerId) {
  const { fetch } = useActions('certification');
  const certification = useStore(selectCertification);

  useEffect(() => {
    fetch(partnerId);
  }, [certification?.status, partnerId]);
}
