import { useCallback } from 'react';
import { saveAs } from 'file-saver';
import useLoading from './useLoading';

export default function useDownload(getData, filename) {
  const [getDataWithLoading, isDownloading] = useLoading(getData);
  const download = useCallback(async () => saveAs(await getDataWithLoading(), filename), [getData, filename]);

  return [download, isDownloading];
}
