import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Box,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  FormHelperText
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import withFormik from 'src/next/util/withFormik';
import yup from 'src/shared/validation';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { changeUserPassword } from 'src/utils/auth';

const StyledPasswordField = withFormik(props => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return <TextField
    fullWidth
    type={showPassword ? 'text' : 'password'}
    InputLabelProps={{ shrink: true }}
    InputProps={{
      endAdornment: <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          edge="end"
        >
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    }}
    {...props}
  />;
}
);

const validationSchema = yup.object({
  currentPassword: yup.string().required('Current password is required'),
  newPassword: yup.string()
    .min(8, 'Password must be at least 8 characters in length')
    .max(99, 'Password must be at most 99 characters in length')
    .password(
      'Password must contain at least one lowercase and uppercase letter, at least one number, and at least one special character'
    )
    .test('same-as-current', 'New password must be different from current password', function (value) {
      return value !== this.parent.currentPassword;
    })
    .required('Password is required'),
  reTypedNewPassword: yup.string()
    .oneOf([yup.ref('newPassword')], 'Passwords must match')
    .required('Retyped password is required')
});

export default function ChangePassword({ closeChangePassword, openSuccessSnackbar }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const saveNewPassword = async data => {
    try {
      setIsLoading(true);
      await changeUserPassword(data.currentPassword, data.newPassword);
      closeChangePassword();
      openSuccessSnackbar();
    } catch (err) {
      setIsLoading(false);
      setError(err.message);
    }
  };

  const formik = useFormik({
    initialValues: { currentPassword: '', newPassword: '', reTypedNewPassword: '' },
    validationSchema,
    onSubmit: saveNewPassword
  });

  useEffect(() => {
    if (error && formik.values.currentPassword) {
      setError('');
    }
  }, [formik.values.currentPassword]);

  return (
    <Box p={4}>
      <Grid mb={3}>
        <Typography variant='modalHeadline'>
          Change Password
        </Typography>
      </Grid>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <StyledPasswordField
          label="Current Password"
          name="currentPassword"
          formik={formik}
          disabled={isLoading}
          sx={{ mb: 5 }}
        />
        <StyledPasswordField
          label="New Password"
          name="newPassword"
          formik={formik}
          disabled={isLoading}
          sx={{ mb: 5 }}
        />
        <StyledPasswordField
          label="Re-type New Password"
          name="reTypedNewPassword"
          formik={formik}
          disabled={isLoading}
          sx={{ mb: 1 }}
        />
        {
          error && <FormHelperText error>
            {error.replace('username or', '')}
          </FormHelperText>
        }
        <Grid
          container
          justifyContent='center'
          spacing={1}
          mt={3}
          mb={1}
        >
          <Grid item>
            <LoadingButton
              type='submit'
              loading={isLoading}
              disabled={!formik.isValid || !formik.values.newPassword}
            >
              Save
            </LoadingButton>
          </Grid>
          <Grid item>
            <Button
              variant='text'
              disabled={isLoading}
              onClick={closeChangePassword}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
