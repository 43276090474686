import { camelCase } from 'lodash';

const SUFFIX = '_completion';

const getPath = type => camelCase(`${type}${SUFFIX}`);

const getQuestionGroupCompletion = (completion, questionGroup) => {
  const path = getPath(questionGroup.type);

  return completion[path][questionGroup.id] ?? {};
};

function getProgress(completion, questionGroup) {
  const {
    completed,
    total
  } = getQuestionGroupCompletion(completion, questionGroup);

  return Math.round((completed * 100) / total);
}

function isCompleted(completion, questionGroup) {
  const {
    completed,
    total
  } = getQuestionGroupCompletion(completion, questionGroup);

  return completed === total;
}

export { getProgress, isCompleted };
