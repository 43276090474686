import makeStyles from '@mui/styles/makeStyles';

const useNavItemStyles = makeStyles(({ spacing, palette, typography }) => ({
  item: {
    display: 'flex',
    padding: 0,

    '& .MuiCollapse-hidden': {
      display: 'none'
    }
  },
  button: ({ active }) => ({
    width: '100%',
    padding: spacing(1, 4),
    justifyContent: 'flex-start',
    color: active
      ? palette.secondary.main
      : palette.text.primary,
    fontWeight: active
      ? typography.fontWeightBold
      : typography.fontWeightRegular,
    background: active && '#E5E7F6',
    textDecoration: 'none',
    textTransform: 'none',
    letterSpacing: 0,
    '&:hover, &.active': {
      borderRadius: 0
    }
  }),
  icon: ({ active, disabled }) => ({
    height: 20,
    width: 20,
    margin: spacing(0.25, 1, 0, 0),
    alignSelf: 'flex-start',
    color: active
      ? palette.secondary.main
      : palette.text.primary,
    ...(disabled && { color: palette.neutrals.grey10 })
  })
}));

export default useNavItemStyles;
