import React from 'react';
import { Box } from '@mui/material';
import { useLocation, useRouteMatch, generatePath } from 'react-router-dom';
import { ROUTES } from 'src/router';
import useCertificationSummaryMatch from 'src/hooks/certificationRoutes/useCertificationSummaryMatch';
import CircleIcon from 'src/components/common/icons/Circle';
import InfoCircleIcon from 'src/components/common/icons/InfoCircle';
import CheckedCircleIcon from 'src/components/common/icons/CheckedCircle';
import SummaryNavItem from './SummaryNavItem';

function BaselineNavigation({
  completion,
  pillars,
  certificationId,
  baselineId,
  showIcon = true,
  disabled
}) {
  const location = useLocation();
  const summaryRoute = {
    pathname: generatePath(ROUTES.CERTIFICATION_BASELINE, {
      id: certificationId,
      baselineId
    }),
    search: location.search
  };

  return (
    <Box>
      <Summary to={summaryRoute} completion={completion} showIcon={showIcon} />
      {pillars.map(pillar => (
        <PillarItem
          key={`${certificationId}-${baselineId}-${pillar.id}`}
          certificationId={certificationId}
          baselineId={baselineId}
          pillar={pillar}
          completion={completion.pillarCompletion[pillar.id]}
          showIcon={showIcon}
          disabled={disabled}
        />
      ))}
    </Box>
  );
}

export default BaselineNavigation;

function Summary({ to, completion, showIcon, disabled }) {
  const isCurrentPath = useCertificationSummaryMatch();

  return (
    <SummaryNavItem
      {...completion}
      to={to}
      Icon={CircleIcon}
      CompletedIcon={CheckedCircleIcon}
      title="Baseline Summary"
      active={!!isCurrentPath}
      showIcon={showIcon}
      disabled={disabled}
    />
  );
}

function PillarItem({
  certificationId,
  baselineId,
  pillar,
  completion,
  showIcon,
  disabled
}) {
  const location = useLocation();
  const route = {
    pathname: generatePath(ROUTES.CERTIFICATION_BASELINE_PILLAR, {
      id: certificationId,
      baselineId,
      pillarId: pillar.id
    }),
    search: location.search
  };
  const routeMatch = useRouteMatch(route.pathname);

  return (
    <SummaryNavItem
      {...completion}
      to={route}
      title={pillar.name}
      active={routeMatch?.isExact}
      Icon={InfoCircleIcon}
      CompletedIcon={CheckedCircleIcon}
      showIcon={showIcon}
      disabled={disabled}
    />
  );
}
