import { useEffect } from 'react';

const IFRAME_MESSAGE_SOURCE = 'MLT-REW';

export const MESSAGE_TYPES = {
  CHANGE_HEIGHT: 'CHANGE_HEIGHT',
  SUBMIT: 'SUBMIT',
  APPROVE: 'APPROVE',
  REJECT: 'REJECT'
};

export function getIsIframed() {
  return window !== window.parent;
}

export function postIframeMessage(type, data) {
  window.postMessage({ type, data, source: IFRAME_MESSAGE_SOURCE }, window.location.origin);
}

export function useOnIframeMessage(iframeRef, callback) {
  useEffect(() => {
    const iframeWindow = iframeRef?.current?.contentWindow;

    if (iframeWindow) {
      const listener = iframeWindow.addEventListener('message', event => {
        if (event.origin !== window.location.origin) {
          return;
        }

        if (event.data?.source === IFRAME_MESSAGE_SOURCE) {
          callback(event.data.type, event.data.data);
        }
      });

      return () => iframeWindow.removeEventListener('message', listener);
    }
  }, [iframeRef]);
}
