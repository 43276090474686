import bewTheme from 'src/next/mui/bewTheme';
import hewTheme from 'src/next/mui/hewTheme';
import assessmentType from './assessmentType';

const themeByAssessmentType = {
  [assessmentType.BEW]: bewTheme,
  [assessmentType.HEW]: hewTheme
};

export default themeByAssessmentType;
