import React from 'react';

export default function usePagination(rows, rowsPerPage) {
  const [page, setPage] = React.useState(1);
  const pageRows = rows.slice((page - 1) * rowsPerPage, page * rowsPerPage);
  const pageCount = Math.max(1, Math.ceil(rows.length / rowsPerPage));

  if (page > pageCount) {
    // Handle the case where the current page is out of scope, for example after filtering
    setPage(pageCount);
  }

  return { pageRows, page, setPage, pageCount };
}
