import React from 'react';
import { generatePath, useRouteMatch } from 'react-router-dom';
import Navigation from 'src/components/Navigation';
import NavSection from 'src/components/Navigation/Section';
import NavLink from 'src/components/Navigation/Link';
import { ROUTES } from 'src/psm/routes';
import GridIcon from 'src/components/common/icons/Grid';
import useStore from 'src/store';
import { selectPartners } from 'src/psm/store/partners/selectors';
import LinkSection from 'src/components/Navigation/LinkSection';
import { selectBaseline } from 'src/store/certification/baseline/selectors';
import {
  selectAssessment,
  selectCertification,
  selectIsCertificationLoading
} from 'src/store/certification/selectors';
import BaselineNavigation from 'src/components/Navigation/Baseline';
import makeStyles from '@mui/styles/makeStyles';
import GettingStartedTrigger from 'src/components/Navigation/GettingStartedTrigger';
import SettingsIcon from 'src/components/common/icons/Settings';
import { selectPartner } from 'src/store/partner/selectors';

const DashboardTree = ({ disabled }) => {
  return (
    <NavSection title="Dashboard" open disabled>
      <NavLink
        to={ROUTES.DASHBOARD}
        Icon={GridIcon}
        disabled={disabled}
      >
        Overview
      </NavLink>
    </NavSection>
  );
};

const AdminNavigation = () => {
  const isCertificationLoading = useStore(selectIsCertificationLoading);

  return (
    <Navigation>
      <DashboardTree disabled={isCertificationLoading} />
      <PartnerTree disabled={isCertificationLoading} />
      <UtilityTree disabled={isCertificationLoading} />
    </Navigation>
  );
};

export default AdminNavigation;

const useStyles = makeStyles(theme => ({
  subSection: {
    '& .MuiListItem-root .MuiButton-label': {
      paddingLeft: theme.spacing(2)
    }
  },
  title: {
    fontSize: 14,
    textTransform: 'capitalize',
    marginLeft: theme.spacing(1)
  }
}));

function PartnerTree({ disabled }) {
  const partners = useStore(selectPartners);
  const partner = useStore(selectPartner);
  const baseline = useStore(selectBaseline);
  const certification = useStore(selectCertification);
  const assessment = useStore(selectAssessment);
  const classes = useStyles();
  const baselineRouteMatch = useRouteMatch(ROUTES.CERTIFICATION_BASELINE);
  const isBaselineReady = certification && assessment && baseline;

  return (
    <NavSection title="Employers" open dropdown>
      {partners.map(it => (
        <LinkSection
          key={`partner-${it.id}`}
          title={it.name}
          isOpen={partner?.id === it.id}
          to={generatePath(ROUTES.PARTNER_OVERVIEW, { partnerId: it.id })}
          disabled={disabled}
        >
          <NavSection
            title="Baseline Assessment"
            open={!!baselineRouteMatch?.params.baselineId && !!baseline}
            dropdown
            titleClassName={classes.title}
            className={classes.subSection}
          >
            {isBaselineReady && (
              <BaselineNavigation
                completion={baseline.completion}
                pillars={assessment.pillars}
                certificationId={certification.id}
                baselineId={baseline.id}
                showIcon={false}
                disabled={disabled}
              />
            )}
          </NavSection>
        </LinkSection>
      ))}
    </NavSection>
  );
}

function UtilityTree({ disabled }) {
  return (
    <NavSection title="Utility">
      <NavLink
        to={ROUTES.SETTINGS}
        Icon={SettingsIcon}
        disabled={disabled}
      >
        Settings
      </NavLink>
      <GettingStartedTrigger />
    </NavSection>
  );
}
