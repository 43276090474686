import { selectActions } from 'src/store/selectors';
import { getPartners } from 'src/api/partners';
import logger from 'src/utils/logger';

const selecCertificationActions = selectActions('certification');
const log = logger.child({ store: 'partner' });

const init = () => ({
  current: null,
  isLoading: false
});

const createActions = (set, get) => ({
  fetch: () => {
    set(state => { state.partner.isLoading = true; }, 'partner/fetchBegin');
    return getPartners()
      .then(([partner]) => set(state => { state.partner.current = partner; }, 'partner/fetchSuccess'))
      .catch(err => log.error('fetchPartner', err))
      .finally(() => set(state => { state.partner.isLoading = false; }, 'partner/fetchFinally'));
  },
  setCurrent: partner => set(state => { state.partner.current = partner; }, 'partner/setCurrent'),
  dispose: () => {
    const { dispose: disposeCertification } = selecCertificationActions(get());
    set(state => { state.partner.current = null; }, 'partner/dispose');
    disposeCertification();
  }
});

export default { init, createActions };
