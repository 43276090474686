import { useHistory } from 'react-router-dom';
import { ROUTES } from 'src/router';
import shallow from 'zustand/shallow';
import { selectAccount } from './selectors';
import useStore, { useActions } from '..';

export const useLogout = () => {
  const history = useHistory();
  const { logout } = useActions('auth');
  return () => {
    logout();
    history.push(ROUTES.LOGIN);
  };
};

export function useAccount() {
  return useStore(selectAccount, shallow);
}
