import { useEffect } from 'react';
import { useRouteMatch, useLocation, matchPath } from 'react-router';
import { useActions } from 'src/store';

export function useDisposeCertificationOnLeave(routes) {
  const location = useLocation();
  const matches = routes
    .map(it => matchPath(location.pathname, { path: it }))
    .filter(Boolean);
  const certificationActions = useActions('certification');

  useEffect(() => {
    if (!matches.length) {
      certificationActions.dispose(null);
    }
  }, [matches.length]);
}

export function useDisposePartnerOnLeave(routes) {
  const location = useLocation();
  const matches = routes
    .map(it => matchPath(location.pathname, { path: it }))
    .filter(Boolean);
  const partnerActions = useActions('partner');
  useEffect(() => {
    if (matches.length) return;
    partnerActions.dispose(null);
  }, [matches.length]);
}

export function useLoadCertificationOnEnter(route) {
  const routeMatch = useRouteMatch(route);
  const certificationActions = useActions('certification');
  useEffect(() => {
    if (routeMatch && routeMatch.params.id) {
      const { id } = routeMatch.params;
      return certificationActions.fetchById(id);
    }
  }, [routeMatch?.params.id]);
}

export function useSetPartnerOnEnter(partners, route) {
  const routeMatch = useRouteMatch(route);
  const partnerActions = useActions('partner');
  useEffect(() => {
    if (routeMatch && routeMatch.params.partnerId) {
      const { partnerId } = routeMatch.params;
      const partner = partners.find(it => it.id === Number(partnerId));
      return partnerActions.setCurrent(partner);
    }
  }, [routeMatch?.params.partnerId]);
}
