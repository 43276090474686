import React, { useState, useCallback } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormHelperText,
  Divider,
  Link,
  Checkbox,
  Typography,
  Tooltip,
  useTheme
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import styled from '@emotion/styled';
import { ROUTES } from 'src/router';
import { useStateWithMountCheck } from 'src/hooks/useIsMountedRef';
import { useActions } from 'src/store';
import { useFormik } from 'formik';
import TextField from 'src/components/common/TextField';
import PasswordTextField from '../PasswordTextField';
import { loginValidationSchema } from '../authValidationSchema';
import { api } from '../../../next/user/userStore';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      width: 'auto'
    },
    '& .MuiButton-root': {
      marginTop: theme.spacing(2)
    }
  },
  whiteColor: {
    color: '#fff'
  }
}));

const BlackCheckbox = styled(Checkbox)`
  padding: none;
  width: 20px;
  height: 22px;
  color: black;

  &.Mui-checked {
    color: black;

    .MuiSvgIcon-root {
      fill: black;
    }
  }
`;

const LoginForm = ({
  handleSubmit,
  isLoading,
  isEmailInvitation,
  error,
  passwordPlaceholder,
  buttonTitle
}) => {
  const classes = useStyles();

  const formBag = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: loginValidationSchema,
    onSubmit: handleSubmit
  });

  return (
    <form className={classes.root} onSubmit={formBag.handleSubmit}>
      <Box display="block" justifyContent="center">
        <TextField
          mt={1}
          name="email"
          label="Email Address"
          variant="outlined"
          formBag={formBag}
          disabled={isLoading}
        />
        <Box>
          <PasswordTextField
            id="password-input"
            mt='0'
            name="password"
            label={passwordPlaceholder}
            variant="outlined"
            disabled={isLoading}
            formBag={formBag}
          />
        </Box>
        {error && (
          <Box>
            <FormHelperText error>{error}</FormHelperText>
          </Box>
        )}
        <Box mt={2}>
          <Button
            id="login-btn"
            variant="contained"
            color="primary"
            size="large"
            disabled={isLoading || !formBag.isValid || !formBag.dirty}
            type="submit"
            data-testid="login-btn"
            sx={ isEmailInvitation ? { textTransform: 'none' } : null}
          >
            {buttonTitle}
          </Button>
        </Box>
        {
          !isEmailInvitation &&
          <>
            <Box my={3}>
              <Divider />
            </Box>
            <Link
              component={RouterLink}
              to={ROUTES.FORGOT_PASSWORD}
              variant="body2"
              color="textSecondary"
              style={{ cssFloat: 'left', textDecoration: 'underline', fontSize: 16 }}
              underline="hover">
              Forgot your password?
            </Link>
          </>
        }
      </Box>
    </form>
  );
};

const ConsentForm = ({
  isLoading,
  handleSubmit
}) => {
  const theme = useTheme();
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = event => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <Box display='flex' justifyContent='space-between'>
        <Box display='flex'>
          <BlackCheckbox
            checked={checked}
            onChange={handleCheckboxChange}
            inputProps={{ 'aria-label': 'controlled' }} />
          <Typography
            display='flex'
            ml={1}>
            Send codes to:
          </Typography>
          <Typography
            fontWeight={700}
            ml={1}>
            xxx-xxx-xxxx
          </Typography>
        </Box>
        <Box>
          <Tooltip
            title={
              <Typography
                color={theme.palette.common.black}
                fontSize={14}>
                Currently not supported.
              </Typography>
            }
          >
            <Link
              disabled
              variant='subtitle1'
              fontSize={11}
              mr={2}
              color={theme.palette.main}
              sx={{ cursor: 'pointer' }}
            >
              Use email instead
            </Link>
          </Tooltip>
        </Box>
      </Box>
      <Typography
        fontSize={11}
        fontWeight={400}
        mt={2}>
        By opting in above, you agree that MLT will use your provided mobile phone number to send two-factor authentication codes.&nbsp;
        Message frequency varies. Message and data rates may apply.&nbsp;
        <Link
          href='https://mlt.org/partners/racial-equity-at-work/terms-of-use/'
          target='_blank'
          color={theme.palette.main}
          sx={{ cursor: 'pointer' }}>
          Terms and Conditions
        </Link>
        &nbsp;apply.
      </Typography>
      <Divider sx={{ mt: 3, mb: 3 }}/>
      <Button
        id='login-btn-step-two'
        variant='contained'
        color='primary'
        size='large'
        disabled={isLoading || !checked}
        type='submit'
        data-testid='login-btn-step-two'
        onClick={handleSubmit}
      >
        Log In
      </Button>
    </>
  );
};

const CognitoLogin = ({
  onBack,
  onNext,
  setProgressLoading,
  isEmailInvitation,
  loginStep
}) => {
  const [error, setError] = useStateWithMountCheck('');
  const [isLoading, setLoading] = useStateWithMountCheck(false);
  const [userLoginInfo, setUserLoginInfo] = useState({ email: '', password: '' });
  const { authenticate } = useActions('auth');
  const history = useHistory();

  const handleLogin = useCallback(async ({ email, password }) => {
    setLoading(true);
    setProgressLoading(true);

    try {
      const { newPasswordRequired, mfaRequired } = await authenticate(email, password);
      if (newPasswordRequired) {
        return history.push(ROUTES.RESET_PASSWORD, { email, password });
      }

      if (mfaRequired) {
        return history.push(ROUTES.MFA_AUTH);
      }

      await api.updateUserLastLogin();
      history.push(ROUTES.NEXT);
    } catch (err) {
      setError(err.message);
      onBack();
    } finally {
      setLoading(false);
      setProgressLoading(false);
    }
  }, [setLoading, setProgressLoading, authenticate, history, setError]);

  const handleNext = userInfo => {
    setUserLoginInfo(userInfo);
    onNext();
  };

  const handleConsent = () => handleLogin(userLoginInfo);

  return isEmailInvitation
    ? (
      loginStep === 1
        ? <LoginForm
          handleSubmit={handleNext}
          isLoading={isLoading}
          isEmailInvitation={isEmailInvitation}
          error={error}
          passwordPlaceholder='Temporary password'
          buttonTitle='Next'
        />
        : <ConsentForm
          handleSubmit={handleConsent}
          isLoading={isLoading}
        />
    )
    : <LoginForm
      handleSubmit={handleLogin}
      isLoading={isLoading}
      isEmailInvitation={isEmailInvitation}
      error={error}
      passwordPlaceholder='Password'
      buttonTitle='Log in'
    />;
};

export default CognitoLogin;
