import React from 'react';
import {
  Chip,
  Typography,
  Card,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  useTheme,
  Button,
  IconButton
} from '@mui/material';
import { MoreVertical as MoreVerticalIcon, UserPlus as UserPlusIcon } from 'react-feather';
import styled from '@emotion/styled';
import usePopperState from 'src/next/util/usePopperState';
import UserActiveLabel from './UserActiveLabel';
import UserActionsPopper from './UserActionsPopper';

const StyledTableCell = styled(TableCell)`
  color: inherit;
`;

const StyledDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 440px;

  @media screen and (max-width: 1350px) {
    max-width: 200px;
  }

  @media screen and (max-width: 1100px) {
    max-width: 100px;
  }
`;

const UserListItem = styled(({
  user,
  isCurrent,
  onEdit,
  onPromote,
  onToggleEnabled,
  isEditHidden,
  isPromoteHidden,
  isToggleEnabledHidden,
  short,
  ...props
}) => {
  const theme = useTheme();
  const [
    anchorEl,
    isPopperOpen,
    onPopperOpen,
    onPopperClose
  ] = usePopperState();
  const areActionsHidden = !isCurrent && isEditHidden && isPromoteHidden && isToggleEnabledHidden;

  return (
    <>
      <TableRow {...props} sx={{ color: !user.enabled && theme.palette.tertiary.dark }}>
        <StyledTableCell>
          <span>
            {user.first} {user.last}
          </span>
          {
            isCurrent &&
            <Chip
              component='span'
              label='ME'
              size='small'
              sx={{ mx: 1, backgroundColor: '#ECEFF1' }}
            />
          }
        </StyledTableCell>
        {
          <>
            {
              !short && <StyledTableCell>
                <UserActiveLabel isEnabled={user.enabled} />
              </StyledTableCell>
            }
            {
              !short && <StyledTableCell>
                <b>
                  Mobile:
                </b>
                &nbsp;{user.mobile}
              </StyledTableCell>
            }
            <StyledTableCell>
              <b>
                Work:
              </b>
              &nbsp;{user.workPhone}
            </StyledTableCell>
            <StyledTableCell>
              <StyledDiv>
                <b>
                  Email:&nbsp;
                </b>
                <a
                  href={`mailto:${user.email}`}
                  target='_blank'
                  rel='noreferrer'
                  style={{ color: user.enabled ? '#000000' : theme.palette.tertiary.dark }}
                >
                  {user.email}
                </a>
              </StyledDiv>
            </StyledTableCell>
          </>
        }
        <StyledTableCell height={74} align='right'>
          {
            !areActionsHidden &&
            <IconButton onClick={onPopperOpen}>
              <MoreVerticalIcon />
            </IconButton>
          }
        </StyledTableCell>
      </TableRow >

      <UserActionsPopper
        open={isPopperOpen}
        anchorEl={anchorEl}
        onClose={onPopperClose}
        user={user}
        isCurrent={isCurrent}
        onEdit={onEdit}
        onPromote={onPromote}
        onToggleEnabled={onToggleEnabled}
        isEditHidden={isEditHidden}
        isPromoteHidden={isPromoteHidden}
        isToggleEnabledHidden={isToggleEnabledHidden}
        isSecondary={user.isPrimary === false}
      />
    </>
  );
})`
  border-top: 1px solid ${props => props.theme.palette.divider};
`;

/**
 * Should be used to display only users of one role at a time, since hidden actions are defined using props.
 */
export default function UserList({
  title,
  addButtonTitle = 'Add User',
  users,
  currentUser,
  onCreate,
  onEdit,
  onPromote,
  onToggleEnabled,
  isCreateHidden,
  isEditHidden,
  isPromoteHidden,
  isToggleEnabledHidden,
  short
}) {
  return (
    <Card sx={{ px: 2 }}>
      <Grid container justifyContent='space-between'>
        <Grid item>
          <Typography variant='subtitle2' p={2}>
            {title}
          </Typography>
        </Grid>
        <Grid item my='auto'>
          {
            !isCreateHidden &&
            <Button
              startIcon={<UserPlusIcon />}
              onClick={onCreate}
            >
              {addButtonTitle}
            </Button>
          }
        </Grid>
      </Grid>
      <Table>
        <TableBody>
          {users.map(u => {
            const isCurrent = currentUser.id === u.id;

            return (
              <UserListItem
                user={u}
                key={u.id}
                isCurrent={isCurrent}
                onEdit={onEdit}
                onPromote={onPromote}
                onToggleEnabled={onToggleEnabled}
                isEditHidden={isEditHidden}
                isPromoteHidden={isPromoteHidden}
                isToggleEnabledHidden={isToggleEnabledHidden}
                short={short}
              />
            );
          })}
        </TableBody>
      </Table>
    </Card>
  );
}
