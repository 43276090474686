import { DateTime } from 'luxon';

export const DATETIME_FORMAT = 'LL/dd/yyyy t';
export const DATE_FORMAT = 'L/d/yyyy';

export function compareByDate(prop) {
  return (first, second) => new Date(second[prop]) - new Date(first[prop]);
}

export function prettifyDateTime(datetime, format = DATETIME_FORMAT) {
  return DateTime
    .fromJSDate(new Date(datetime))
    .toFormat(format);
}
