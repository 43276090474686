import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

const styleProps = new Set(['origin', 'deg']);

const StyledBox = styled(Box, { shouldForwardProp: prop => !styleProps.has(prop) })`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    transform: skew(${({ deg }) => deg}deg, 0deg);
    transform-origin: ${({ origin }) => origin};
    background-color: inherit;
  }
`;

function SingleSideSkew({ children, origin = 'bottom left', deg = -33, ...props }) {
  return (
    <StyledBox origin={origin} deg={deg} {...props}>
      {children}
    </StyledBox>
  );
}

export default SingleSideSkew;
