import React from 'react';
import styled from '@emotion/styled';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  Typography
} from '@mui/material';
import { prettifyDateTime } from '../../utils/datetime';

const DateChip = styled(props => (
  <Chip size="small" {...props} />
))`
  height: 18px;
  margin-left: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.spacing(0.25)};

  .MuiChip-label {
    padding: ${({ theme }) => theme.spacing(0, 2)};
  }
`;

const AppointmentSubject = styled(props => (
  <Typography variant="body3" {...props} />
))`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-top: ${({ theme }) => theme.spacing(1)};
  white-space: unset;
  overflow: hidden;
`;

const StyledDivider = styled(Divider)`
  margin: 0;
`;

function AppointmentItemWrapper({ children }) {
  return (
    <>
      <StyledDivider
        variant="middle"
        component="li"
      />
      <ListItem sx={{ py: 2 }}>
        {children}
      </ListItem>
    </>
  );
}

function AppointmentListItem({ appointment }) {
  const formattedDateTime = prettifyDateTime(appointment.startDateTime);
  const [date, time] = formattedDateTime.split(' ');

  return (
    <Grid container>
      <Grid item xs={true} container direction="column">
        <Typography variant="microtextBold" componen="p">
          {date}
        </Typography>
        <AppointmentSubject>
          {appointment.subject}
        </AppointmentSubject>
      </Grid>
      <Grid item xs="auto" container>
        <DateChip label={<Typography variant="microtext1">{time}</Typography>}/>
      </Grid>
    </Grid>
  );
}

function AppointmentEmptyItem() {
  return (
    <Typography variant="body3" noWrap>
      You have no meetings scheduled
    </Typography>
  );
}

export default function AppointmentList({
  appointments = [],
  width = 340,
  maxHeight = 480,
  calendlyUrl,
  onRequestAppointment,
  ...props
}) {
  return (
    <Card {...props} sx={{ width }}>
      <CardHeader
        title="Upcoming PSM Meetings"
        titleTypographyProps={{ variant: 'subtitle2' }}
      />
      <CardContent sx={{ maxHeight, p: 0, overflowY: 'auto' }}>
        <List sx={{ p: 0 }}>
          { appointments && appointments.length
            ? appointments.map(appointment => (
              <AppointmentItemWrapper key={appointment.id}>
                <AppointmentListItem appointment={appointment} />
              </AppointmentItemWrapper>
            ))
            : <AppointmentItemWrapper>
              <AppointmentEmptyItem />
            </AppointmentItemWrapper>
          }
        </List>
      </CardContent>
      <StyledDivider/>
      <CardActions sx={{ p: 2 }}>
        <Button
          href={calendlyUrl}
          target="_blank"
          disabled={!calendlyUrl}
          size="medium"
          sx={{ width: '100%' }}
          onClick={onRequestAppointment}
        >
          REQUEST
        </Button>
      </CardActions>
    </Card>
  );
}
