import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Card, CardActionArea, CardContent, CardHeader, Typography } from '@mui/material';
import { ArrowRight } from 'react-feather';
import WelcomeSlider from 'src/components/WelcomeSlider';
import { useAccount } from 'src/store/auth/hooks';
import { useActions } from 'src/store';

const StyledCardActionArea = styled(CardActionArea)`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.tertiary.lighter};
  border-radius: 4.5px;
`;

const Thumbnail = styled.img`
  flex-shrink: 0;
  width: 37%;
`;

const CopyText = styled(Typography)`
  flex-grow: 1;
`;

const ArrowRightIcon = styled(ArrowRight)`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: ${props => props.theme.spacing(2)};
  color: #263238;
`;

export default function GettingStartedWidget() {
  const { update: updateAccount } = useActions('auth');
  const { firstLogin } = useAccount();
  const [open, setOpen] = useState(firstLogin);

  const closeAndRemember = () => {
    setOpen(false);
    updateAccount({ firstLogin: false });
  };

  return (
    <Card>
      <CardHeader title="Getting Started" titleTypographyProps={{ variant: 'subtitle2' }} />
      <CardContent sx={{ p: 2, pt: 0 }}>
        <StyledCardActionArea onClick={() => setOpen(true)}>
          <Thumbnail src="/static/next/getting-started-thumbnail.jpeg" draggable={false} />
          <CopyText variant="body4" sx={{ px: 2, py: 1 }}>
            Learn more about the Racial Equity at Work program, and see what information you&apos;ll
            need to collect in order to complete your Baseline Assessment.
          </CopyText>
          <ArrowRightIcon />
        </StyledCardActionArea>
      </CardContent>
      <WelcomeSlider onClose={closeAndRemember} open={open} />
    </Card>
  );
}
