import React, { useState } from 'react';
import { Modal, Box, Typography, IconButton, Button } from '@mui/material';
import { AttachFile, Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/system';
import useStore from '../certification/certificationStore';

const ModalBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: 700,
  backgroundColor: theme.palette.tertiary.lightest,
  padding: theme.spacing(4)
}));

const UploadField = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  cursor: 'pointer',
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.background.paper
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: '40px',
  marginTop: '12px'
}));

const UploadInput = styled('input')({
  display: 'none'
});

const StyledActionButton = styled(({ isDelete, backgroundColor, ...props }) => <Button {...props} />)(({ theme, isDelete, backgroundColor }) => ({
  marginTop: theme.spacing(3),
  marginRight: theme.spacing(2),
  backgroundColor: isDelete ? theme.palette.error.main : backgroundColor
}));

const CancelText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: 'underline',
  cursor: 'pointer',
  marginTop: theme.spacing(3),
  marginLeft: theme.spacing(2)
}));

const actionMap = {
  Add: {
    title: 'Upload a Partner Report',
    buttonLabel: 'Upload',
    description: 'Click the field below to upload partner report PDF.',
    onClick: async ({ certificationId, certificationStepId, file, handleAssetUpload, onClose }) => {
      if (file) {
        await handleAssetUpload(certificationId, certificationStepId, file);
        onClose();
      }
    }
  },
  Replace: {
    title: 'Replace a Partner Report',
    buttonLabel: 'Replace',
    description: 'Click the field below to upload a replacement partner report PDF.',
    onClick: async ({ certificationId, certificationStepId, file, handleAssetUpload, onClose }) => {
      if (file) {
        await handleAssetUpload(certificationId, certificationStepId, file);
        onClose();
      }
    }
  },
  Remove: {
    title: 'Remove Report?',
    buttonLabel: 'Remove',
    description: 'Are you sure you want to remove this report?',
    onClick: async ({ asset, onClose, certificationId, certificationStepId, handleAssetDelete }) => {
      if (asset) {
        await handleAssetDelete(certificationId, certificationStepId);
        onClose();
      }
    }
  }
};

const FileUploadModal = ({
  open,
  onClose,
  action,
  asset,
  certificationId,
  certificationStepId,
  onAssetDeleted,
  onAssetUploaded,
  themeColor
}) => {
  const [file, setFile] = useState(null);
  const { uploadStepAsset, deleteStepAsset, getStepAssets } = useStore();

  const handleAssetUpload = async (certificationId, certificationStepId, file) => {
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData.append('file', file);
    await uploadStepAsset(certificationId, certificationStepId, formData);
    const [newAsset] = await getStepAssets(certificationId, certificationStepId);
    onAssetUploaded(newAsset);
  };

  const handleAssetDelete = async (certificationId, certificationStepId) => {
    await deleteStepAsset(certificationId, certificationStepId);
    onAssetDeleted();
  };

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleDiscard = () => {
    setFile(null);
    document.getElementById('file-upload').value = '';
  };

  const currentAction = actionMap[action] || actionMap.Add;
  const isDeleteModal = action === 'Remove';

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-title" aria-describedby="modal-description">
      <ModalBox>
        <Typography id="modal-title" variant="h5">
          {currentAction.title}
        </Typography>
        <Typography id="modal-description" variant="body1" sx={{ mt: 2 }}>
          {currentAction.description}
        </Typography>
        {!isDeleteModal && (
          <>
            {file && (
              <CloseButton color="error" size="small" onClick={handleDiscard}>
                <CloseIcon />
              </CloseButton>
            )}
            <label htmlFor="file-upload">
              <UploadField>
                <IconButton component="span">
                  <AttachFile />
                </IconButton>
                <Typography variant="body1" sx={{ flexGrow: 1, textAlign: 'left' }}>
                  {file ? file.name : 'Insert a PDF'}
                </Typography>
                <Typography variant="body1" sx={{ flexGrow: 1, textAlign: 'right' }} mr={4}>
                  {file ? `${(file.size / 1024).toFixed(2)} KB` : ''}
                </Typography>
              </UploadField>
            </label>
            <UploadInput type="file" id="file-upload" onChange={handleFileChange} accept="application/pdf" />
          </>
        )}
        {isDeleteModal && (
          <Typography variant="subtitle2" sx={{ mt: 3 }}>
            {asset?.originalName}
          </Typography>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
          <StyledActionButton
            onClick={() => currentAction.onClick({ file, onClose, asset, certificationId, certificationStepId, handleAssetDelete, handleAssetUpload })}
            isDelete={isDeleteModal}
            disabled={!file && !isDeleteModal}
            backgroundColor={themeColor}
          >
            {currentAction.buttonLabel}
          </StyledActionButton>
          <CancelText onClick={onClose}>Cancel</CancelText>
        </Box>
      </ModalBox>
    </Modal>
  );
};

export default FileUploadModal;
