import React, { useState } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import NavButton from 'src/components/Navigation/Button';
import WelcomeSlider from 'src/components/WelcomeSlider';
import { useAccount } from 'src/store/auth/hooks';
import { useActions } from 'src/store';

function GettingStartedLink() {
  const { update: updateAccount } = useActions('auth');
  const { firstLogin } = useAccount();
  const [open, setOpen] = useState(firstLogin);

  const closeAndRemember = () => {
    setOpen(false);
    updateAccount({ firstLogin: false });
  };

  return (
    <>
      <NavButton onClick={() => setOpen(true)} Icon={HelpOutlineIcon}>
        Getting Started
      </NavButton>
      <WelcomeSlider onClose={closeAndRemember} open={open} />
    </>
  );
}

export default GettingStartedLink;
