import { useEffect } from 'react';
import { useActions } from 'src/store';

export function useFetchPartners() {
  const { fetch, setPartners } = useActions('partners');
  useEffect(() => {
    fetch();
    return () => setPartners(null);
  }, []);
}
