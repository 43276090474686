import axios from 'axios';
import { getUsers, getCertificationPsTeamMembers } from 'src/api/users';
import { getEmployees, addEmployee } from 'src/api/employees';
import { API_URL, ASSIGNMENT_TYPES } from '../../constants';

const USERS_URL = `${API_URL}/users`;
const CERTIFICATIONS_URL = `${API_URL}/certifications`;

export const api = {
  addAdmin(data) {
    return axios.post(`${USERS_URL}/admin`, data);
  },
  updateAdmin(id, data) {
    return axios.patch(`${USERS_URL}/admin/${id}`, data);
  },
  updateLoggedInUser(data) {
    return axios.patch(`${USERS_URL}`, data);
  },
  enable(userIds) {
    return axios.put(`${USERS_URL}/enable`, { userIds });
  },
  disable(userIds) {
    return axios.put(`${USERS_URL}/disable`, { userIds });
  },
  updateEmployee(certificationId, employeeId, data) {
    return axios.patch(`${CERTIFICATIONS_URL}/${certificationId}/employees/${employeeId}`, data);
  },
  promoteEmployee(certificationId, employeeId) {
    return axios.put(`${CERTIFICATIONS_URL}/${certificationId}/employees/${employeeId}/promote`);
  },
  sendToDestination(name) {
    return axios.post(`${USERS_URL}/me/destination/${name}`);
  },
  async updateUserLastLogin() {
    return await axios.put(`${USERS_URL}/last-login`);
  },
  async resetPassword(username) {
    return await axios.patch(`${USERS_URL}/reset-password/${username}`);
  }
};

export default set => ({
  users: [],
  psTeamMembers: [],

  async getUsers() {
    const data = await getUsers({ certificationAssignments: true });

    // Users can currently belong only to one certification, so compute `isPrimary` immediately from first certification
    const users = data.map(u => {
      const assignmentType = u?.certificationAssignments?.[0]?.type;

      if (assignmentType === ASSIGNMENT_TYPES.PRIMARY_EMPLOYEE) {
        return { ...u, isPrimary: true };
      }

      if (assignmentType === ASSIGNMENT_TYPES.SECONDARY_EMPLOYEE) {
        return { ...u, isPrimary: false };
      }

      return u;
    });

    set(state => ({ users }), 'GET_USERS');
  },

  async getEmployees(certificationId) {
    const data = await getEmployees(certificationId);

    set(state => ({ users: data }), 'GET_EMPLOYEES');
  },

  async addAdmin(admin) {
    const data = await api.addAdmin(admin);

    set(state => state.users.push(data), 'ADD_ADMIN');
  },

  async updateAdmin(id, admin) {
    const data = await api.updateAdmin(id, admin);

    set(state => ({
      users: state.users.map(u => u.id === data.id ? { ...u, ...data } : u)
    }), 'UPDATE_ADMIN');
  },

  async updateLoggedInUser(user) {
    const { data } = await api.updateLoggedInUser(user);

    set(state => ({
      users: state.users.map(u => u.id === data.id ? { ...u, ...data } : u)
    }), 'UPDATE_LOGGED_IN_USER');
  },

  async enable(userId) {
    const { result } = await api.enable([userId]);
    const status = result[userId];

    if (!status.success) {
      throw new Error(status.error);
    }

    set(state => ({ users: state.users.map(u => u.id === userId ? { ...u, enabled: true } : u) }));
  },

  async disable(userId) {
    const { result } = await api.disable([userId]);
    const status = result[userId];

    if (!status.success) {
      throw new Error(status.error);
    }

    set(state => ({ users: state.users.map(u => u.id === userId ? { ...u, enabled: false } : u) }));
  },

  resetUsers() {
    set(state => ({ users: [] }), 'RESET_USERS');
  },

  async addEmployee(certificationId, employee) {
    const data = await addEmployee(certificationId, employee);

    set(state => state.users.push({ isPrimary: false, enabled: true, ...data }), 'ADD_EMPLOYEE');
  },

  async updateEmployee(certificationId, employeeId, employee) {
    const data = await api.updateEmployee(certificationId, employeeId, employee);

    set(state => ({
      users: state.users.map(e => e.id === data.id ? { ...e, ...data } : e)
    }), 'UPDATE_EMPLOYEE');
  },

  async promoteEmployee(certificationId, employeeId) {
    const { primary, secondary } = await api.promoteEmployee(certificationId, employeeId);

    set(state => ({
      users: state.users.map(e => {
        if (e.id === primary.id) {
          return { ...e, isPrimary: true };
        } else if (e.id === secondary.id) {
          return { ...e, isPrimary: false };
        } else {
          return e;
        }
      })
    }), 'PROMOTE_EMPLOYEE');
  },

  async getPsTeamMembers(certificationId) {
    const data = await getCertificationPsTeamMembers(certificationId);

    set(state => ({ psTeamMembers: data }), 'GET_PS_TEAM_MEMBERS');
  },

  resetPsTeamMembers() {
    set(state => ({ psTeamMembers: [] }), 'RESET_PS_TEAM_MEMBERS');
  },

  sendToDestination: api.sendToDestination
});
