import { useMemo } from 'react';
import { matchPath, useLocation, generatePath } from 'react-router-dom';
import { ROLES } from 'src/constants';
import routes from 'src/next/navigation/routes';
import logger from 'src/utils/logger';
import { getCertificationStepLabel } from '../certification/certificationUtil';
import useStore from '../store';

function getBreadcrumbsAtPath(breadcrumbs, path) {
  const routes = [];

  try {
    const paths = path.split('.');
    let target = breadcrumbs;

    while (paths.length) {
      const prop = paths.shift();

      target = target[prop];
      routes.push([target.url ?? '', target.name ?? '']);
    }
  } catch (err) {
    logger.error(err);
  }

  return routes;
}

// The breadcrumbs will only show data about entities if they are loaded into the store by other components.
export default function useBreadcrumbs() {
  const { user, hasRole } = useStore(state => state.auth);
  const { certificationSteps, pillars } = useStore(state => state.certification);
  const location = useLocation();
  const [match] = Object.entries(routes)
    .map(([name, path]) => matchPath(location.pathname, { path, exact: true }))
    .filter(m => m);
  const params = match?.params ?? {}; // Unable to use useParams outside of Route hierarchy
  const { certificationId, certificationStepId, pillarId, indicatorId } = params;
  const certificationStep = certificationSteps.find(cs => cs.id === +certificationStepId) ?? {};
  const pillar = pillars?.find(p => p.id === +pillarId) ?? {};
  const indicator = pillar.indicators?.find(i => i.id === +indicatorId) ?? {};

  const breadcrumbsByRoute = useMemo(() => {
    const breadcrumbs = {
      home: {
        name: hasRole(ROLES.EMPLOYEE) ? 'My Programs' : 'Partners',
        url: routes.HOME,
        certification: {
          name: 'Dashboard',
          url: certificationId && generatePath(routes.CERTIFICATION, { certificationId }),
          users: {
            name: 'Users',
            url: certificationId && generatePath(routes.CERTIFICATION_USERS, { certificationId })
          },
          step: {
            name: getCertificationStepLabel(certificationStep),
            url: certificationId && certificationStepId && generatePath(routes.CERTIFICATION_STEP, { certificationId, certificationStepId }),
            pillar: {
              name: pillar.name,
              url: certificationId && certificationStepId && pillarId &&
                generatePath(routes.CERTIFICATION_PILLAR, { certificationId, certificationStepId, pillarId }),
              indicator: {
                name: indicator.name,
                url: certificationId && certificationStepId && pillarId && indicatorId &&
                generatePath(routes.CERTIFICATION_INDICATOR, { certificationId, certificationStepId, pillarId, indicatorId })
              }
            }
          }
        }
      },
      users: {
        name: 'Users',
        url: routes.ADMINISTRATION_USERS
      }
    };

    return {
      [routes.ADMINISTRATION_USERS]: getBreadcrumbsAtPath(breadcrumbs, 'users'),
      [routes.HOME]: getBreadcrumbsAtPath(breadcrumbs, 'home'),
      [routes.CERTIFICATION]: getBreadcrumbsAtPath(breadcrumbs, 'home.certification'),
      [routes.CERTIFICATION_USERS]: getBreadcrumbsAtPath(breadcrumbs, 'home.certification.users'),
      [routes.CERTIFICATION_STEP]: getBreadcrumbsAtPath(breadcrumbs, 'home.certification.step'),
      [routes.CERTIFICATION_PILLAR]: getBreadcrumbsAtPath(breadcrumbs, 'home.certification.step.pillar'),
      [routes.CERTIFICATION_INDICATOR]: getBreadcrumbsAtPath(breadcrumbs, 'home.certification.step.pillar.indicator')
    };
  }, [certificationId, certificationStepId, pillarId, certificationSteps, pillars, user, hasRole, indicatorId]);

  return breadcrumbsByRoute[match?.path] ?? [];
}
