import { useMemo } from 'react';
import themeByAssessmentType from './themeByAssessmentType';
import useAssessmentType from './useAssessmentType';

/*
  Avoid returning the theme provider component that changes based on the current assessment
  type, i.e. theme, to avoid recreating child components encompassed by the theme provider,
  and additional requests accompanied by it. Instead, just the theme is provided and consumers
  should apply it through the MUI theme provider component.
 */
function useAssessmentTheme(certification) {
  const currentAssessmentType = certification
    ? certification.assessmentType?.title
    : useAssessmentType();

  return useMemo(
    // When there is no assessment theme to apply, just use the base one.
    () => themeByAssessmentType[currentAssessmentType] ?? {},
    [currentAssessmentType]
  );
}

export default useAssessmentTheme;
