import styled from '@emotion/styled';
import {
  Box,
  Card,
  Chip,
  Divider,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { findKey, head, upperFirst, cond, matches, constant, stubTrue } from 'lodash';
import React, { useMemo } from 'react';
import { ROLES, CERTIFICATION_STEP_TYPES } from 'src/constants';
import writtenNumber from 'written-number';
import { getCompletedIndicatorsCount, getPillarRoute, getIndicatorRoute } from './certificationUtil';

const TOTAL_SCORE_CONTAINER_WIDTH = 124;
const { BASELINE } = CERTIFICATION_STEP_TYPES;

function getStatusLabel({ isApproved, isReview, isDraft }) {
  if (isApproved) {
    return 'APPROVED';
  }

  if (isDraft) {
    return 'IN PROGRESS';
  }

  if (isReview) {
    return 'IN REVIEW';
  }

  return 'UNKNOWN';
}

const getStatusDescription = (state, incompleteCount, incompleteCountWord, IncompleteIndicatorIcon) => {
  const isEvaluation = state.stepType === 'evaluation';
  return cond([
    // begin assessment
    [
      matches({ hasStarted: false, userRole: ROLES.EMPLOYEE }),
      constant({
        text: 'Click the button above to begin your Assessment. We have provided guidance throughout the Assessment to help you succeed on your path towards racial equity.'
      })
    ],
    // assessment submitted
    [
      matches({ isReview: true, userRole: ROLES.EMPLOYEE }),
      constant({
        text: 'Thank you for submitting your Assessment. Your PSM is reviewing your Assessment and will notify you once the review is complete.'
      })
    ],
    // assessment approved
    [
      matches({ isApproved: true, userRole: ROLES.EMPLOYEE }),
      constant({
        headingColor: 'secondary',
        heading: `${isEvaluation ? 'Your Certification Assessment' : 'Your Baseline Assessment'} has been approved!`,
        text: state.stepType === BASELINE && 'Congratulations! Your Assessment has been approved. Please continue your efforts to improve racial equity within your organization.'
      })
    ],
    // assessment in progress
    [
      matches({ isCompleted: false, userRole: ROLES.EMPLOYEE }),
      constant({
        headingColor: 'error',
        heading: <>
          {incompleteCountWord} ({incompleteCount}) indicator{incompleteCount === 1 ? '' : 's'} need{incompleteCount === 1 ? 's' : ''} attention before you can submit your results.
        </>,
        text: 'Click the button above to continue your Assessment. You can also navigate anywhere within the Assessment by clicking on the Indicator names on the left side of this page.'
      })
    ],
    // assessment ready to be submitted
    [
      matches({ isCompleted: true, userRole: ROLES.EMPLOYEE }),
      constant({
        heading: `When you have completed your ${state.stepType}, submit it for careful review and comment by MLT staff.`,
        text: <>
          Once your {state.stepType} is submitted, it can
          <b>
            &nbsp;no longer be edited&nbsp;
          </b>
          unless it is sent back to you from your MLT representative for further refinement.
        </>
      })
    ],
    // assessment ready for review
    [
      matches({ isReview: true }),
      constant({
        text: 'This Assessment is ready for your review. Please examine this Assessment to determine if the information is correct, then Approve or Reject as necessary.'
      })
    ],
    // assessment approved
    [
      matches({ isApproved: true }),
      constant({
        text: 'This Assessment has been Approved. No further action is required on this Assessment.'
      })
    ],
    // assessment in progress
    [
      stubTrue,
      constant({
        text: 'This Assessment is still in progress. Once this partner has submitted the Assessment, you will be able to Approve or Reject as necessary.'
      })
    ]
  ])(state);
};

const StatusChip = styled(Chip)`
  background-color: ${({ theme }) => theme.palette.tertiary.lighter};
`;

const TotalScoreRibbon = styled(Box)`
  position: relative;
  min-width: ${TOTAL_SCORE_CONTAINER_WIDTH}px;
  height: 130px;
  background: ${({ theme }) => theme.palette.gradient.primary};
  /*
    NOTE: For now it is only possible to apply box shadow to the left and right sides.
    Because of the use of :after, box-shadow cannot be applied to the bottom. Another
    alternative is to use an image if ever provided.
  */
  box-shadow: ${({ theme }) => theme.shadows[6]};

  & > * {
    margin: auto;
    margin-top: 12px;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    border-left: ${TOTAL_SCORE_CONTAINER_WIDTH / 2}px solid transparent;
    border-right: ${TOTAL_SCORE_CONTAINER_WIDTH / 2}px solid transparent;
    border-bottom: 16px solid white;
  }
`;

const TotalScore = styled(({ children, ...props }) => (
  <Box {...props}>
    <Typography variant="h4" color="primary.dark">
      {children}
    </Typography>
  </Box>
))`
  width: 90px;
  height: 90px;
  background-color: ${({ theme }) => theme.palette.primary.lighter};
  border: 2px solid ${({ theme }) => theme.palette.primary.dark};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLoadingButton = styled(LoadingButton)`
  width: 100%;
  margin: ${({ theme }) => theme.spacing(1, 0)};
`;

const StatusButtons = ({
  certificationId,
  certificationStepId,
  pillarId,
  firstIncomplete,
  isDraft,
  isReview,
  isCompleted,
  hasStarted,
  isCurrent,
  onSubmit,
  onApprove,
  onReject,
  isSubmitting,
  isApproving,
  isRejecting,
  userRole,
  ...props
}) => {
  const showBeginButton = ![ROLES.STAFF, ROLES.ADMIN].includes(userRole);
  const showContinueButton = ![ROLES.STAFF, ROLES.ADMIN].includes(userRole) && isDraft && !isCompleted;
  const showSubmitButton = userRole !== ROLES.STAFF;
  const showReviewButtons = userRole !== ROLES.EMPLOYEE;

  return (
    <Box {...props}>
      {
        !hasStarted && showBeginButton
          ? <StyledLoadingButton to={getPillarRoute(certificationId, certificationStepId, pillarId)}>
            Begin Assessment
          </StyledLoadingButton>
          : <Box mr={2}>
            {
              showContinueButton &&
              <StyledLoadingButton to={getIndicatorRoute(certificationId, certificationStepId, firstIncomplete.pillarId, firstIncomplete.indicatorId)}>
                Continue Assessment
              </StyledLoadingButton>
            }
            {
              showSubmitButton &&
              <StyledLoadingButton
                color="secondary"
                loading={isSubmitting}
                disabled={!isDraft || !isCompleted || isApproving }
                onClick={onSubmit}
              >
                {!isDraft ? 'Assessment Submitted' : 'Submit Assessment'}
              </StyledLoadingButton>
            }
            {
              showReviewButtons &&
              <>
                <StyledLoadingButton
                  color="secondary"
                  loading={isApproving}
                  disabled={!isReview || isRejecting }
                  onClick={onApprove}
                >
                  Approve Assessment
                </StyledLoadingButton>
                <StyledLoadingButton
                  color="error"
                  loading={isRejecting}
                  disabled={!isReview || isApproving }
                  onClick={onReject}
                >
                  Reject Assessment
                </StyledLoadingButton>
              </>
            }
          </Box>
      }
    </Box>
  );
};

function getFirstIncomplete(completion, pillars) {
  const firstIncompleteIndicatorId = Number(findKey(completion.indicatorCompletion, item => item.total > item.completed));
  const firstIncompletePillar = firstIncompleteIndicatorId &&
    pillars.find(pillar => pillar.indicators.some(indicator => indicator.id === firstIncompleteIndicatorId));
  return { pillarId: firstIncompletePillar?.id, indicatorId: firstIncompleteIndicatorId };
}

export default function CertificationStepSummaryStatus({
  scores,
  completion,
  certificationStep,
  pillars,
  isCurrent,
  isDraft,
  isReview,
  isApproved,
  onSubmit,
  onApprove,
  onReject,
  isSubmitting,
  isApproving,
  isRejecting,
  userRole,
  Header,
  IncompleteIndicatorIcon
}) {
  const totalIndicatorsCount = Object.keys(completion.indicatorCompletion).length;
  const completedIndicatorsCount = getCompletedIndicatorsCount(completion.indicatorCompletion);
  const incompleteCount = totalIndicatorsCount - completedIndicatorsCount;
  const firstIncomplete = getFirstIncomplete(completion, pillars);
  const stepType = certificationStep.type;
  const isCompleted = incompleteCount === 0;
  const hasStarted = completion.completed > 0;
  const firstPillar = head(pillars);
  const statusLabel = getStatusLabel({ isApproved, isReview, isDraft });
  const incompleteCountWord = useMemo(() => upperFirst(writtenNumber(incompleteCount)), [incompleteCount]);
  const description = useMemo(() => getStatusDescription(
    { stepType, userRole, hasStarted, isCompleted, isCurrent, isDraft, isReview, isApproved },
    incompleteCount,
    incompleteCountWord,
    IncompleteIndicatorIcon
  ), [
    stepType,
    userRole,
    hasStarted,
    isCompleted,
    isCurrent,
    isDraft,
    isReview,
    isApproved,
    incompleteCount,
    incompleteCountWord,
    IncompleteIndicatorIcon
  ]);

  const StatusButtonsComponent = props => (
    <StatusButtons
      {...props}
      certificationId={certificationStep.certification.id}
      certificationStepId={certificationStep.id}
      pillarId={firstPillar?.id}
      firstIncomplete={firstIncomplete}
      isCompleted={isCompleted}
      hasStarted={hasStarted}
      isDraft={isDraft}
      isReview={isReview}
      isCurrent={isCurrent}
      onSubmit={onSubmit}
      onApprove={onApprove}
      onReject={onReject}
      isSubmitting={isSubmitting}
      isApproving={isApproving}
      isRejecting={isRejecting}
      userRole={userRole}
    />
  );

  return (
    <Card>
      <Header justifyContent='space-between'>
        <Typography variant="h4" component="span">
          Review Status
        </Typography>
        <StatusChip
          size="small"
          label={<Typography component="p" variant="smallLabel2">{statusLabel}</Typography>}
        />
      </Header>
      <Box p={2}>
        {
          <Box mb={2} display='flex' justifyContent='space-between'>
            <Box>
              <Typography variant='h2'>
                Total Score
              </Typography>
              <StatusButtonsComponent />
            </Box>
            <TotalScoreRibbon mt={-2}>
              <TotalScore>
                {scores.total}
              </TotalScore>
            </TotalScoreRibbon>
          </Box>
        }
        <Divider />
        <Typography variant="h5" my={2} color={description.headingColor}>
          {description.heading}
        </Typography>
        <Typography>
          {description.text}
        </Typography>
      </Box>
    </Card>
  );
}
