import React from 'react';
import DashboardLayout from 'src/layouts/DashboardLayout';
import useStore from 'src/store';
import Router from './router';
import { useFetchPartners } from './store/partners/hooks';
import { selectPartners } from './store/partners/selectors';
import Navigation from './components/Navigation';

function App() {
  const partners = useStore(selectPartners);
  useFetchPartners();

  if (!partners) return null;
  return (
    <DashboardLayout renderNavigation={() => <Navigation />}>
      <Router />
    </DashboardLayout>
  );
}

export default App;
