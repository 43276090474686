import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { Box, Button, InputLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ROUTES } from 'src/router';

const REDIRECT_DELAY = 5000;

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      width: 'auto'
    },
    '& .MuiButton-root': {
      marginTop: theme.spacing(2)
    }
  },
  whiteColor: {
    color: '#fff'
  }
}));

const SuccessView = ({ className, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    history.push(ROUTES.LOGIN);
  };

  useEffect(() => {
    setTimeout(handleClick, REDIRECT_DELAY);
  }, []);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box>
        <InputLabel sx={{ whiteSpace: 'normal' }}>
          Password Changed Successfully. You will be redirected to the Login page in 5 seconds.
        </InputLabel>
        <Button onClick={handleClick} variant="contained" color="primary" size="large" fullWidth>
          Return to Login
        </Button>
      </Box>
    </div>
  );
};

export default SuccessView;
