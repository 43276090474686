import React, { useEffect } from 'react';
import ActionsPopper from '../mui/ActionsPopper';

export default function UserActionsPopper({
  anchorEl,
  open,
  onClose,
  user,
  isCurrent,
  onEdit,
  onPromote,
  onPasswordReset,
  onToggleEnabled,
  isEditHidden,
  isPromoteHidden,
  isToggleEnabledHidden,
  isSecondary,
  certificationId,
  setCurrentUserCertificationId,
  isAdmin,
  isDeactivated
}) {
  const handleActionClick = action => {
    action(user);
    if (certificationId) setCurrentUserCertificationId(certificationId);
  };

  useEffect(() => {
    const handleEscapeKeyPress = event => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keyup', handleEscapeKeyPress);

    return () => {
      document.removeEventListener('keyup', handleEscapeKeyPress);
    };
  }, []);

  return (
    <ActionsPopper
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    >
      {
        ((isCurrent || !isEditHidden) && !isDeactivated) &&
        <span onClick={() => handleActionClick(onEdit)}>
          Edit User
        </span>
      }
      {
        (isSecondary && !isPromoteHidden && !isDeactivated) &&
        <span onClick={() => handleActionClick(onPromote)}>
          Promote
        </span>
      }
      {
        (!isCurrent && !isToggleEnabledHidden) &&
        <span onClick={() => handleActionClick(onToggleEnabled)}>
          {
            user.enabled
              ? 'Deactivate'
              : 'Activate'
          }
        </span>
      }
      {
        isAdmin &&
        <span onClick={() => handleActionClick(onPasswordReset)}>
          Reset Password
        </span>
      }
    </ActionsPopper>
  );
}
