import React from 'react';
import styled from '@emotion/styled';
import { LinearProgress } from '@mui/material';

const ProgressBar = styled(
  LinearProgress
)`
  width: 72px;
  height: 16px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.palette.tertiary.main};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.tertiary.lightest};
  box-shadow: ${({ theme }) => theme.shadows[3]};

  & .MuiLinearProgress-bar {
    border-radius: 8px;
    box-shadow: ${({ theme }) => theme.shadows[3]};
    background-color: ${({ theme }) => theme.palette.primary.main};
  )};
  }
`;

export default function CertificationProgressBar({ value }) {
  return (
    <ProgressBar variant="determinate" value={value} />
  );
}
