import { useRef, useEffect, useState } from 'react';

const useIsMountedRef = () => {
  const isMounted = useRef(true);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  return isMounted;
};

export function useStateWithMountCheck(...args) {
  const isMountedRef = useIsMountedRef();
  const [state, originalSetState] = useState(...args);

  const setState = (...args2) => {
    if (isMountedRef.current) {
      originalSetState(...args2);
    }
  };

  return [state, setState];
}

export default useIsMountedRef;
