import logger from 'src/utils/logger';
import {
  getSession,
  authenticate,
  logout
} from 'src/utils/auth';
import { getProfile, updateUser, updateUserAssignment } from 'src/api/users';
import { selectActions } from '../selectors';

const log = logger.child({ store: 'auth' });

const init = () => ({
  isSessionLoaded: false,
  account: undefined
});

const selectAuthActions = selectActions('auth');
const createActions = (set, get) => ({
  loadSession: async () => {
    try {
      const session = await getSession();
      if (!session) return;
      const account = await getProfile();
      set(state => { state.auth.account = account; }, 'auth/loadSessionSuccess');
    } catch (error) {
      log.error(error);
    } finally {
      set(state => { state.auth.isSessionLoaded = true; }, 'auth/loadSessionFinally');
    }
  },
  authenticate: async (...args) => {
    const result = await authenticate(...args);
    const { loadSession } = selectAuthActions(get());
    await loadSession();
    return result;
  },
  logout: () => {
    logout();
    return get().reset();
  },
  updateAssignment: (account, { certification }) => {
    return updateUserAssignment(certification, { seenPlanGuide: true })
      .then(assignment => {
        const assignments = account.certificationAssignments.map(it => {
          if (it.certification === assignment.certification) return assignment;
          return it;
        });
        set(
          state => { state.auth.account = { ...account, certificationAssignments: assignments }; },
          'auth/updateAssignment');
      });
  },
  update: async data => {
    set(state => { state.auth.isSaving = true; }, 'auth/updateBegin');
    await updateUser(data);
    set(
      state => {
        const { account } = state.auth;
        state.auth.account = { ...account, ...data };
      },
      'auth/updateSuccess'
    );
  }
});

export default { init, createActions };
