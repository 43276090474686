import React, { useRef, useEffect } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { Button, ListItem } from '@mui/material';
import clsx from 'clsx';
import isString from 'lodash/isString';
import { CertificationStatusColor } from 'src/models/certification';
import useNavItemStyles from './useNavItemStyles';

function Link({
  children,
  to,
  status,
  Icon,
  disabled,
  className,
  iconClassName,
  ...props
}) {
  const element = useRef(null);
  const currentPathname = isString(to) ? to : to?.pathname;
  const routeMatch = useRouteMatch(currentPathname);
  const active = routeMatch?.isExact;
  const classes = useNavItemStyles({ active, disabled });
  const color = CertificationStatusColor[status];

  useEffect(() => {
    if (!active || !element.current) return;
    element.current.scrollIntoView({ block: 'nearest' });
  }, [active]);

  return (
    <ListItem
      {...props}
      color={color}
      disableGutters
      className={classes.item}
    >
      <Button
        ref={element}
        to={to}
        disabled={disabled}
        component={NavLink}
        className={clsx(classes.button, className)}
      >
        {Icon && <Icon className={clsx(classes.icon, iconClassName)} />}
        {children}
      </Button>
    </ListItem>
  );
}

export default Link;
