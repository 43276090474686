import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Box, Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import usePreviousValue from 'src/next/util/usePreviousValue';
import withFormik from 'src/next/util/withFormik';
import yup from 'src/shared/validation';

const validationSchema = yup.object({
  content: yup
    .string()
    .required('Content is required')
});

const Form = styled(Box)`
  width: 100%
`;

const StyledTextField = styled(withFormik(props => (
  <TextField
    fullWidth
    multiline
    minRows={6}
    {...props}
  />
)))`
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.tertiary.dark};
  }
`;

function CommentEdit({ comment = {}, isLoading = false, isFailed = false, onSave, listVisible }) {
  const prevIsLoading = usePreviousValue(isLoading);
  const formik = useFormik({
    initialValues: { content: '', ...comment },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: onSave
  });

  useEffect(() => {
    if ((!isFailed && prevIsLoading === true && isLoading === false) || (!listVisible)) {
      formik.handleReset();
    }
  }, [isFailed, isLoading, listVisible]);

  return (
    <Form
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <Grid container>
        <Grid item xs={12}>
          <StyledTextField
            name="content"
            formik={formik}
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={12} mt={2} container justifyContent="end">
          <LoadingButton
            type="submit"
            disabled={!formik.values.content}
            loading={isLoading}
          >
            Submit
          </LoadingButton>
        </Grid>
      </Grid>
    </Form>
  );
}

export default CommentEdit;
