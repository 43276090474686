import jwtDecode from 'jwt-decode';
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import Logger from 'src/utils/logger';
import {
  mapCognitoRoles,
  COGNITO_USERPOOL_ID,
  COGNITO_CLIENT_ID
} from '../constants';

export const PARTNER_STORAGE_KEY = 'partner';
export const DEVICE_KEYS = 'device_keys';
// 7 days in minutes
export const DEVICE_EXPIRATION_TIME_PERIOD = 10080;

export async function loadSession() {
  const session = await getSession();
  if (!session) return;
  const account = await getAccount(session);
  return account;
}

export function decodeToken(token) {
  return jwtDecode(token);
}

export async function getCognitoGroups(sessionArg) {
  let session = sessionArg;
  try {
    if (!session) {
      session = await getSession();
    }
    const accessToken = session.getAccessToken().getJwtToken();
    if (accessToken) {
      const decoded = decodeToken(accessToken);
      const groups = decoded && decoded['cognito:groups'];
      return groups || [];
    }
  } catch (err) {
    // no-op
  }
  return [];
}

const UserPool = poolData => new CognitoUserPool(poolData);

export async function getAccount() {
  const session = await getSession();
  const { idToken } = session;
  const [role] = await getCognitoGroups(session);
  return {
    role: mapCognitoRoles[role],
    first: idToken.payload.given_name,
    last: idToken.payload.family_name,
    username: idToken.payload['cognito:username'],
    email: idToken.payload.email,
    mobile: idToken.payload.phone_number
  };
}

export function getUsername() {
  const user = getCurrentUser();
  return user && user.username;
}

export function getCognitoUser(poolData, email) {
  const Pool = UserPool(poolData);
  return new CognitoUser({
    Username: email,
    Pool
  });
}

function getDeviceKeys() {
  try {
    let data = localStorage.getItem(DEVICE_KEYS);
    if (!data) {
      data = {};
      localStorage.setItem(DEVICE_KEYS, JSON.stringify(data));
    }
    return JSON.parse(data);
  } catch (err) {
    return {};
  }
}

export function findDevice(deviceKey) {
  const keyPairs = getDeviceKeys();
  const foundDeviceKey = keyPairs[deviceKey];
  if (!foundDeviceKey) {
    return { key: undefined, value: undefined };
  }
  return { key: deviceKey, value: foundDeviceKey };
}

export function updateDeviceKey(cognitoUser) {
  try {
    const { deviceKey } = cognitoUser;
    const keyPairs = getDeviceKeys();
    keyPairs[deviceKey] = new Date().toISOString();
    localStorage.setItem(DEVICE_KEYS, JSON.stringify(keyPairs));
    return keyPairs[deviceKey];
  } catch (err) {
    // no-op
  }
}

export function deleteDeviceKey(deviceKey) {
  try {
    const data = getDeviceKeys();
    delete data[deviceKey];
    localStorage.setItem(DEVICE_KEYS, JSON.stringify(data));
  } catch (err) {
    // no-op
  }
}

export async function checkDeviceExpiration(cognitoUser) {
  const { deviceKey } = cognitoUser;
  // Find device
  const foundDeviceKey = findDevice(deviceKey);
  if (!foundDeviceKey.key) {
    return true;
  }

  // Calculate the differnce in minutes
  const timestamp = new Date(foundDeviceKey.value);
  const now = new Date();
  const difference = (now - timestamp) / 1000;
  const diffMins = difference / 60;
  // forget device and logout user if expires
  if (diffMins > DEVICE_EXPIRATION_TIME_PERIOD) {
    return true;
  }
  return false;
}

function forgetDevice(cognitoUser) {
  const { deviceKey } = cognitoUser;
  return new Promise((resolve, reject) => {
    cognitoUser.forgetDevice({
      onSuccess(success) {
        Logger.info('onSuccess');
        deleteDeviceKey(deviceKey);
        resolve(success);
      },

      onFailure(err) {
        Logger.error('onFailure:', err);
        reject(err);
      }
    });
  });
}

export function getPoolData() {
  if (!COGNITO_USERPOOL_ID) return undefined;
  return {
    UserPoolId: COGNITO_USERPOOL_ID,
    ClientId: COGNITO_CLIENT_ID
  };
}

export function getCurrentUser() {
  const poolData = getPoolData();
  if (!poolData) {
    return null;
  }
  const Pool = UserPool(poolData);
  const user = Pool.getCurrentUser();
  return user;
}

export async function getSession() {
  return new Promise((resolve, reject) => {
    const user = getCurrentUser();
    if (!user) resolve();
    user.getSession((err, session) => {
      if (err) return reject(err);
      return resolve(session);
    });
  });
}

export async function getMFAOptions(cognitoUser) {
  return new Promise((resolve, reject) => {
    cognitoUser.getMFAOptions((err, result) => {
      if (err) {
        reject(err);
        return;
      }

      resolve(result);
    });
  });
}

// Need to use the same CognitoUser instance
// between `authenticateUser` and `completeNewPasswordChallenge`
const loginSessionData = {};

export async function authenticate(Username, Password) {
  const poolData = getPoolData();
  if (!poolData) {
    return Promise.reject(new Error('Incorrect email or password.'));
  }
  const Pool = UserPool(poolData);
  return new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({ Username, Pool });
    const authDetails = new AuthenticationDetails({ Username, Password });
    cognitoUser.authenticateUser(authDetails, {
      async onSuccess(session) {
        Logger.info('onSuccess');
        const mfaEnabled = await getMFAOptions(cognitoUser);
        const isExpired = mfaEnabled && (await checkDeviceExpiration(cognitoUser));
        if (isExpired) {
          await forgetDevice(cognitoUser);
          logout();
          const { mfaRequired } = await authenticate(Username, Password);
          resolve({ mfaRequired });
          return;
        }
        resolve(session);
      },

      onFailure(err) {
        Logger.error('onFailure:', err);
        reject(new Error('Incorrect email or password.'));
      },

      mfaRequired(codeDeliveryDetails) {
        Logger.info(codeDeliveryDetails);
        loginSessionData.cognitoUser = cognitoUser;
        resolve({ mfaRequired: true });
      },

      newPasswordRequired(userAttributes) {
        // User was signed up by an admin and must provide new
        // password and required attributes, if any, to complete
        // authentication.

        // the api doesn't accept this field back
        const requiredAttributeData = {
          ...userAttributes
        };
        delete requiredAttributeData.email_verified;
        delete requiredAttributeData.phone_number_verified;
        delete requiredAttributeData.phone_number;

        loginSessionData.cognitoUser = cognitoUser;
        loginSessionData.requiredAttributeData = requiredAttributeData;
        resolve({
          newPasswordRequired: true
        });
      }
    });
  });
}

export async function changeUserPassword(oldPassword, newPassword) {
  const poolData = getPoolData();
  const Pool = UserPool(poolData);
  const currentUser = Pool.getCurrentUser();

  const authenticationData = {
    Username: currentUser.username,
    Password: oldPassword
  };

  return new Promise((resolve, reject) => {
    const authDetails = new AuthenticationDetails(authenticationData);

    currentUser.authenticateUser(authDetails, {
      onSuccess() {
        currentUser.changePassword(oldPassword, newPassword, function (err, result) {
          if (err) {
            Logger.error('onFailure:', err);
            reject(err);
          } else {
            Logger.info('onSuccess');
            resolve(result);
          }
        });
      },
      onFailure(err) {
        Logger.error('onFailure:', err);
        reject(err);
      }
    });
  });
}

export async function completeNewPasswordChallenge({ newPw }) {
  const { cognitoUser, requiredAttributeData } = loginSessionData;
  return new Promise((resolve, reject) => {
    cognitoUser.completeNewPasswordChallenge(newPw, requiredAttributeData, {
      onSuccess(data) {
        resolve(data);
      },
      mfaRequired(codeDeliveryDetails) {
        Logger.info(codeDeliveryDetails);
        loginSessionData.cognitoUser = cognitoUser;
        resolve({ mfaRequired: true });
      },
      onFailure(err) {
        Logger.error('onFailure:', err);
        reject(err);
      }
    });
  });
}

export async function sendMFACode({ mfaCode }) {
  const { cognitoUser } = loginSessionData;
  return new Promise((resolve, reject) => {
    cognitoUser.sendMFACode(mfaCode, {
      onSuccess(data) {
        updateDeviceKey(cognitoUser);
        resolve(data);
      },
      onFailure(err) {
        Logger.error('onFailure:', err);
        reject(err);
      }
    });
  });
}

export function logout() {
  const poolData = getPoolData();
  const Pool = UserPool(poolData);
  const user = Pool.getCurrentUser();
  if (user) user.signOut();
}

export async function resetPassword({ code, email, newPw, confirmPw }) {
  return new Promise((resolve, reject) => {
    if (newPw !== confirmPw) {
      reject(new Error('Passwords are not the same'));
    }
    const poolData = getPoolData();
    const cognitoUser = getCognitoUser(poolData, email);
    cognitoUser.confirmPassword(code, newPw, {
      onSuccess: data => {
        Logger.info('onSuccess');
        resolve(data);
      },
      onFailure: err => {
        Logger.error('onFailure:', err);
        reject(err);
      }
    });
  });
}

export async function sendCode(email) {
  return new Promise((resolve, reject) => {
    const poolData = getPoolData();
    const cognitoUser = getCognitoUser(poolData, email);
    cognitoUser.forgotPassword({
      onSuccess: data => {
        Logger.info('onSuccess');
        resolve(data);
      },
      onFailure: err => {
        Logger.error('onFailure:', err);
        reject(new Error('Failed to send verification code, please try again later'));
      },
      inputVerificationCode: data => {
        Logger.info('Input code');
        resolve(data);
      }
    });
  });
}
