import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useStore from 'src/store';
import { selectIsAuthenticated } from 'src/store/auth/selectors';
import ROUTES from './routes';

function PrivateRoute({ children, ...props }) {
  const isAuthenticated = useStore(selectIsAuthenticated);
  if (!isAuthenticated) return <Redirect to={ROUTES.LOGIN} />;
  return <Route {...props}>{children}</Route>;
}

export default PrivateRoute;
