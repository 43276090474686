import React, { useState, useEffect } from 'react';
import useQueryParams from 'src/hooks/useQueryParams';
import { Box, Card, CardContent, Typography, Divider, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import { CODE_SENT } from 'src/constants';
import { ROUTES } from 'src/router';
import AuthLayout from 'src/layouts/AuthLayout';
import CognitoLogin from './CognitoLogin';

const useStyles = makeStyles(theme => ({
  cardContent: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    minHeight: 400
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const [logo, setLogo] = useState();
  const [progressLoading, setProgressLoading] = useState(false);
  const history = useHistory();
  const [loginStep, setLoginStep] = useState(1);
  const { emailInvitation } = useQueryParams();
  const isEmailInvitation = !!emailInvitation;
  const isLoginStepTwo = isEmailInvitation && loginStep === 2;

  useEffect(() => {
    const data = localStorage.getItem(CODE_SENT);
    if (data) {
      history.push(ROUTES.FORGOT_PASSWORD);
    }
    // cleanup function to prevent memory leak warning
    return () => {
      setProgressLoading(false);
    };
  }, [history]);

  return (
    <AuthLayout logo={logo} title="Login" isLoading={progressLoading}>
      <Card>
        <CardContent className={classes.cardContent}>
          <Box mt={2} mb={1.5} alignItems='center'>
            {
              isLoginStepTwo &&
              <Link
                onClick={() => setLoginStep(1)}
                variant="body2"
                color="textSecondary"
                style={{ cursor: 'pointer', textDecoration: 'underline', fontSize: 16 }}>
                {'< Back'}
              </Link>
            }
            <Box display='flex' flexDirection='row'>
              <Typography
                style={{ fontWeight: 'bold', fontSize: 24 }}
                color="textPrimary"
                gutterBottom
                variant="subtitle1">
                Login
              </Typography>
              {
                isEmailInvitation &&
                <Typography
                  variant='subtitle1'
                  fontSize={11}
                  mt={2}
                  ml={2}>
                  Step {loginStep} of 2
                </Typography>
              }
            </Box>
            <Typography variant='subtitle1' fontSize={isLoginStepTwo ? '14px' : '16px'}>
              {
                isLoginStepTwo
                  ? 'Opt in to receive SMS text messages for two-factor authentication'
                  : `Please log in with ${isEmailInvitation ? 'the credentials we’ve provided' : 'your credentials'}`
              }
            </Typography>
          </Box>
          {!isLoginStepTwo && <Divider />}
          <Box flexGrow={1} mt={1.5}>
            <CognitoLogin
              onBack={() => setLoginStep(1)}
              onNext={() => setLoginStep(2)}
              setLogo={setLogo}
              setProgressLoading={setProgressLoading}
              loginStep={loginStep}
              isEmailInvitation={isEmailInvitation}
            />
          </Box>
        </CardContent>
      </Card>
    </AuthLayout>
  );
};

export default LoginView;
