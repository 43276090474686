import { get, last, sortBy } from 'lodash';
import { generatePath } from 'react-router-dom';
import { CERTIFICATION_STEP_TYPES } from 'src/constants';
import { ExtendedCertificationStatus } from 'src/models/certification';
import routes from '../navigation/routes';

const {
  BASELINE_NOT_STARTED,
  BASELINE_DRAFT,
  BASELINE_REVIEW,
  BASELINE_APPROVED,
  EVALUATION_DRAFT,
  EVALUATION_NOT_STARTED,
  EVALUATION_NOT_STARTED_OPEN,
  EVALUATION_IN_REVIEW,
  EVALUATION_APPROVED,
  EVALUATION_APPROVED_NO_BADGE
} = ExtendedCertificationStatus;
const DRAFT_STATES = new Set([BASELINE_DRAFT, EVALUATION_DRAFT]);
const REVIEW_STATES = new Set([BASELINE_REVIEW, EVALUATION_IN_REVIEW]);

const uncompletedStepStatus = [
  BASELINE_NOT_STARTED,
  BASELINE_DRAFT,
  BASELINE_REVIEW,
  BASELINE_APPROVED,
  EVALUATION_DRAFT,
  EVALUATION_IN_REVIEW,
  EVALUATION_NOT_STARTED,
  EVALUATION_NOT_STARTED_OPEN
];

const yearAgnosticStatuses = [BASELINE_DRAFT, BASELINE_REVIEW];

const extendedCertificationStatusMapper = {
  [BASELINE_DRAFT]: {
    getStatus({ currentCertificationCycle }) {
      if (!currentCertificationCycle) return BASELINE_NOT_STARTED;
    }
  },
  [BASELINE_APPROVED]: {
    getStatus({ isAssessmentOpen }) {
      if (isAssessmentOpen) return EVALUATION_NOT_STARTED_OPEN;
      return EVALUATION_NOT_STARTED;
    }
  },
  [EVALUATION_APPROVED]: {
    getStatus({ isAssessmentOpen, isAssessmentAttemptedInCurrentYearPeriod, hasReceivedABadgeInCurrentYear }) {
      if (hasReceivedABadgeInCurrentYear) return EVALUATION_APPROVED;
      if (isAssessmentAttemptedInCurrentYearPeriod) return EVALUATION_APPROVED_NO_BADGE;
      if (isAssessmentOpen) return EVALUATION_NOT_STARTED_OPEN;
      return EVALUATION_APPROVED_NO_BADGE;
    }
  }
};

export const notStartedStatuses = [BASELINE_NOT_STARTED, EVALUATION_NOT_STARTED, EVALUATION_NOT_STARTED_OPEN];

export const inProgressStatuses = [BASELINE_DRAFT, EVALUATION_DRAFT];

export const CERTIFICATION_STEP_LABEL_BY_TYPE = {
  [CERTIFICATION_STEP_TYPES.BASELINE]: 'Baseline Assessment',
  [CERTIFICATION_STEP_TYPES.EVALUATION]: 'Certification Assessment'
};

export function getCertificationStepMetadata(id, steps = [], certification = {}) {
  const ordered = getOrderedCertificationSteps(steps);
  const index = ordered.findIndex(s => s.id === id);

  if (index < 0) {
    return {};
  }

  const certificationStep = ordered[index];
  const currentIndex = ordered.length - 1;
  const currentStep = ordered[currentIndex];
  const isCurrent = currentStep.id === certificationStep.id;
  const isBefore = index < currentIndex;
  const isDraft = isCurrent && DRAFT_STATES.has(certification.status);
  const isReview = isCurrent && REVIEW_STATES.has(certification.status);
  // A step was approved if the next one was created or if current certification
  // status is 'Certified' because it is the last possible step in one certification cycle
  const isApproved = isBefore || [BASELINE_APPROVED, EVALUATION_APPROVED].includes(certification.status);

  return { isCurrent, isDraft, isReview, isApproved };
}

export function isBaseline(certification) {
  const { status } = certification;
  return [BASELINE_NOT_STARTED, BASELINE_DRAFT, BASELINE_REVIEW].includes(status);
}

export function isStepCompleted(step) {
  const total = step?.completion?.total ?? NaN;
  const completed = step?.completion?.completed ?? NaN;
  return completed === total;
}

export function isScorePositive(step) {
  return step?.score?.total > 0;
}

export function isStepNotInCycle(step, cycleId) {
  return step.certificationCycle.id !== cycleId;
}

export function isPastStepInCycle(step, allSteps, cycleId) {
  if (!cycleId) return false;

  const currentCycleSteps = allSteps.filter(it => it.certificationCycle.id === cycleId);

  // Past step in current cycle can be only February step when August step exists
  return currentCycleSteps.length === 2 && step.position === 1;
}

export function getCertificationStepLabel({ type }) {
  return CERTIFICATION_STEP_LABEL_BY_TYPE[type];
}

export function getIndicatorMaxScore(indicator) {
  const maxScore = get(indicator, 'scoring.maxScore', Infinity);
  const questionsMaxScore = indicator.questions.reduce((acc, question) => acc + question.maxScore, 0);

  return Math.min(maxScore, questionsMaxScore);
}

export function getPillarMaxScore(pillar) {
  return pillar.indicators.reduce((acc, indicator) => acc + getIndicatorMaxScore(indicator), 0);
}

export function getAssessmentMaxScore(assessment) {
  return assessment.pillars.reduce((acc, pillar) => acc + getPillarMaxScore(pillar), 0);
}

export function getCurrentCertificationCycle(cycles = []) {
  return last(sortBy(cycles, ['position']));
}

export function getOrderedCertificationSteps(steps = []) {
  return sortBy(steps, ['certificationCycle.position', 'position']);
}

export function getCurrentCertificationStep(steps = []) {
  return last(getOrderedCertificationSteps(steps));
}

export function appendAssetsFieldToSteps(assets = [], steps = []) {
  const assetMap = assets.reduce((map, asset) => {
    map[asset.certificationStep] = asset;
    return map;
  }, {});

  return steps.map(step => ({ ...step, asset: assetMap[step.id] }));
}

export function getLatestAssessmentStep() {
  // TODO: Check if referencing methods should be removed and logic refactored
  return {};
}

export function filterPartnersByAssignmentTypes(partners, assignmentTypes = [], userId) {
  const filterAssignments = assignment => {
    if (!assignmentTypes.includes(assignment.type)) return false;
    if (assignment.user.id !== userId) return false;
    return true;
  };

  const filterCertifications = certification => {
    if (!certification.platformStatus) return false;
    return certification.certificationAssignments.some(filterAssignments);
  };

  return partners.reduce((acc, partner) => {
    const filteredCertifications = partner.certifications.filter(filterCertifications);
    if (!filteredCertifications.length) return acc;
    const partnerData = { ...partner, certifications: filteredCertifications };
    acc.filteredPartners.push(partnerData);
    return acc;
  }, { filteredPartners: [] });
}

export function getCompletedIndicatorsCount(indicators) {
  let count = 0;

  for (const key in indicators) {
    const { total, completed } = indicators[key];
    if (total === completed) {
      count++;
    }
  }

  return count;
}

export function getCertificationStatusByFrontendTerminology(status) {
  switch (status) {
    case 'Baseline Draft':
      return 'Preliminary Assessment in Progress';
    case 'Baseline In Review':
      return 'Preliminary Assessment in Review';
    case 'Baseline Approved':
      return 'Preliminary Assessment Approved';
    case 'Assessment In Progress':
      return 'Certification Assessment in Progress';
    case 'Assessment In Review':
      return 'Certification Assessment in Review';
    default:
      return status;
  }
}

export function getCertificationStatus(params) {
  const { certification, currentCertificationCycle, currentCertificationStep, periods, currentDate } = params;
  const { status: certificationStatus, isAssessmentOpen, hasReceivedABadgeInCurrentYear, isAssessmentAttemptedInCurrentYearPeriod } = certification;
  const getStatusParams = {
    isAssessmentOpen, currentCertificationCycle, hasReceivedABadgeInCurrentYear, isAssessmentAttemptedInCurrentYearPeriod
  };

  if (yearAgnosticStatuses.includes(certificationStatus)) {
    const extendedStatus = extendedCertificationStatusMapper[certificationStatus]?.getStatus(getStatusParams);
    return extendedStatus || certificationStatus;
  }

  const hasReport = !!currentCertificationStep?.asset;
  const hasNextPeriodInCurrentYear = periods.some(period => period.isNext);
  const isCurrentYearClosed = !isAssessmentOpen && !hasNextPeriodInCurrentYear;
  const currentCalendarYear = currentDate.getFullYear();
  const isNewCalendarYear = currentCertificationCycle?.cycleYear < currentCalendarYear;

  if (isNewCalendarYear) {
    return isAssessmentOpen ? EVALUATION_NOT_STARTED_OPEN : EVALUATION_NOT_STARTED;
  }

  if (hasReport && !isAssessmentOpen) return EVALUATION_NOT_STARTED;

  if (isCurrentYearClosed && !hasReceivedABadgeInCurrentYear && !isScorePositive(currentCertificationStep)) {
    return EVALUATION_NOT_STARTED;
  }

  const extendedStatus = extendedCertificationStatusMapper[certificationStatus]?.getStatus(getStatusParams);

  return extendedStatus || certificationStatus;
}

export function getCurrentCyclePeriodsMetadata(params) {
  const { certificationId, certificationCycle, certificationSteps, certificationStatus, isPsTeamMember, isAdmin, periods } = params;
  const currentCycleEvaluationSteps = certificationSteps.filter(step => {
    return step.certificationCycle.id === certificationCycle?.id &&
      step.type === CERTIFICATION_STEP_TYPES.EVALUATION;
  });

  return periods.map(period => {
    const { title, position, isCurrent, isNext, isClosed, startDate, endDate } = period;
    const matchingEvaluation = currentCycleEvaluationSteps.find(step => step.position === position);
    const isHighlighted = getHighlightedStatus(period, certificationStatus);
    const hasNextStep = period.hasNextStep(currentCycleEvaluationSteps);
    const isDataUnavailable = getDataUnavailabilityStatus(matchingEvaluation, isHighlighted, certificationStatus, hasNextStep);
    const score = matchingEvaluation?.score?.total;
    const isScoreVisible = score >= 0 && [EVALUATION_APPROVED, EVALUATION_APPROVED_NO_BADGE, EVALUATION_IN_REVIEW].includes(certificationStatus);
    const reviewLink = isScoreVisible ? getStepRoute(certificationId, matchingEvaluation?.id) : null;
    const canManageReports = isClosed && (isPsTeamMember || isAdmin) && !!isScoreVisible;
    const asset = !isDataUnavailable ? matchingEvaluation?.asset : null;

    return {
      title,
      isHighlighted,
      status: certificationStatus,
      score,
      isScoreVisible,
      position,
      certificationId,
      stepId: matchingEvaluation?.id,
      isDataUnavailable,
      isCurrent,
      isNext,
      startDate,
      endDate,
      reviewLink,
      asset,
      canManageReports
    };
  });
}

export function getStepRoute(certificationId, certificationStepId) {
  if (!certificationStepId) return;
  return generatePath(routes.CERTIFICATION_STEP, {
    certificationId,
    certificationStepId
  });
}

export function getPillarRoute(certificationId, certificationStepId, pillarId) {
  if (!pillarId) return;
  return generatePath(routes.CERTIFICATION_PILLAR, {
    certificationId,
    certificationStepId,
    pillarId
  });
}

export function getIndicatorRoute(certificationId, certificationStepId, pillarId, indicatorId) {
  if (!pillarId || !indicatorId) return;
  return generatePath(routes.CERTIFICATION_INDICATOR, {
    certificationId,
    certificationStepId,
    pillarId,
    indicatorId
  });
}

function getHighlightedStatus(period, certificationStatus) {
  if (!period.isCurrent) return false;
  if (uncompletedStepStatus.includes(certificationStatus)) return true;

  return false;
}

function getDataUnavailabilityStatus(matchingEvaluation, isHighlighted, status, hasNextStep) {
  if (!matchingEvaluation) return true;
  if (hasNextStep) return true;
  if (!isHighlighted && ![EVALUATION_IN_REVIEW, EVALUATION_APPROVED, EVALUATION_APPROVED_NO_BADGE].includes(status)) {
    return true;
  }
  if (status === EVALUATION_NOT_STARTED_OPEN) return true;

  return false;
}
