import axios from 'axios';
import { API_URL } from '../../constants';

const ASSESSMENTS_URL = `${API_URL}/assessments`;

const api = {
  getPillars(assessmentId) {
    return axios.get(`${ASSESSMENTS_URL}/${assessmentId}/pillars`);
  }
};

export default (set, get) => ({
  pillars: [],

  async getPillars(assessmentId) {
    const data = await api.getPillars(assessmentId);

    set(state => ({ pillars: data }), 'GET_PILLARS');
  },

  resetPillars(params) {
    set(state => ({ pillars: [] }), 'RESET_PILLARS');
  }
});
