import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import * as auth from 'src/utils/auth';
import { useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { withLoading } from 'src/utils/progress';
import TextField from 'src/components/common/TextField';
import { ROUTES } from 'src/router';
import Error from './Error';
import PasswordTextField from '../PasswordTextField';
import { forgotPasswordValidationSchema } from '../authValidationSchema';

const ResetPasswordView = ({ isLoading, setLoading }) => {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const verificationCode = query.get('code');
  const [error, setError] = useState('');
  const email = query.get('email');

  const resetPassword = withLoading(data => {
    const { email, code, password: newPw, confirmPassword: confirmPw } = data;
    return auth.resetPassword({ code, email, newPw, confirmPw })
      .then(() => history.push(`${ROUTES.FORGOT_PASSWORD}/success`))
      .catch(err => setError(err.message));
  }, setLoading);

  const formBag = useFormik({
    initialValues: {
      email,
      code: verificationCode || '',
      password: '',
      confirmPassword: ''
    },
    validationSchema: forgotPasswordValidationSchema,
    onSubmit: resetPassword
  });

  return (
    <Box>
      <Box display="block" mt={2}>
        <form onSubmit={formBag.handleSubmit}>
          <TextField mt={1} name="email" formBag={formBag} disabled />
          <TextField
            mt='0'
            name="code"
            label="Verification Code"
            disabled={isLoading}
            formBag={formBag}
          />
          <PasswordTextField
            mt='0'
            name="password"
            label="New Password"
            autoComplete="new-password"
            disabled={isLoading}
            formBag={formBag}
          />
          <PasswordTextField
            mt='0'
            name="confirmPassword"
            label="Confirm New Password"
            autoComplete="new-password"
            disabled={isLoading}
            formBag={formBag}
          />
          {error && <Error mb={2}>{error}</Error>}
          <Box mt={1}>
            <Button
              disabled={isLoading || !formBag.isValid || !formBag.dirty}
              variant="contained"
              color="primary"
              size="large"
              type="submit" >
              Reset Password
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default ResetPasswordView;
