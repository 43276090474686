import { ROLES } from 'src/constants';
import routes from 'src/next/navigation/routes';
import useStore from '../store';

/**
 * Gets a path to what is considered a dashboard page for a particular user, based on their role.
 */
export default function useDashboardRoute() {
  const user = useStore(state => state.auth.user);

  // TODO(marko): Update this once we have clearly defined dashboard routes for each role.
  return user?.role === ROLES.EMPLOYEE ? routes.HOME : '/';
}
