import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  Typography,
  useTheme
} from '@mui/material';
import { ChevronRight as ChevronRightIcon, HelpCircle as HelpCircleIcon } from 'react-feather';
import qs from 'query-string';
import { NOTIFICATION_TYPES } from 'src/constants';
import useAssessmentName from 'src/next/assessment/useAssessmentName';
import useAssessmentTheme from 'src/next/assessment/useAssessmentTheme';
import { CERTIFICATION_STEP_LABEL_BY_TYPE } from '../certification/certificationUtil';

const StyledCard = styled(Card)`
  .MuiCardContent-root:last-child {
    padding-bottom: 0;
  }
`;

const StyledCardHeader = styled(CardHeader)`
  & .MuiCardHeader-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const StyledChip = styled(
  ({ label, ...props }) => (
    <Chip
      size="small"
      label={<Typography variant="microtext2">{label}</Typography>}
      {...props}
    />
  ),
  { shouldForwardProp: prop => prop !== 'isSeen' && prop !== 'assessmentColor' }
)`
  ${({ assessmentColor, isSeen }) => (assessmentColor
    ? `
      color: #FFFFFF;
      background-color: ${assessmentColor};
    `
    : `background-color: ${!isSeen ? '#ECEFF1' : '#DDE1E3'};`
  )}
`;

const StyledLink = styled(Link, { shouldForwardProp: prop => prop !== 'isSeen' })`
  overflow: auto;
  opacity: ${({ isSeen }) => !isSeen ? 1 : 0.8};
  width: 100%;
  padding: ${({ theme }) => theme.spacing(1, 2, 2)};
`;

const StyledListItem = styled(ListItem, { shouldForwardProp: prop => prop !== 'isSeen' })`
  padding: 0;
  background-color: ${({ isSeen }) => !isSeen ? '#FFFFFF' : '#00000010'};
`;

const StyledListItemDivider = styled(Divider, { shouldForwardProp: prop => prop !== 'isSeen' })`
  border-color: ${({ isSeen, theme }) => !isSeen ? theme.palette.divider : '#FFFFFF'};
`;

const NotificationHeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NotificationTitle = styled(Typography)`
  color: inherit;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin: ${({ theme }) => theme.spacing(0, 0, 1)};
  overflow: hidden;
  white-space: unset;
`;

const NotificationFooterContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-top: ${({ theme }) => theme.spacing(1)};
  border-top: 1px solid ${({ theme }) => theme.palette.tertiary.dark};
`;

const ChipContainer = styled.div`
  flex-shrink: 0;

  & > .MuiChip-root {
    margin-left: ${({ theme }) => theme.spacing(1)};
  }
`;

const IconWrapper = styled(Box)`
  height: 100%;
  display: flex;
  align-items: center;
`;

function getGroupCommentUrl(comment) {
  const { questionGroup, certificationStep, certification } = comment;
  const certificationPath = `/next/${certification.id}`;
  const stepPath = `steps/${certificationStep.id}`;
  const questionGroupPath = questionGroup.pillar
    ? `pillars/${questionGroup.pillar.id}/indicators/${questionGroup.id}`
    : `pillars/${questionGroup.id}`;
  const path = [certificationPath, stepPath, questionGroupPath].join('/');
  const query = qs.stringify({ commentId: comment.id });

  return `${path}?${query}`;
}

function getCommentUrl(comment) {
  const { question, certification, baseline } = comment;
  const certificationPath = `/certifications/${certification.id}`;
  const baselinePath = `baselines/${baseline.id}`;
  const pillarPath = `pillars/${question.pillar.id}`;
  const path = [certificationPath, baselinePath, pillarPath].join('/');
  const query = qs.stringify({ comment: comment.id, question: question.id });

  return `${path}?${query}`;
}

function getNotificationDisplayData(notification) {
  const { data: { comment } } = notification;
  const commentBy = `${comment?.user?.first} ${comment?.user?.last} `;
  const url = getCommentUrl(comment);
  const header = comment.question.title;
  const title = comment.content;
  const label = 'Baseline Assessment';

  return { url, header, title, commentBy, label };
}

function getCommentNotificationDisplayData(notification) {
  const { data: { comment } } = notification;
  const { questionGroup, certificationStep } = comment;
  const commentBy = `${comment?.user?.first} ${comment?.user?.last} `;
  const url = getGroupCommentUrl(comment);
  const header = questionGroup.name;
  const title = comment.content;
  const label = CERTIFICATION_STEP_LABEL_BY_TYPE[certificationStep.type];

  return { url, header, title, commentBy, label };
}

function CommentNotificationListItem({ notification, onClick }) {
  const { url, commentBy, title, header, label } = notification.type === NOTIFICATION_TYPES.COMMENT
    ? getNotificationDisplayData(notification)
    : getCommentNotificationDisplayData(notification);
  const isSeen = !!notification.seenAt;
  const onNotificationItemClick = useCallback(() => onClick(notification.id), [notification, onClick]);
  const assessmentName = useAssessmentName();
  const assessmentTheme = useAssessmentTheme();

  return (
    <StyledListItem
      isSeen={isSeen}
      onClick={onNotificationItemClick}
    >
      <StyledLink
        to={url}
        isSeen={isSeen}
      >
        <Grid
          container
          wrap="nowrap"
        >
          <Grid
            item
            zeroMinWidth
            width='100%'
            pr={2}
          >
            <NotificationHeaderContainer>
              <Typography variant="subtitle2" noWrap>
                {header}
              </Typography>
            </NotificationHeaderContainer>
            <Box>
              <NotificationTitle variant="link2" noWrap>
                {title}
              </NotificationTitle>
            </Box>
            <NotificationFooterContainer>
              <Typography
                variant="microtext1"
                lineHeight="unset"
                noWrap
              >
                By:&nbsp;
                <span>{commentBy}</span>
              </Typography>
              <ChipContainer>
                <StyledChip label={label} isSeen={isSeen} />
                <StyledChip
                  label={assessmentName}
                  assessmentColor={assessmentTheme.palette?.primary.main}
                />
              </ChipContainer>
            </NotificationFooterContainer>
          </Grid>
          <Grid item>
            <IconWrapper>
              <ChevronRightIcon width={20} height={20} />
            </IconWrapper>
          </Grid>
        </Grid>
      </StyledLink>
    </StyledListItem>
  );
}

export default function CommentNotificationList({
  seenNotifications = [],
  unseenNotifications = [],
  width = 516,
  maxHeight = 480,
  onNotificationClick,
  ...props
}) {
  const notifications = [...unseenNotifications, ...seenNotifications].slice(0, 10); // Limit to 10
  const theme = useTheme();
  const currentWidth = notifications.length ? width : 320;

  return (
    <StyledCard {...props} sx={{ width: currentWidth }}>
      <StyledCardHeader
        disableTypography={true}
        title={(
          <>
            <Typography variant="subtitle2">Comments</Typography>
            <HelpCircleIcon width={20} height={20} stroke={theme.palette.tertiary.dark} />
          </>
        )}
      />
      <CardContent sx={{ maxHeight, p: 0, overflowY: 'auto' }}>
        {notifications?.length
          ? <List sx={{ p: 0 }}>
            {notifications.map(notification => (
              <React.Fragment key={notification.id}>
                <StyledListItemDivider isSeen={!!notification.seenAt} />
                <CommentNotificationListItem
                  notification={notification}
                  onClick={onNotificationClick}
                />
              </React.Fragment>
            ))}
          </List>
          : <>
            <Divider />
            <Typography sx={{ p: 2 }} component="div" variant="body4" noWrap>
              No notifications at this time.
            </Typography>
          </>
        }
      </CardContent>
    </StyledCard>
  );
}
