import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Container, LinearProgress, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ROUTES } from 'src/router';
import Page from 'src/components/Page';
import Logo from 'src/components/Logo';
import LoginIcon from 'src/assets/images/login/login-icon.png';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  cardContainer: {
    paddingBottom: 80,
    paddingTop: 80
  },
  progressBar: {
    backgroundColor: '#fff',
    borderRadius: 0,
    height: 8
  }
}));

const AuthLayout = ({ logo, title, isLoading, children }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Page className={classes.root} title={title} data-testid="login-form">
      <Container className={classes.cardContainer} maxWidth="sm">
        <Box mb={8} display="flex" justifyContent="center">
          <RouterLink to={ROUTES.HOME}>
            <Logo width={80} src={logo} />
          </RouterLink>
        </Box>
        <Box ml={3} mb={-4} display="flex" justifyContent="left">
          <RouterLink to={ROUTES.HOME} style={{ zIndex: 1 }}>
            <Logo width={64} src={LoginIcon} />
          </RouterLink>
        </Box>
        <Box height={8} mb={-1}>
          {isLoading &&
          <LinearProgress
            className={classes.progressBar}
            color="secondary"
            sx={{
              '& .MuiLinearProgress-bar': {
                backgroundColor: theme.palette.main
              }
            }} />}
        </Box>
        {children}
      </Container>
    </Page>
  );
};

export default AuthLayout;
