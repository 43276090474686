import create from 'zustand';
import { devtools } from 'zustand/middleware';
import shallow from 'zustand/shallow';
import mapValues from 'lodash/mapValues';
import { ROLES } from 'src/constants';
import psmStore from 'src/psm/store';
import adminStore from 'src/admin/store';
import auth from './auth';
import certification from './certification';
import baseline from './certification/baseline';
import partner from './partner';
import { immer } from './middleware';
import { selectActions } from './selectors';

const slices = { auth, certification, baseline, partner };

const useStore = create(devtools(immer((set, get) => {
  const storeFactory = () => ({
    ...mapValues(slices, slice => slice.init(set, get)),
    actions: mapValues(slices, slice => slice.createActions(set, get)),
    loadStore: store => set(state => {
      const storeSlices = store.init(set, get);
      const storeActions = store.createActions(set, get);
      Object.entries(storeSlices).forEach(([name, slice]) => {
        state[name] = slice;
      });
      Object.entries(storeActions).forEach(([name, actions]) => {
        state.actions[name] = actions;
      });
    })
  });

  return {
    ...storeFactory(),
    reset: () => set(storeFactory())
  };
})));

useStore.subscribe(changeStore, state => state.auth.account?.role, shallow);

export { useActions };
export default useStore;

function useActions(slice) {
  return useStore(selectActions(slice), shallow);
}

function changeStore(role, previousRole) {
  const { loadStore } = useStore.getState();
  if (role === previousRole) return;
  if (role === ROLES.STAFF) return loadStore(psmStore);
  if (role === ROLES.ADMIN) return loadStore(adminStore);
}
