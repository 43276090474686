import React, { useState, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Box, Button, FormHelperText, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { completeNewPasswordChallenge } from 'src/utils/auth';
import { ROUTES } from 'src/router';
import { useErrorOutlineStyles } from 'src/hooks/useGlobalStyles';
import { useFormik } from 'formik';
import TextField from 'src/components/common/TextField';
import SuccessView from './SuccessView';
import PasswordTextField from '../PasswordTextField';
import { resetPasswordValidationSchema } from '../authValidationSchema';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      width: 'auto'
    },
    '& .MuiButton-root': {
      marginTop: theme.spacing(2)
    }
  },
  whiteColor: {
    color: '#fff'
  }
}));

const ResetPassword = ({ setProgressLoading }) => {
  const classes = useStyles();
  const globalErrorOutineClasses = useErrorOutlineStyles();
  const location = useLocation();
  const { state = {} } = location || {};
  const { email: initialUsername } = state;
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const history = useHistory();

  const handleResetPassword = useCallback(
    async ({ password: newPw, confirmPassword: confirmPw }) => {
      if (!newPw) {
        setError('New Password is required');
        return;
      }

      if (!confirmPw) {
        setError('Confirm New Password is required');
        return;
      }

      if (confirmPw !== newPw) {
        setError('Confirm New Password does not match New Password');
        return;
      }

      try {
        setLoading(true);
        setProgressLoading(true);

        const { mfaRequired } = await completeNewPasswordChallenge({
          newPw
        });
        if (mfaRequired) {
          history.push(ROUTES.MFA_AUTH);
          return;
        }
        setSuccess(true);
      } catch (err) {
        setLoading(false);
        setError(err.message);
      } finally {
        setProgressLoading(false);
      }
    },
    [setProgressLoading, history]
  );

  const formBag = useFormik({
    initialValues: {
      email: initialUsername || '',
      password: '',
      confirmPassword: ''
    },
    validationSchema: resetPasswordValidationSchema,
    onSubmit: handleResetPassword
  });

  return (
    <div className={classes.root}>
      {isSuccess
        ? (
          <SuccessView />
        )
        : (
          <form onSubmit={formBag.handleSubmit}>
            <Box display="block" justifyContent="center">
              <TextField mt={1}
                name="email"
                label="Email"
                formBag={formBag}
                disabled={!!initialUsername}
                variant="outlined"
              />
              <Box>
                <PasswordTextField
                  id="new-password-input"
                  mt='0'
                  name="password"
                  label="New Password"
                  variant="outlined"
                  autoComplete="new-password"
                  margin="normal"
                  disabled={isLoading}
                  formBag={formBag}
                  className={clsx(globalErrorOutineClasses, {
                    [globalErrorOutineClasses.filled]: formBag.values.password.length > 0
                  })}
                />
                <PasswordTextField
                  id="confirm-password-input"
                  mt='0'
                  name="confirmPassword"
                  label="Confirm New Password"
                  autoComplete="new-password"
                  disabled={isLoading}
                  formBag={formBag}
                  className={clsx(globalErrorOutineClasses, {
                    [globalErrorOutineClasses.filled]: formBag.values.confirmPassword.length > 0
                  })}
                />
              </Box>
              {error && (
                <Box my={3}>
                  <FormHelperText error>{error}</FormHelperText>
                </Box>
              )}
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isLoading || !formBag.isValid || !formBag.dirty}
                  size="large">
                  {isLoading ? <CircularProgress size={30} className={classes.whiteColor} /> : 'Set Password'}
                </Button>
              </Box>
            </Box>
          </form>
        )}
    </div>
  );
};

export default ResetPassword;
