import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import pick from 'lodash/pick';
import useStore from 'src/store';
import Navigation from 'src/components/Navigation';
import NavSection from 'src/components/Navigation/Section';
import NavLink from 'src/components/Navigation/Link';
import GettingStartedTrigger from 'src/components/Navigation/GettingStartedTrigger';
import BaselineNavigation from 'src/components/Navigation/Baseline';
import BaselineProgress from 'src/components/common/BaselineProgress';
import GridIcon from 'src/components/common/icons/Grid';
import SettingsIcon from 'src/components/common/icons/Settings';
import {
  selectAssessment,
  selectCertification
} from 'src/store/certification/selectors';
import { selectBaseline } from 'src/store/certification/baseline/selectors';
import { ROUTES } from '../router';

function EmployeeNavigation() {
  return (
    <Navigation>
      <DashboardTree />
      <CertificationTree />
      <UtilityTree />
    </Navigation>
  );
}

export default EmployeeNavigation;

function DashboardTree() {
  return (
    <NavSection title="Dashboard" open disabled>
      <NavLink to={ROUTES.DASHBOARD} Icon={GridIcon}>Overview</NavLink>
    </NavSection>
  );
}

function CertificationTree() {
  const baseline = useStore(selectBaseline);
  const assessment = useStore(selectAssessment);
  const certification = useStore(selectCertification);
  const baselineRouteMatch = useRouteMatch(ROUTES.CERTIFICATION_BASELINE);

  return (
    <>
      <NavSection
        title="Certification"
        open={!!baselineRouteMatch?.params.baselineId}
        dropdown
      >
        {baseline && (
          <>
            <BaselineProgress
              {...pick(baseline.completion, ['completed', 'total'])}
              mx={4} mt={1} mb={2} />
            <BaselineNavigation
              completion={baseline.completion}
              pillars={assessment.pillars}
              certificationId={certification.id}
              baselineId={baseline.id}
            />
          </>
        )}
      </NavSection>
    </>
  );
}

function UtilityTree() {
  return (
    <NavSection title="Utility">
      <NavLink to={ROUTES.SETTINGS} Icon={SettingsIcon}>Settings</NavLink>
      <GettingStartedTrigger />
    </NavSection>
  );
}
