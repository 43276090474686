export default function wrapPromise(fn, onSuccess, onError, onFinally) {
  return async (...args) => {
    try {
      await fn(...args);
      onSuccess();
    } catch (err) {
      onError();
      throw err;
    } finally {
      onFinally();
    }
  };
}
