function withLoading(action, setLoading) {
  return (...params) => {
    setLoading(true);
    return action(...params).finally(() => {
      setLoading(false);
    });
  };
}

export { withLoading };
