import { createTheme } from '@mui/material/styles';
import base from './rewTheme';

export default createTheme({
  ...base,
  palette: {
    ...base.palette,
    primary: {
      main: '#452059',
      light: '#6C308D',
      lighter: '#E2CFEA',
      dark: '#38104B',
      contrastText: '#FFF'
    },
    gradient: {
      ...base.palette.gradient,
      primary: 'linear-gradient(180deg, #A040E9 0%, #542577 100%)'
    },
    breadcrumbs: '#E2CFEA'
  }
});
